import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Canvas from './Canvas';

const CanvasPlanDialog = ({
  openCanvas,
  setOpenCanvas,
  data,
  setData,
  selectedProductPosition,
  canvasRef,
}) => {
  const [src, setSrc] = useState(undefined);

  const getSrcFromFile = useCallback(async (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  useEffect(() => {
    const isStringAndHttpsUrl = (str) =>
      typeof str === 'string' && /^https:\/\//i.test(str);

    if (data?.ctr_plan) {
      if (isStringAndHttpsUrl(data.ctr_plan)) {
        setSrc(data.ctr_plan);
      } else {
        getSrcFromFile(data.ctr_plan);
      }
    }
  }, [data.ctr_plan, getSrcFromFile, setSrc]);

  return (
    <Dialog
      style={{ zIndex: 9999999999 }}
      fullScreen={true}
      open={openCanvas}
      onClose={() => setOpenCanvas(false)}
    >
      <DialogContent style={{ padding: 0, height: '100%' }}>
        <IconButton
          onClick={() => setOpenCanvas(false)}
          style={{
            position: 'absolute',
            float: 'right',
            zIndex: 999,
            backgroundColor: 'white',
            border: '1px solid lightgrey',
            right: 10,
            top: 10,
          }}
        >
          <Close />
        </IconButton>
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              position: 'relative',
              height: '100%',
              backgroundColor: 'lightgrey',
              overflow: 'hidden',
            }}
          >
            {src && (
              <Canvas
                setData={setData}
                src={src}
                canvasRef={canvasRef}
                selectedProduct={data?.products?.find(
                  (p) => p?.ctl_position === selectedProductPosition
                )}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  margin: 'auto',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CanvasPlanDialog;
