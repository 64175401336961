'Use strict';
import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import LeftMenu from '../LeftMenu/LeftMenu';
import Avatar from '@material-ui/core/Avatar';
import { Link, useLocation } from 'react-router-dom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { useSelector } from 'react-redux';
import axios from 'axios';
import NotificationsBtn from '../NotificationsBtn/BtnNotif';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Header(props) {
  const { search, handleSearch, ctr, clt, role } = props;
  const classes = useStyles();
  const [roleLink, setRoleLink] = useState(null);
  const [accessToken] = useState(useSelector((state) => state.accessToken));
  const location = useLocation();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      try {
        if (accessToken) {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/info/pathRole', {
              headers: { token: accessToken },
            })
            .then((response) => {
              if (response.data && mounted) {
                setRoleLink({
                  pathname: response.data.pathname,
                  state: null,
                });
              }
            })
            .catch((err) => {
              throw err;
            });
        }
      } catch (error) {
        throw error;
      }
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, location, clt, ctr]);

  return (
    <HideOnScroll>
      <AppBar
        position="sticky"
        style={{ backgroundColor: '#01374C', zIndex: 99999999 }}
      >
        <Toolbar>
          <LeftMenu role={role} />
          <Link
            style={{ textDecoration: 'none', color: 'white' }}
            to={roleLink ? roleLink : { pathname: '/', state: location.state }}
          >
            <Typography className={classes.title} variant="h6" noWrap>
              <div className={classes.root}>
                <Avatar
                  style={{ height: '45px', width: '45px' }}
                  alt="Logo Eye Tech"
                  src={'/logo192.png'}
                />
              </div>
            </Typography>
          </Link>
          {ctr || clt || search ? (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                type="text"
                placeholder="Rechercher"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                autoFocus={false}
                onChange={handleSearch ? handleSearch : null}
                autoComplete="off"
              />
            </div>
          ) : null}
          <div className={classes.grow} />
          <div>
            <NotificationsBtn />
          </div>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}
