import React from 'react';
import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';

const CalendarToolbar = ({ toolbar }) => {
  const navigations = [
    {
      label: 'previous',
      action: 'PREV',
    },
    {
      label: 'today',
      action: 'TODAY',
    },
    {
      label: 'next',
      action: 'NEXT',
    },
  ];

  const views = ['month', 'week', 'day', 'agenda'];

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={7}>
          <ButtonGroup disableElevation variant="contained" sx={{ pb: '5px' }}>
            {navigations.map((n) => (
              <Button
                key={n.label}
                size="small"
                onClick={() => toolbar.onNavigate(n.action)}
              >
                {toolbar.localizer.messages[n.label]}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography component="h2" variant="body1" color="primary">
            {toolbar.label}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginBottom: '15px',
          }}
        >
          <ButtonGroup
            disableElevation
            variant="outlined"
            sx={{ p: '5px', pr: 0 }}
          >
            {views.map((v) => (
              <Button
                key={toolbar.localizer.messages[v]}
                size="small"
                variant={toolbar.view === v ? 'contained' : 'outlined'}
                onClick={() => toolbar.onView(v)}
              >
                {toolbar.localizer.messages[v]}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default CalendarToolbar;
