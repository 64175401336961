import React, { useEffect, useState } from 'react';
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Chip,
  Select,
  FormControl,
  Box,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

const MultiSelectCtr = ({ data, setData, options, disabled = false }) => {
  const [selectedValues, setSelectedValues] = useState(data);

  const handleBlur = () => {
    setData(selectedValues);
  };

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
  };

  useEffect(() => {
    setSelectedValues(data);
  }, [data]);

  return (
    <FormControl
      style={{
        padding: 5,
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
        top: '-4px',
      }}
      fullWidth
    >
      <InputLabel style={{ position: 'relative', left: '15px', top: '10px' }}>
        Contrats
      </InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        input={<OutlinedInput label="Contrats" fullWidth />}
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {selected.map((value) => (
              <Chip
                key={value}
                size="small"
                label={
                  options.find((option) => option.value === value)?.label ||
                  value
                }
              />
            ))}
          </Box>
        )}
      >
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            sx={{ justifyContent: 'space-between' }}
          >
            {label}
            {selectedValues.includes(value) ? <Check color="info" /> : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectCtr;
