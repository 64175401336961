import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";

const Videosurveillance = ({ steps, handleStep }) => {
  return steps.ctg_id === 1 && steps?.arf2_id === undefined ? (
    <>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 4)}
        >
          Cameras IP
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 3)}
        >
          Cameras HD
        </Button>

        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 5)}
        >
          Moniteur
        </Button>
      </ButtonGroup>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 1)}
        >
          DVR
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 2)}
        >
          NVR
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 6)}
        >
          Autres
        </Button>
      </ButtonGroup>
    </>
  ) : (
    <></>
  );
};

export default Videosurveillance;
