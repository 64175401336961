import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default function ProduitCard({
  count,
  setSelected,
  selected,
  product,
  token,
  chooseProduct,
}) {
  const useStyles = makeStyles({
    root: {
      width: '100%',
      borderRadius: '10px',
      backgroundColor: '#fff',
      border: '1px solid #fff',
      transition: 'all .2s ease',
    },
    selectedRoot: {
      width: '100%',
      borderRadius: '10px',
      backgroundColor: count ? '#fff' : '#D5EDFF',
      border: '5px solid #0A374C',
      transition: 'all .5s ease',
    },
    media: {
      height: 150,
    },
    content: {
      height: '100%',
      cursor: selected ? 'unset' : 'pointer',
    },
  });
  const classes = useStyles();

  const handleSelect = () => {
    if (chooseProduct) {
      chooseProduct();
    } else {
      return selected
        ? setSelected((select) =>
            select.filter((s) => s?.art_id !== product?.art_id)
          )
        : setSelected((select) => [...select, { ...product, quantity: 1 }]);
    }
  };

  const setQuantity = (qty) => {
    setSelected((select) =>
      select.map((sel) =>
        sel?.art_id === selected?.art_id
          ? { ...sel, quantity: parseInt(qty) }
          : sel
      )
    );
  };

  return (
    <Card className={selected ? classes.selectedRoot : classes.root}>
      <CardActionArea
        onClick={!count ? handleSelect : undefined}
        className={classes.content}
        disableRipple={count}
      >
        <CardMedia
          className={classes.media}
          image={`https://dashboard.eye-tech.fr/fileProduct.php?id=${product?.art_id}&jwt=${token}`}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="inherit"
            component="h2"
            id="base-title"
            style={{ fontSize: '14px' }}
          >
            {product?.art_nom}
          </Typography>
          {count && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'stretch',
                  paddingTop: '10px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: 0, minWidth: '40px' }}
                  onClick={() =>
                    setQuantity(
                      selected?.quantity > 1
                        ? selected?.quantity - 1
                        : selected?.quantity
                    )
                  }
                >
                  <RemoveIcon fontSize="small" />
                </Button>
                <TextField
                  type="number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: '19px',
                      fontWeight: 'bold',
                    },
                    min: 0,
                  }}
                  value={selected?.quantity}
                  size="small"
                  onChange={(e) =>
                    setQuantity(e.target.value !== '' ? e.target.value : 1)
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: 0, minWidth: '40px' }}
                  onClick={() => setQuantity(selected?.quantity + 1)}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </div>
              <Button
                fullWidth
                style={{ color: 'red', marginTop: '10px' }}
                size="small"
                onClick={() => {
                  if (
                    window.confirm(
                      'Êtes vous sur de vouloir supprimer ce produit ?'
                    )
                  )
                    handleSelect();
                }}
              >
                <DeleteOutlineIcon /> Supprimer
              </Button>
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
