import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import ViewStyle from '../ViewStyle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Profil() {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const accessToken = useSelector((state) => state.accessToken);

  const breadLink = {
    view: 'Profil',
    bread: [
      {
        name: 'Accueil',
        href: '/',
      },
      {
        name: 'Profil',
        href: '/profil',
      },
    ],
  };

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function getUser() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res.data && mounted) setUser(res.data);
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      getUser();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  let roleText = null;
  if (user && user.role === 0) {
    roleText = 'Technicien';
  } else if (user && user.role === 1) {
    roleText = 'Administrateur';
  } else if (user && user.role === 3) {
    roleText = 'Client';
  } else if (user && user.role === 4) {
    roleText = 'Contrat';
  }

  return (
    <ViewStyle breadLink={breadLink} width="lg">
      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Nom" secondary={user && user.acc_lastname} />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Prénom"
            secondary={user && user.acc_firstname}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <MailOutlineIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Email" secondary={user && user.acc_email} />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PhoneIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Téléphone"
            secondary={user && user.acc_phone1}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <SupervisorAccountIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Type de compte" secondary={roleText} />
        </ListItem>
      </List>
    </ViewStyle>
  );
}

export default Profil;
