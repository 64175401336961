'Use strict';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewStyle from '../ViewStyle';
import EyeTabs from '../../utils/Tabs/Tabs';
import Informations from './Informations/Informations';
import SAV from './SAV/SAV';
import Materiel from './Materiel/Materiel';
import Factures from './Factures/Factures';
import Archives from './Archives/Archives';
import AdresseIp from './AdresseIp/AdresseIp';
import TlsEntretien from './TlsEntretien/TlsEntretien';
import Fade from '@material-ui/core/Fade';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Centrale from './Centrale/Centrale';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    color: 'white',
    fontWeight: 'bold',
  },
}));

export default function Fiche(props) {
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();
  const breadLink = {
    view: 'Informations',
    bread: [
      {
        name: 'Clients',
        href: '/clients',
      },
      {
        name: 'Contrats',
        href: '/contrats',
      },
      {
        name: 'Informations',
        href: '/contrat/informations',
      },
    ],
  };

  const [role, setRole] = useState(null);
  const [contrat, setContrat] = useState({});
  const accessToken = useSelector((state) => state.accessToken);
  // const [centrale, setCentrale] = useState([]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     if (!props.location.state.ctr_id || !accessToken) {
  //       history.push('/');
  //     } else {
  //       axios
  //         .get(
  //           process.env.REACT_APP_BACK_API_URL +
  //             '/contrat/centrale/' +
  //             props.location.state.ctr_id,
  //           {
  //             headers: { token: accessToken },
  //           }
  //         )
  //         .then((res) => {
  //           if (res.data && mounted) setCentrale(res.data);
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     }
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  // }, [history, accessToken, props.location.state.ctr_id]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!location.state) {
        history.push({ pathname: '/', state: location.state });
      } else {
        axios
          .get(
            process.env.REACT_APP_BACK_API_URL +
              '/contrat/fiche/' +
              location.state.ctr_id,
            {
              headers: { token: accessToken },
            }
          )
          .then((res) => {
            if (res && res.data && mounted) setContrat(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      async function role() {
        try {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/role', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res && res.data && mounted) {
                setRole(res.data.role);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (error) {
          throw error;
        }
      }
      role();
    }
    return () => {
      mounted = false;
    };
  }, [location.state, history, accessToken]);

  const Tab = !location.state
    ? null
    : [
        {
          title: 'INFORMATIONS',
          view: (
            <Informations
              ctrId={props.location.state.ctr_id}
              accessToken={accessToken}
            />
          ),
        },
        {
          title: 'SAV',
          view: (
            <SAV
              ctrId={props.location.state.ctr_id}
              accessToken={accessToken}
            />
          ),
        },
        {
          title: 'MATERIEL',
          view: (
            <Materiel
              ctrId={props.location.state.ctr_id}
              accessToken={accessToken}
            />
          ),
        },
      ];

  if (location.state && (role === 1 || role === 3)) {
    Tab.push(
      {
        title: 'FACTURES',
        view: (
          <Factures
            ctrId={props.location.state.ctr_id}
            accessToken={accessToken}
          />
        ),
      },
      {
        title: 'ARCHIVES',
        view: (
          <Archives
            ctrId={props.location.state.ctr_id}
            accessToken={accessToken}
          />
        ),
      }
    );
  }
  if (location.state) {
    Tab.push({
      title: 'ADRESSE IP',
      view: (
        <AdresseIp
          ctrId={props.location.state.ctr_id}
          accessToken={accessToken}
        />
      ),
    });
  }

  if (contrat && contrat.ctr_ncors && location.state) {
    Tab.push({
      title: 'TLS/ENTRETIEN',
      view: (
        <TlsEntretien contrat={contrat} ctrId={props.location.state.ctr_id} />
      ),
    });
  }

  // if (centrale.length > 0) {
  //   Tab.push({
  //     title: 'CENTRALE ALARME',
  //     view: <Centrale centrale={centrale} />,
  //   });
  // }

  return (
    <>
      <ViewStyle
        dial="info"
        role={role}
        breadLink={breadLink}
        width="lg"
        noPadding
      >
        <Fade in={true} timeout={600}>
          <div className={classes.root}>
            <EyeTabs tabArr={!location.state ? [] : Tab} />
          </div>
        </Fade>
      </ViewStyle>
    </>
  );
}
