"Use strict";

const initState = {
    accessToken: null,
    userId: null,
};

const rootReducer = (state = initState, action) => {
    if (action.type === "SET_USER_ACCESS_TOKEN") {
        return {
            ...state,
            accessToken: action.accessToken,
        };
    } else if (action.type === "SET_USER_ID") {
        return {
            ...state,
            userId: action.userId,
        };
    }
    return state;
};

export default rootReducer;
