'Use strict';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import axios from 'axios';
import Grow from '@material-ui/core/Grow';
import { useSelector } from 'react-redux';
import EyeTableClient from '../../utils/EyeTable/EyeTableClient';
import EyeTableSAV from '../../utils/EyeTable/EyeTableSAV';
import ViewStyle from '../ViewStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: 0,
    margin: 0,
  },
  card: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function Client(props) {
  const accessToken = useSelector((state) => state.accessToken);
  const classes = useStyles();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const breadLink = {
    view: 'Clients',
    bread: [
      {
        name: 'Clients',
        href: '/clients',
      },
    ],
  };

  const [clients, setClients] = useState([]);
  const [clientsF, setClientsF] = useState([]);
  const CltColumns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Raison Sociale',
    },
    {
      id: 'contact',
      numeric: false,
      disablePadding: false,
      label: 'Contact',
    },
    {
      id: 'adress',
      numeric: false,
      disablePadding: false,
      label: 'Adresse',
    },
  ];

  const [sav, setSAV] = useState([]);
  const [savF, setSAVF] = useState([]);
  const SAVColumns = [
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      label: 'Actif',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Raison Sociale',
    },
    {
      id: 'contrat',
      numeric: false,
      disablePadding: false,
      label: 'Contrat',
    },
    {
      id: 'date',
      numeric: false,
      label: 'Date',
    },
  ];

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function clients() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + '/client', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res.data && mounted) {
                setClients(res.data);
                setClientsF(res.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      async function sav() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + '/ticket/client', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res.data && mounted) {
                setSAV(res.data);
                setSAVF(res.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      sav();
      clients();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  const handleSearch = (event) => {
    let newClt = clients.filter(
      (el) =>
        (el.clt_adr_rue &&
          el.clt_adr_rue
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1) ||
        (el.clt_raisonsociale &&
          el.clt_raisonsociale
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1) ||
        (el.clt_lastname &&
          el.clt_lastname
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1) ||
        (el.clt_firstname &&
          el.clt_firstname
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1)
    );
    let newSAV = sav.filter(
      (el) =>
        (el.ctr_ref &&
          el.ctr_ref.toUpperCase().indexOf(event.target.value.toUpperCase()) !==
            -1) ||
        (el.clt_raisonsociale &&
          el.clt_raisonsociale
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1) ||
        (el.clt_lastname &&
          el.clt_lastname
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1) ||
        (el.clt_firstname &&
          el.clt_firstname
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1)
    );
    setSAVF(newSAV);
    setClientsF(newClt);
  };

  return (
    <>
      <ViewStyle
        search
        handleSearch={handleSearch}
        clt
        noCard
        width="lg"
        breadLink={breadLink}
      >
        <Grid container spacing={1}>
          <Grow in={true} timeout={600}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.grid}>
              <Card
                style={{
                  marginTop: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingRight: 0,
                  paddingleft: 0,
                  backgroundColor: 'white',
                }}
              >
                <EyeTableClient
                  title="Clients"
                  columns={CltColumns}
                  rows={clientsF}
                  defaultSort="create"
                />
              </Card>
            </Grid>
          </Grow>
          <Grow in={true} timeout={900}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.grid}>
              <Card
                style={{
                  marginTop: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingRight: 0,
                  paddingleft: 0,
                  backgroundColor: 'white',
                }}
              >
                <EyeTableSAV
                  title="SAV"
                  columns={SAVColumns}
                  rows={savF}
                  defaultSort="create"
                />
              </Card>
            </Grid>
          </Grow>
        </Grid>
      </ViewStyle>
    </>
  );
}
