import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";

const Controle = ({ steps, handleStep }) => {
  return steps.ctg_id === 3 && steps?.arf2_id === undefined ? (
    <ButtonGroup
      orientation="horizontal"
      color="primary"
      aria-label="vertical contained primary button group"
      variant="contained"
      fullWidth={true}
      size="large"
    >
      <Button
        style={{
          height: "100px",
          padding: "20px",
          border: "white 0.1px solid",
        }}
        onClick={() => handleStep("arf2_id", 12)}
      >
        Dahua
      </Button>
      <Button
        style={{
          height: "100px",
          padding: "20px",
          border: "white 0.1px solid",
        }}
        onClick={() => handleStep("arf2_id", 13)}
      >
        Vauban
      </Button>
    </ButtonGroup>
  ) : (
    <></>
  );
};

export default Controle;
