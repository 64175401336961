'Use strict';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import StatusIcon from '../../../utils/StatusIcon/StatusIcon';
import { Divider } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import NoRows from '../../../utils/NoRows/NoRows';

function SAV({ ctrId, accessToken }) {
  const [sav, setSAV] = useState([]);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function getSav() {
        try {
          await axios
            .get(
              process.env.REACT_APP_BACK_API_URL +
                '/ticket/contrat/info/' +
                ctrId,
              {
                headers: { token: accessToken },
              }
            )
            .then((res) => {
              if (Array.isArray(res.data) && mounted) {
                setSAV(res.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (error) {
          throw error;
        }
      }
      getSav();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, ctrId]);

  return (
    <>
      {!sav || (sav.length === 0 && <NoRows />)}
      {sav && sav.length > 0 && (
        <List>
          {sav &&
            sav.map((el, i) => {
              let arianne = JSON.parse('[' + el.sav_arianne + ']')
                .join(' ')
                .split(',')
                .join(' → ');
              const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              };
              let d = new Date(el.sav_date);
              let date = d.toLocaleDateString('fr-FR', options);
              return (
                <span key={i}>
                  <ListItem
                    button
                    onClick={() => {
                      history.push({
                        pathname: '/sav',
                        state: {
                          ...location.state,
                          sav_id: el.sav_id,
                        },
                      });
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          backgroundColor: 'white',
                          border:
                            '1px solid ' +
                            (el.sav_actif === 1
                              ? 'rgba(0,255,0,0.2)'
                              : 'rgba(255, 0,0,0.1)'),
                        }}
                      >
                        <StatusIcon active={el.sav_actif} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={el.sav_arianne ? arianne : null}
                      secondary={date}
                    />
                  </ListItem>
                  <Divider />
                </span>
              );
            })}
        </List>
      )}
    </>
  );
}

export default SAV;
