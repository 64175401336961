import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        width: "100%",
        margin: "10px",
        maxWidth: "220px",
    },
});

export default function ImgCard({ data, Illustration, i }) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Card className={classes.root}>
            <CardActionArea
                onClick={() => {
                    if ((data.clt_id, data.ctr_id)) {
                        history.push({
                            pathname: "/contrat/informations",
                            state: {
                                clt_id: data.clt_id,
                                ctr_id: data.ctr_id,
                            },
                        });
                    } else if (data.clt_id) {
                        history.push({
                            pathname: "/client",
                            state: {
                                clt_id: data.clt_id,
                            },
                        });
                    }
                }}
            >
                <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    height="140"
                    image={Illustration}
                    title="Contemplative Reptile"
                />
                <CardContent
                    style={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <Typography gutterBottom variant="button">
                        {data.clt_raisonsociale}
                    </Typography>
                    <br />
                    <Typography variant="button" color="textSecondary">
                        {data.ctr_ref}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
