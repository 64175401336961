'Use strict';
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '120',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '7px',
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '100%',
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultiSelect({
  clt_id,
  ctr_id,
  sav_id,
  techniciens,
  affectation,
  role,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [names, setNames] = useState([]);
  const accessToken = useSelector((state) => state.accessToken);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      let PersonName = [];
      affectation.map((el) => {
        PersonName.push(el.acc_firstname);
        return el;
      });
      setPersonName(PersonName);
    }
    return () => {
      mounted = false;
    };
  }, [affectation]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      let Names = [];
      techniciens.map((el) => {
        Names.push(el.acc_firstname);
        return el;
      });
      setNames(Names);
    }
    return () => {
      mounted = false;
    };
  }, [techniciens]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handlePost = async () => {
    await axios
      .post(
        process.env.REACT_APP_BACK_API_URL + '/ticket/affectation',
        {
          affectation: personName,
          clt_id: clt_id,
          ctr_id: ctr_id,
          sav_id: sav_id,
        },
        {
          headers: { token: accessToken },
        }
      )
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <FormControl
        style={{ marginTop: '0px', width: '100%' }}
        className={classes.formControl}
      >
        <InputLabel
          id="mutiple-chip-label"
          style={{ paddingLeft: '10px', paddingTop: '-2px' }}
        >
          Affectation
        </InputLabel>
        <Select
          style={{
            marginBottom: '10px',
            width: '100%',
          }}
          labelId="mutiple-chip-label"
          id="mutiple-chip"
          multiple
          disabled={role !== 1 ? true : false}
          readOnly={role !== 1 ? true : false}
          value={personName}
          onChange={role !== 1 ? null : handleChange}
          onBlur={role !== 1 ? null : handlePost}
          variant="outlined"
          input={<OutlinedInput name="Affectation" id="Affectation" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {role === 1 &&
            names.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={{
                  ...getStyles(name, personName, theme),
                }}
              >
                {name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
