import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import ScreenIcon from '../ScreenIcon';
import ScreenSubtype from '../ScreenSubtype';
import StreamPagination from './StreamPagination';
import StreamGrid from './StreamGrid';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: '64px',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: 'none'
    }
}));

const StreamAppBar = ({
    open,
    setOpen,
    screen,
    ip,
    channel,
    subtype,
    page,
    setPage,
    nbPage
}) => {
    const classes = useStyles();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                        [classes.hide]: open
                    })}
                >
                    <MenuIcon />
                </IconButton>

                <StreamPagination
                    page={page}
                    setPage={setPage}
                    nbPage={nbPage}
                    show={nbPage > 1 && !channel}
                />
                <div style={{ position: 'absolute', right: 10 }}>
                    <StreamGrid
                        subtype={subtype}
                        channel={channel}
                        ip={ip}
                        setPage={setPage}
                        page={page}
                    />
                    <ScreenSubtype
                        subtype={subtype}
                        channel={channel}
                        ip={ip}
                        show={!channel}
                    />
                    <ScreenIcon screen={screen} show={!channel} />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default StreamAppBar;
