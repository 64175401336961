import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Place from '../../Place';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  maxWidth: {
    maxWidth: '800px',
  },
}));

const Bdc = ({
  action,
  setAction,
  token,
  articles,
  offre,
  setOffre,
  vente,
  loyer,
  entretien,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    vente,
    loyer,
    entretien,
    offre,
    prel: 2,
    civ: -1,
    rs: '',
    adr: '',
    ville: '',
    cp: '',
    cont: '',
    tel: '',
    email: '',
    nbmm: '60',
    ent: false,
  });

  const fillState = (v) => {
    setState((s) => ({ ...s, ...v }));
  };

  const handleContrat = () => {
    if (
      window.confirm(
        'Voulez vous vraiment créer un client et lui associer un contrat ?'
      )
    ) {
      let data = state;

      if (offre === 1) {
        data = {
          ...data,
          vente: 0,
          entretien: 0,
        };
      } else {
        data = {
          ...data,
          loyer: 0,
        };
      }
      fetch(
        `${
          process.env.REACT_APP_XAVSIT
        }/src/create-profile/insertClientContrat.php?${Object.keys(data)
          .map((d, i) =>
            data[d] !== -1 && data[d] !== ''
              ? i === 0
                ? `${d}=${encodeURI(data[d])}`
                : `&${d}=${data[d]}`
              : ''
          )
          .join('')}&art=${articles}&jwt=${token}`
      )
        .then((res) => {
          alert('Creation effectuée');
        })
        .catch((err) => {
          console.error(err);
          alert('Requete effectuée');
        });
    }
  };

  const handleBDC = () => {
    if (window.confirm('Voulez vous vraiment créer un bon de commande ?')) {
      window.open(
        `${
          process.env.REACT_APP_XAVSIT
        }/src/PDF/bon/pdf-client.php?${Object.keys(state)
          .map((d, i) =>
            state[d] !== -1 && state[d] !== ''
              ? i === 0
                ? `${d}=${encodeURI(state[d])}`
                : `&${d}=${state[d]}`
              : ''
          )
          .join('')}&art=${articles}&jwt=${token}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const disabled =
    state?.offre === -1 ||
    (state?.offre === 1 && (state?.prel === -1 || state?.nbmm === '')) ||
    (state?.offre === 2 &&
      state?.ent &&
      (state?.prel === -1 || state?.nbmm === '')) ||
    (state?.offre === 2 &&
      !state?.ent &&
      (state?.prel !== -1 || state?.nbmm !== ''));

  useEffect(() => {
    setState((state) => ({ ...state, offre, ent: false, prel: 2, nbmm: '60' }));
  }, [offre]);

  useEffect(() => {
    setState((state) => ({ ...state, vente }));
  }, [vente]);

  useEffect(() => {
    setState((state) => ({ ...state, loyer }));
  }, [loyer]);

  return action === 'BDC' ? (
    <div className={classes.root}>
      <Grid container className={classes.maxWidth} spacing={2}>
        <Grid item xs={12}>
          <Button
            startIcon={<ArrowBackIcon />}
            size="small"
            color="primary"
            onClick={() => setAction(null)}
          >
            Retourner en arrière
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Raison sociale"
            placeholder="Eye Tech"
            onChange={(e) => fillState({ rs: e.target.value })}
            value={state.rs}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Place
            onAddress={(address) => {
              const adr = address.split(',')[0];
              const cp = address.split(',')[1];
              const ville = address.split(',')[2];

              fillState({
                adr,
                cp,
                ville,
              });
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <TextField
            select
            fullWidth
            label="Civilité"
            onChange={(e) => fillState({ civ: e.target.value })}
            value={state.civ}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="secondary" />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={-1}>
              <div style={{ color: '#A2A2A2' }}>Civilité</div>
            </MenuItem>
            <MenuItem value={'M.'}>M.</MenuItem>
            <MenuItem value={'Mme.'}>Mme.</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextField
            fullWidth
            label="Contact"
            placeholder="Sitruk David"
            onChange={(e) => fillState({ cont: e.target.value })}
            value={state.cont}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={5} sm={4}>
          <TextField
            fullWidth
            label="Téléphone"
            placeholder="0612345678"
            onChange={(e) => fillState({ tel: e.target.value })}
            value={state.tel}
            type="tel"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={7} sm={4}>
          <TextField
            fullWidth
            label="Email"
            placeholder="contact@eye-tech.fr"
            type="email"
            onChange={(e) => fillState({ email: e.target.value })}
            value={state.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={state?.offre !== 2 ? 12 : 8}>
          <TextField
            select
            fullWidth
            label="Offre"
            onChange={(e) => {
              setOffre(e.target.value);
              fillState({ offre: e.target.value });
            }}
            value={state.offre}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SelectAllIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={-1}>
              <div style={{ color: '#A2A2A2' }}>Offre</div>
            </MenuItem>
            <MenuItem value={1}>Location</MenuItem>
            <MenuItem value={2}>Vente</MenuItem>
            {/* <MenuItem value={3}>Location et Vente</MenuItem> */}
          </TextField>
        </Grid>
        {state?.offre === 2 && (
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.ent}
                  onChange={(e) => fillState({ ent: e.target.checked })}
                  name="ent"
                  size="small"
                />
              }
              label="Entretien"
            />
          </Grid>
        )}
        {state?.offre !== -1 && (state?.offre !== 2 || state?.ent) && (
          <>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                label="Prélevement"
                onChange={(e) => fillState({ prel: e.target.value })}
                value={state.prel}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AutorenewIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={-1}>
                  <div style={{ color: '#A2A2A2' }}>Prélevement</div>
                </MenuItem>
                <MenuItem value={1}>Mensuel</MenuItem>
                <MenuItem value={2}>Trimestriel</MenuItem>
                <MenuItem value={3}>Annuel</MenuItem>
              </TextField>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <TextField
                label="Nombre de mois"
                placeholder="60"
                type="number"
                onChange={(e) => fillState({ nbmm: e.target.value })}
                value={state.nbmm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={handleBDC}
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            Créer un Bon de Commande
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={handleContrat}
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            Créer un contrat
          </Button>
        </Grid>
      </Grid>
    </div>
  ) : (
    <></>
  );
};

export default Bdc;
