import React, { useEffect, useState } from 'react';
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Chip,
  Select,
  FormControl,
  Box,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

const MultiSelectResp = ({
  data,
  setData,
  options,
  setSelectedContrats,
  setContratOptions,
  responsables,
}) => {
  const [selectedValues, setSelectedValues] = useState(data);

  useEffect(() => {
    if (data) setSelectedValues(data);
  }, [data]);

  useEffect(() => {
    // Update contrats options
    const contrats = responsables
      .filter((r) => data.includes(r.responsable.acc_id))
      .map((r) => r.contrats)
      .flat();
    const contratOptions = contrats.map((c) => ({
      value: c.ctr_id,
      label: c.ctr_ref,
    }));
    setContratOptions(contratOptions);
    setSelectedContrats((ctr) =>
      ctr.filter((c) => contrats.map((c) => c.ctr_id).includes(c))
    );
  }, [responsables, data, setContratOptions, setSelectedContrats]);

  const handleBlur = (event) => {
    const selectedResponsables = event.target.value;
    if (JSON.stringify(selectedResponsables) === JSON.stringify(data)) return;
    setData(selectedValues);
  };

  const handleChange = (event) => {
    const selectedResponsables = event.target.value;
    if (selectedResponsables === data) return;
    setSelectedValues(selectedResponsables);
  };

  return (
    <FormControl
      style={{
        padding: 5,
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
        top: '-4px',
      }}
      fullWidth
    >
      <InputLabel style={{ position: 'relative', left: '15px', top: '10px' }}>
        Responsable régional
      </InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={handleChange}
        onBlur={handleBlur}
        input={<OutlinedInput label="Responsable régional" fullWidth />}
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {selected.map((value) => (
              <Chip
                key={value}
                size="small"
                label={
                  options.find((option) => option.value === value)?.label ||
                  value
                }
              />
            ))}
          </Box>
        )}
      >
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            sx={{ justifyContent: 'space-between' }}
          >
            {label}
            {selectedValues.includes(value) ? <Check color="info" /> : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectResp;
