import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";

const Cat = ({ steps, handleStep }) => {
  return !steps.ctg_id ? (
    <>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("ctg_id", 1)}
        >
          Vidéo surveillance
        </Button>
      </ButtonGroup>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("ctg_id", 2)}
        >
          Alarme
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("ctg_id", 3)}
        >
          Controle
        </Button>
        {/* <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("ctg_id", 5)}
        >
          Particulier
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("ctg_id", 4)}
        >
          Autres
        </Button> */}
      </ButtonGroup>
    </>
  ) : (
    <></>
  );
};

export default Cat;
