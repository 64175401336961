import React from "react";
import Cat from "./Cat/Cat";
import Fam from "./Fam/Fam";
import Fam2 from "./Fam2/Fam2";
import Step from "./Step";

const EyeButtonGroup = ({ steps, setSteps }) => {
  const handleStep = (step, value) => {
    setSteps((steps) => ({
      ...steps,
      [step]: value,
    }));
  };

  const back = () => {
    setSteps((steps) => {
      if (steps?.arf_id) {
        return {
          ...steps,
          arf_id: undefined,
        };
      } else if (steps?.arf2_id) {
        return {
          ...steps,
          arf2_id: undefined,
          arf_id: undefined,
        };
      } else {
        return {
          ctg_id: undefined,
          arf2_id: undefined,
          arf_id: undefined,
        };
      }
    });
  };

  const reset = () => {
    setSteps({
      ctg_id: undefined,
      arf2_id: undefined,
      arf_id: undefined,
    });
  };

  return (
    <>
      <Step steps={steps} reset={reset} back={back} />
      <Cat steps={steps} handleStep={handleStep} />
      <Fam2 steps={steps} handleStep={handleStep} />
      <Fam steps={steps} handleStep={handleStep} />
    </>
  );
};

export default EyeButtonGroup;
