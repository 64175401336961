'Use strict';
import React, { useEffect } from 'react';
import ViewStyle from '../ViewStyle';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Slide from '@material-ui/core/Slide';
import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import { Fade, Grid, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import BreadcrumbSAV from '../../utils/Breadcrumb/BreadcrumbSAV';
import { toast } from 'react-toastify';

function TicketCreate(props) {
  const accessToken = useSelector((state) => state.accessToken);

  const [open1, setOpen1] = useState(true);
  const [direction1] = useState('left');
  const [result1, setResult1] = useState(null);

  const [open2, setOpen2] = useState(false);
  const [direction2] = useState('left');
  const [result2, setResult2] = useState(null);

  const [open3, setOpen3] = useState(false);
  const [direction3] = useState('left');
  const [result3, setResult3] = useState(null);

  const [open4, setOpen4] = useState(false);
  const [direction4] = useState('left');

  const [step, setStep] = useState(1);

  const [success, setSuccess] = useState(false);

  const [results, setResults] = useState({});

  const [description, setDescription] = useState('');

  const [client, setClient] = useState(null);

  const [arianne, setAriannne] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const breadLink = {
    view: 'Create',
    bread: [
      {
        name: 'Clients',
        href: '/clients',
      },
      {
        name: 'Contrats',
        href: '/contrats',
      },
      {
        name: 'Informations',
        href: '/contrat/informations',
      },
      {
        name: 'Creation de ticket',
        href: '/creation/ticket',
      },
    ],
  };

  const PreviousStep = () => {
    setAriannne((arianne) => {
      let ari = arianne;
      ari.pop();
      return ari;
    });
    if (success) {
      setSuccess(false);
      if (step === 1) {
        setOpen1(true);
      } else if (step === 2) {
        setOpen2(true);
      } else if (step === 3) {
        setOpen3(true);
      } else if (step === 4) {
        setOpen4(true);
      }
    } else {
      if (step === 2) {
        // setResult1(1);
        // setResult2(1);
        // setResult3(1);
        setOpen1(true);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        setStep((step) => step - 1);
      } else if (step === 3) {
        // setResult1(1);
        // setResult2(1);
        // setResult3(1);
        setOpen1(false);
        setOpen2(true);
        setOpen3(false);
        setOpen4(false);
        setStep((step) => step - 1);
      } else if (step === 4) {
        // setResult1(1);
        // setResult2(1);
        // setResult3(1);
        setOpen1(false);
        setOpen2(false);
        setOpen3(true);
        setOpen4(false);
        setStep((step) => step - 1);
      }
    }
  };

  const Success = (step, position, name) => {
    setAriannne((arianne) => [...arianne, name]);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setSuccess(true);
    if (step === 1) {
      setResults({
        step1: position,
        description: '',
      });
    } else if (step === 2) {
      setResults({
        step1: result1,
        step2: position,
        description: '',
      });
    } else if (step === 3) {
      setResults({
        step1: result1,
        step2: result2,
        step3: position,
        description: '',
      });
    } else if (step === 4) {
      setResults({
        step1: result1,
        step2: result2,
        step3: result3,
        step4: position,
        description: '',
      });
    }
  };

  const Reset = () => {
    setAriannne([]);
    setStep(1);
    setResult1(1);
    setResult2(1);
    setResult3(1);
    setOpen1(true);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setSuccess(false);
  };

  const ClickStep = (step, position, name) => {
    setAriannne((arianne) => [...arianne, name]);
    if (step === 1) {
      setStep(2);
      setResult1(position);
      MoveStep1();
    } else if (step === 2) {
      setStep(3);
      setResult2(position);
      MoveStep2();
    } else if (step === 3) {
      setStep(4);
      setResult3(position);
      MoveStep3();
    }
  };

  const MoveStep1 = () => {
    setOpen1(false);
    setOpen2(true);
  };

  const MoveStep2 = () => {
    setOpen2(false);
    setOpen3(true);
  };

  const MoveStep3 = () => {
    setOpen3(false);
    setOpen4(true);
  };

  const Step1 = () => {
    return (
      <Slide
        in={open1}
        timeout={600}
        direction={direction1}
        style={{ display: open1 ? 'block' : 'none' }}
      >
        <ButtonGroup
          orientation="vertical"
          color="primary"
          aria-label="vertical contained primary button group"
          variant="contained"
          fullWidth={true}
          size="large"
        >
          <Button
            onClick={() => {
              ClickStep(1, 1, 'VIDEOSURVEILLANCE');
            }}
          >
            VIDEOSURVEILLANCE
          </Button>
          <Button
            onClick={() => {
              ClickStep(1, 2, 'ALARME');
            }}
          >
            ALARME
          </Button>
          <Button
            onClick={() => {
              Success(1, 3, "CONTROLE D'ACCES");
            }}
          >
            CONTROLE D'ACCES
          </Button>
        </ButtonGroup>
      </Slide>
    );
  };

  const Step2 = () => {
    if (result1 === 1) {
      return (
        <Slide
          in={open2}
          timeout={600}
          direction={direction2}
          style={{ display: open2 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                ClickStep(2, 1, 'Connexion à distance');
              }}
            >
              Connexion à distance
            </Button>
            <Button
              onClick={() => {
                ClickStep(2, 2, 'Logiciels');
              }}
            >
              Logiciels
            </Button>
            <Button
              onClick={() => {
                ClickStep(2, 3, 'Affichage');
              }}
            >
              Affichage
            </Button>
            <Button
              onClick={() => {
                ClickStep(2, 4, 'DVR');
              }}
            >
              DVR
            </Button>
            <Button
              onClick={() => {
                Success(2, 5, 'Autres');
              }}
            >
              Autres
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else if (result1 === 2) {
      return (
        <Slide
          in={open2}
          timeout={600}
          direction={direction2}
          style={{ display: open2 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button onClick={() => ClickStep(2, 1, 'Dysfonctionnement')}>
              Dysfonctionnement
            </Button>
            <Button onClick={() => ClickStep(2, 2, 'Logiciels')}>
              Logiciels
            </Button>
            <Button onClick={() => Success(2, 3, 'Modifications à apporter')}>
              Modifications à apporter
            </Button>
            <Button onClick={() => Success(2, 4, 'Autres')}>Autres</Button>
          </ButtonGroup>
        </Slide>
      );
    } else {
      return null;
    }
  };

  const Step3 = () => {
    if (result1 === 1 && result2 === 1) {
      return (
        <Slide
          in={open3}
          timeout={600}
          direction={direction3}
          style={{ display: open3 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                Success(3, 1, 'Pas de connexion à distance');
              }}
            >
              Pas de connexion à distance
            </Button>
            <Button
              onClick={() => {
                Success(3, 2, 'Changement de box');
              }}
            >
              Changement de box
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else if (result1 === 1 && result2 === 2) {
      return (
        <Slide
          in={open3}
          timeout={600}
          direction={direction3}
          style={{ display: open3 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                Success(3, 1, 'Installation Smartphone');
              }}
            >
              Installation Smartphone
            </Button>
            <Button
              onClick={() => {
                Success(3, 2, 'Installation Mac / PC');
              }}
            >
              Installation Mac / PC
            </Button>
            <Button
              onClick={() => {
                Success(3, 2, 'Extraction d’');
              }}
            >
              Extraction d’
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else if (result1 === 1 && result2 === 3) {
      return (
        <Slide
          in={open3}
          timeout={600}
          direction={direction3}
          style={{ display: open3 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                ClickStep(3, 1, 'Camera');
              }}
            >
              Camera
            </Button>
            <Button
              onClick={() => {
                ClickStep(3, 2, 'Ecran');
              }}
            >
              Ecran
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else if (result1 === 1 && result2 === 4) {
      return (
        <Slide
          in={open3}
          timeout={600}
          direction={direction3}
          style={{ display: open3 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                Success(3, 1, "Est ce qu'il s'allume ?");
              }}
            >
              Est ce qu'il s'allume ?
            </Button>
            <Button
              onClick={() => {
                Success(3, 2, "Est ce qu'il bippe ?");
              }}
            >
              Est ce qu'il bippe ?
            </Button>
            <Button
              onClick={() => {
                Success(3, 3, "N'enregistre plus");
              }}
            >
              N'enregistre plus
            </Button>
            <Button
              onClick={() => {
                Success(3, 4, 'Autre');
              }}
            >
              Autre
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else if (result1 === 2 && result2 === 1) {
      return (
        <Slide
          in={open3}
          timeout={600}
          direction={direction3}
          style={{ display: open3 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                Success(3, 1, 'Mise en service / Hors service');
              }}
            >
              Mise en service / Hors service
            </Button>
            <Button
              onClick={() => {
                Success(3, 2, 'Intempestifs');
              }}
            >
              Intempestifs
            </Button>
            <Button
              onClick={() => {
                Success(3, 3, 'Pile / Batterie faible');
              }}
            >
              Pile / Batterie faible
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else if (result1 === 2 && result2 === 2) {
      return (
        <Slide
          in={open3}
          timeout={600}
          direction={direction3}
          style={{ display: open3 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                Success(3, 1, 'Smartphone');
              }}
            >
              Smartphone
            </Button>
            <Button
              onClick={() => {
                Success(3, 2, 'Mac / PC');
              }}
            >
              Mac / PC
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else {
      return null;
    }
  };

  const Step4 = () => {
    if (result1 === 1 && result2 === 3 && result3 === 1) {
      return (
        <Slide
          in={open4}
          timeout={600}
          direction={direction4}
          style={{ display: open4 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                Success(4, 1, 'Une seule Floue');
              }}
            >
              Une seule Floue
            </Button>
            <Button
              onClick={() => {
                Success(4, 2, 'HS');
              }}
            >
              HS
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else if (result1 === 1 && result2 === 3 && result3 === 2) {
      return (
        <Slide
          in={open4}
          timeout={600}
          direction={direction4}
          style={{ display: open4 ? 'block' : 'none' }}
        >
          <ButtonGroup
            orientation="vertical"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            fullWidth={true}
            size="large"
          >
            <Button
              onClick={() => {
                Success(4, 1, 'Ne s’allume pas');
              }}
            >
              Ne s’allume pas
            </Button>
            <Button
              onClick={() => {
                Success(4, 2, 'Pas d’image');
              }}
            >
              Pas d’image
            </Button>
          </ButtonGroup>
        </Slide>
      );
    } else {
      return null;
    }
  };

  const [contrat, setContrat] = useState({});
  const location = useLocation();
  const history = useHistory();

  const handleSubmit = (e) => {
    if (!location.state) {
      history.push({ pathname: '/', state: location.state });
    } else {
      setDisabledBtn(true);
      e.preventDefault();
      let result = results;
      result.description = description;
      result.contrat = location.state.ctr_id;
      result.client = location.state.clt_id;
      result.arianne = JSON.stringify(arianne);
      setResults(result);
      axios
        .post(process.env.REACT_APP_BACK_API_URL + '/ticket/create', results, {
          headers: { token: accessToken },
        })
        .then((res) => {
          if (res && res.data) {
            history.push({
              pathname: '/sav',
              state: {
                ...location.state,
                sav_id: res.data,
              },
            });
            toast.info('✅ SAV créé !', {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setDisabledBtn(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setDisabledBtn(false);
        });
    }
  };

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!location.state) {
        history.push({ pathname: '/', state: location.state });
      } else {
        async function client() {
          try {
            await axios
              .get(
                process.env.REACT_APP_BACK_API_URL +
                  '/client/' +
                  location.state.clt_id,
                {
                  headers: { token: accessToken },
                }
              )
              .then((result) => {
                axios
                  .get(
                    process.env.REACT_APP_BACK_API_URL +
                      '/contrat/fiche/' +
                      location.state.ctr_id,
                    {
                      headers: { token: accessToken },
                    }
                  )
                  .then((res) => {
                    if (res.data && mounted) {
                      setContrat(res.data);
                      setClient(result.data[0]);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              })
              .catch((err) => {
                console.error(err);
              });
          } catch (e) {
            throw e;
          }
        }
        client();
      }
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, history, location.state]);

  return (
    <ViewStyle breadLink={breadLink} width="lg">
      <Typography variant="h5" noWrap={true} align="center">
        {client ? (
          client.clt_raisonsociale
        ) : (
          <Skeleton animation="wave" height={60} />
        )}
      </Typography>
      <Typography
        variant="subtitle1"
        noWrap={true}
        gutterBottom
        align="center"
        color="textSecondary"
      >
        {contrat ? contrat.ctr_ref : <Skeleton animation="wave" height={60} />}
      </Typography>
      <Divider />
      <Grid container wrap="nowrap" spacing={3}>
        <Grid item xs={1} sm={1}>
          <Fade in={true}>
            <IconButton
              disabled={step === 1 && !success}
              aria-label="back step"
              style={{
                marginBottom: '10px',
                marginTop: '10px',
                opacity: step === 1 && !success ? 0.2 : 1,
              }}
              onClick={PreviousStep}
            >
              <KeyboardBackspaceIcon
                fontSize="large"
                style={{ color: '#0A374C' }}
              />
            </IconButton>
          </Fade>
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <BreadcrumbSAV array={arianne} />
        </Grid>
        <Grid item xs={1} sm={1}>
          <Fade in={true}>
            <IconButton
              disabled={step === 1 && !success}
              aria-label="replay"
              style={{
                float: 'right',
                marginBottom: '10px',
                marginTop: '10px',
                opacity: step === 1 && !success ? 0.2 : 1,
              }}
              onClick={Reset}
            >
              <ReplayIcon fontSize="large" style={{ color: '#F4970E' }} />
            </IconButton>
          </Fade>
        </Grid>
      </Grid>
      <Step1 />
      <Step2 />
      <Step3 />
      <Step4 />
      {success ? (
        <Slide in={true} timeout={600} direction={'left'}>
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{ width: '100%' }}
          >
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              value={description}
              variant="outlined"
              style={{ width: '100%', marginBottom: '10px' }}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <ButtonGroup
              orientation="vertical"
              color="primary"
              aria-label="vertical contained primary button group"
              variant="contained"
              fullWidth={true}
              size="large"
            >
              <Button type="submit" disabled={disabledBtn}>
                <AddIcon /> CRÉER LE TICKET
              </Button>
            </ButtonGroup>
          </form>
        </Slide>
      ) : null}
    </ViewStyle>
  );
}

export default TicketCreate;
