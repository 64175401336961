import React, { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import Paper from "@material-ui/core/Paper";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { InputAdornment, TextField } from "@material-ui/core";

const Place = ({ onAddress }) => {
  const [address, setAddress] = useState("");
  const searchOptions = {
    language: "fr-FR",
    componentRestrictions: { country: "fr" },
    types: ["geocode"],
  };

  const handleSelect = (address) => {
    setAddress(address);
    onAddress(address);
  };

  const ScrollTo = (e) => {
    e.target.scrollIntoView();
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            {...getInputProps({
              placeholder: "Entrez une adresse ...",
            })}
            onClick={ScrollTo}
            inputProps={{
              "aria-label": "search eye tech maps",
            }}
            fullWidth
            label="Adresse"
            placeholder="88 avenue des Ternes, 75017 Paris, France"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <Paper
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            {loading && <div style={{ padding: "10px" }}>Chargement...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              const style = suggestion.active
                ? {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                    padding: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }
                : {
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    padding: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default Place;
