'Use strict';
import React, { useEffect, useState } from 'react';
import ViewStyle from '../ViewStyle';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import Account from './Account/Account';
import { useHistory } from 'react-router';
import Synthese from './Synthese/Synthese';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function Administration() {
  const accessToken = useSelector((state) => state.accessToken);
  const history = useHistory();
  const [role, setRole] = useState(null);
  const [value, setValue] = useState(0);

  const breadLink = {
    view: 'Administration',
    bread: [
      {
        name: 'Accueil',
        href: '/',
      },
      {
        name: 'Administration',
        href: '/administration',
      },
    ],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function role() {
        try {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/role', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res && res.data && res.data.role && mounted) {
                if (
                  res.data.role !== 0 &&
                  res.data.role !== 1 &&
                  res.data.role !== 3
                ) {
                  history.push('/');
                } else setRole(res.data.role);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (error) {
          throw error;
        }
      }
      role();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, history]);

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  return (
    <ViewStyle
      noPadding
      breadLink={breadLink}
      width="lg"
      dial="admin"
      role={role}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        aria-label="scrollable auto tabs"
      >
        <Tab label={'Compte'} {...a11yProps(0)} />
        <Tab label={'Synthèse'} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Account accessToken={accessToken} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Synthese accessToken={accessToken} />
      </TabPanel>
    </ViewStyle>
  );
}

export default Administration;
