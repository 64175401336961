import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";

const Alarme = ({ steps, handleStep }) => {
  return steps.ctg_id === 2 && steps?.arf2_id === undefined ? (
    <>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 7)}
        >
          Risco Agility
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 8)}
        >
          Risco Lightsys
        </Button>

        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 9)}
        >
          Risco Prosys
        </Button>
      </ButtonGroup>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 10)}
        >
          Ajax
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf2_id", 11)}
        >
          Autres
        </Button>
      </ButtonGroup>
    </>
  ) : (
    <></>
  );
};

export default Alarme;
