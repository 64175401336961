import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function DestAlertDialog({ contrat, open, setOpen }) {
  let {
    ctr_ncors,
    ctr_ref,
    tls_pass,
    tls_lundi,
    tls_mardi,
    tls_mercredi,
    tls_jeudi,
    tls_vendredi,
    tls_samedi,
    tls_dimanche,
    tls_pass_contrainte,
    tls_obs,
    tls_nom,
    tls_qualite,
    tls_phone,
  } = contrat;

  let name = tls_nom ? tls_nom.split('\n') : null;
  let qualite = tls_qualite ? tls_qualite.split('\n') : null;
  let tel = tls_phone ? tls_phone.split('\n') : null;

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        scroll="body"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            borderRadius: '10px',
            transform: 'translateY(50px)',
            zIndex: 9999,
            marginBottom: '150px',
          },
        }}
        BackdropProps={{
          style: {
            zIndex: 999,
          },
        }}
      >
        <Box>
          <IconButton
            onClick={handleClose}
            aria-label="delete"
            style={{ float: 'right', marginRight: 5, marginTop: 5 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id="responsive-dialog-title">
            <b>Déstinataire Alerte</b>
            <br />
            <span style={{ color: 'grey' }}>{ctr_ref}</span>
          </DialogTitle>
          <DialogContent dividers>
            <Grid style={{ padding: '20px' }} container spacing={3}>
              <Grid
                style={{ textAlign: 'center' }}
                item
                xs={12}
                md={12}
                lg={12}
              >
                <Typography variant="h6">DESTINATAIRES D'ALERTES</Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Nom
                        </TableCell>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Qualité
                        </TableCell>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Téléphone
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {name &&
                        name?.map((name, i) => {
                          let telephone = tel && tel[i] ? tel[i] : null;
                          let telephoneSpace =
                            tel && tel[i] ? tel[i].replace(/\s/g, '') : null;
                          return (
                            <TableRow key={i}>
                              <TableCell align="center">
                                <Box whiteSpace="nowrap">{name}</Box>
                              </TableCell>
                              <TableCell align="center">
                                <Box whiteSpace="nowrap">
                                  {qualite && qualite[i] ? qualite[i] : null}
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                <Box whiteSpace="nowrap">
                                  <a
                                    style={{
                                      color: 'black',
                                    }}
                                    href={`tel:${telephoneSpace}`}
                                  >
                                    {telephone}
                                  </a>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid style={{ padding: '20px' }} container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  id="outlined-read-only-input"
                  label="N° ABONNÉ"
                  defaultValue={ctr_ncors}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="outlined-read-only-input"
                  label="MOT DE PASSE OK"
                  defaultValue={tls_pass || ' '}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="outlined-read-only-input"
                  label="MOT DE PASSE CONTRAINTE"
                  defaultValue={tls_pass_contrainte || ' '}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: '20px' }} container spacing={3}>
              <Grid
                style={{ textAlign: 'center' }}
                item
                xs={12}
                md={12}
                lg={12}
              >
                <Typography variant="h6">HORAIRES D'OUVERTURES</Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Lundi
                        </TableCell>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Mardi
                        </TableCell>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Mercredi
                        </TableCell>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Jeudi
                        </TableCell>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Vendredi
                        </TableCell>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Samedi
                        </TableCell>
                        <TableCell
                          // style={{ fontWeight: "bold" }}
                          align="center"
                        >
                          Dimanche
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          <Box whiteSpace="nowrap">{tls_lundi ?? ''}</Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box whiteSpace="nowrap">{tls_mardi ?? ''}</Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box whiteSpace="nowrap">{tls_mercredi ?? ''}</Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box whiteSpace="nowrap">{tls_jeudi ?? ''}</Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box whiteSpace="nowrap">{tls_vendredi ?? ''}</Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box whiteSpace="nowrap">{tls_samedi ?? ''}</Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box whiteSpace="nowrap">{tls_dimanche ?? ''}</Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: '20px' }} container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  multiline
                  id="outlined-read-only-input"
                  label="OBSERVATIONS"
                  defaultValue={tls_obs ?? ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              value="create"
              size="medium"
              fullWidth
              style={{ margin: '15px', padding: '10px' }}
            >
              Fermer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
