'Use strict';
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import axios from 'axios';

const PublicRoute = ({ component: Component, ...rest }) => {
  const [accessToken] = useState(useSelector((state) => state.accessToken));
  const [start, setStart] = useState(null);
  const [roleLink, setRoleLink] = useState('/');

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (accessToken !== null) {
        axios
          .get(process.env.REACT_APP_BACK_API_URL + '/info/pathRole', {
            headers: { token: accessToken },
          })
          .then((result) => {
            if (result.data) {
              axios
                .get(process.env.REACT_APP_BACK_API_URL + '/info/token', {
                  headers: { token: accessToken },
                })
                .then((res) => {
                  if (res.data === false) {
                    setStart(false);
                  } else {
                    setRoleLink(result.data.pathname);
                    setStart(true);
                  }
                })
                .catch((err) => {
                  setStart(false);
                  console.error(err);
                });
            } else {
              setStart(false);
            }
          })
          .catch((err) => {
            setStart(false);
            console.error(err);
          });
      } else {
        setStart(false);
      }
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  return start === null ? null : (
    <Route
      {...rest}
      render={(props) =>
        !start ? (
          <Container style={{ padding: '10px' }} component="main" maxWidth="xs">
            <Component {...props} />
          </Container>
        ) : (
          <Redirect to={{ pathname: roleLink, state: null }} />
        )
      }
    />
  );
};

export default PublicRoute;
