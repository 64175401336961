'Use strict';
import React, { useEffect } from 'react';
import ViewStyle from '../ViewStyle';
import axios from 'axios';
import { Skeleton } from '@material-ui/lab';
import { Fade, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import AppsIcon from '@material-ui/icons/Apps';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

function UserCreate(props) {
  const accessToken = useSelector((state) => state.accessToken);
  const [client, setClient] = useState(null);
  const [contrat, setContrat] = useState({});
  const location = useLocation();
  const history = useHistory();

  const [lastname, setLastname] = useState({ data: '', error: false });
  const [firstname, setFirstname] = useState({ data: '', error: false });
  const [email, setEmail] = useState({ data: '', error: false });
  const [phone, setPhone] = useState({ data: '', error: false });
  const [pass, setPass] = useState({ data: '', error: false });
  const [rpass, setRpass] = useState({ data: '', error: false });
  const [role, setRole] = useState(null);

  const [type, setType] = useState(props.match.params.type);

  const [isExist, setIsExist] = useState(false);

  const breadLink = {
    view: 'Create',
    bread: [
      {
        name: <AppsIcon style={{ marginTop: '6px' }} />,
        href: '/',
      },
      {
        name: "Creation d'utilisateur " + type,
        href: '/creation/utilisateur/' + type,
      },
    ],
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) setType(props.match.params.type);
    return () => {
      mounted = false;
    };
  }, [props.match.params.type]);

  useEffect(() => {
    if (role) {
      if (
        (type === 'technicien' && role !== 1) ||
        (type === 'client' &&
          (!location || !location.state || !location.state.clt_id)) ||
        (type === 'contrat' &&
          (!location ||
            !location.state ||
            !location.state.clt_id ||
            !location.state.ctr_id))
      ) {
        history.push({ pathname: '/', state: location.state });
      }
    }
  }, [role, type, accessToken, history, location]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function role() {
        try {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/role', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res && res.data && res.data.role && mounted) {
                setRole(res.data.role);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (error) {
          throw error;
        }
      }
      role();
    }
    return () => {
      mounted = false;
    };
  }, [role, type, accessToken, location]);

  useEffect(() => {
    if (role) {
      let mounted = true;
      if (mounted) {
        async function contrat() {
          try {
            await axios
              .get(
                process.env.REACT_APP_BACK_API_URL +
                  '/contrat/fiche/' +
                  location.state.ctr_id,
                {
                  headers: { token: accessToken },
                }
              )
              .then((res) => {
                if (res.data && mounted) setContrat(res.data);
              })
              .catch((err) => {
                console.error(err);
              });
          } catch (e) {
            throw e;
          }
        }
        async function client() {
          try {
            await axios
              .get(
                process.env.REACT_APP_BACK_API_URL +
                  '/client/' +
                  location.state.clt_id,
                {
                  headers: { token: accessToken },
                }
              )
              .then((res) => {
                if (res.data && mounted) setClient(res.data[0]);
              })
              .catch((err) => {
                console.error(err);
              });
          } catch (e) {
            throw e;
          }
        }
        if (type === 'contrat') contrat();
        if (type === 'client' || type === 'contrat') client();
      }
      return () => {
        mounted = false;
      };
    }
  }, [role, type, accessToken, location]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = false;
    if (!isExist) {
      if (lastname.data.length < 3) {
        setLastname((lastname) => {
          return { ...lastname, error: true };
        });
        toast.info('❌ Nom trop court !', {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        error = true;
      } else {
        setLastname((lastname) => {
          return { ...lastname, error: false };
        });
      }
      if (firstname.data.length < 3) {
        setFirstname((firstname) => {
          return { ...firstname, error: true };
        });
        toast.info('❌ Prénom trop court !', {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        error = true;
      } else {
        setFirstname((firstname) => {
          return { ...firstname, error: false };
        });
      }
      if (pass.data.length < 8) {
        setPass((pass) => {
          return { ...pass, error: true };
        });
        toast.info('❌ Mot de passe trop court !', {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        error = true;
      } else {
        setPass((pass) => {
          return { ...pass, error: false };
        });
      }
      if (rpass.data !== pass.data) {
        setRpass((rpass) => {
          return { ...rpass, error: true };
        });
        toast.info('❌ Les mots de passe ne sont pas identiques !', {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        error = true;
      } else {
        setRpass((rpass) => {
          return { ...rpass, error: false };
        });
      }
    }
    if (email.data.length < 6) {
      setEmail((email) => {
        return { ...email, error: true };
      });
      toast.info('❌ Email trop court !', {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      error = true;
    } else if (!validateEmail(email.data)) {
      setEmail((email) => {
        return { ...email, error: true };
      });
      toast.info('❌ Email incorrect !', {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      error = true;
    } else {
      setEmail((email) => {
        return { ...email, error: false };
      });
    }

    if (
      (type === 'technicien' && role !== 1) ||
      (type === 'client' &&
        (!location || !location.state || !location.state.clt_id)) ||
      (type === 'contrat' &&
        (!location ||
          !location.state ||
          !location.state.clt_id ||
          !location.state.ctr_id))
    ) {
      error = true;
    }

    if (!error) {
      axios
        .post(
          process.env.REACT_APP_BACK_API_URL + '/user/create/' + type,
          {
            ctr_id:
              location && location.state && location.state.ctr_id
                ? location.state.ctr_id
                : null,
            clt_id:
              location && location.state && location.state.clt_id
                ? location.state.clt_id
                : null,
            nom: lastname.data,
            prenom: firstname.data,
            email: email.data,
            phone: phone.data,
            pass: pass.data,
          },
          {
            headers: { token: accessToken },
          }
        )
        .then((res) => {
          if (res.data) {
            history.push({
              pathname: '/administration',
              state: location.state,
            });
            toast.info('✅ Utilisateur crée !', {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          toast.info('❌ Erreur.', {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.error(err);
        });
    }
  };

  const checkEmail = (e) => {
    e.preventDefault();
    axios
      .post(
        process.env.REACT_APP_BACK_API_URL + '/user/email',
        {
          email: email.data,
        },
        {
          headers: { token: accessToken },
        }
      )
      .then((res) => {
        if (res) {
          setIsExist((isExist) => res.data);
        }
      });
  };

  return (
    <ViewStyle breadLink={breadLink} width="lg">
      {type === 'technicien' && (
        <Typography variant="h5" noWrap={true} align="center" gutterBottom>
          Créer un compte Technicien
        </Typography>
      )}

      {(type === 'client' || type === 'contrat') && (
        <Typography variant="h5" noWrap={true} align="center" gutterBottom>
          {client ? (
            client.clt_raisonsociale
          ) : (
            <Skeleton animation="wave" height={60} />
          )}
        </Typography>
      )}

      {type === 'contrat' && (
        <Typography
          variant="subtitle1"
          noWrap={true}
          gutterBottom
          align="center"
          color="textSecondary"
        >
          {contrat ? (
            contrat.ctr_ref
          ) : (
            <Skeleton animation="wave" height={60} />
          )}
        </Typography>
      )}
      {type === 'client' || type === 'contrat' ? <Divider /> : null}
      <Fade in={true}>
        <form style={{ marginTop: '15px' }} onSubmit={handleSubmit} noValidate>
          <TextField
            error={email.error}
            value={email.data}
            onChange={(e) => {
              setEmail((email) => {
                return { ...email, data: e.target.value };
              });
            }}
            onBlur={type === 'technicien' ? null : checkEmail}
            fullWidth
            style={{ marginBottom: '10px' }}
            size="medium"
            label="Adresse email*"
            variant="outlined"
            type="email"
            autoComplete="off"
          />
          {!isExist && (
            <>
              <TextField
                error={lastname.error}
                value={lastname.data}
                onChange={(e) => {
                  setLastname((lastname) => {
                    return {
                      ...lastname,
                      data: e.target.value,
                    };
                  });
                }}
                fullWidth
                style={{ marginBottom: '10px' }}
                size="medium"
                label="Nom*"
                variant="outlined"
                type="text"
                autoComplete="off"
              />
              <TextField
                error={firstname.error}
                value={firstname.data}
                onChange={(e) => {
                  setFirstname((firstname) => {
                    return {
                      ...firstname,
                      data: e.target.value,
                    };
                  });
                }}
                fullWidth
                style={{ marginBottom: '10px' }}
                size="medium"
                label="Prénom*"
                variant="outlined"
                type="text"
                autoComplete="off"
              />
              <TextField
                error={phone.error}
                value={phone.data}
                onChange={(e) => {
                  setPhone((phone) => {
                    return {
                      ...phone,
                      data: e.target.value,
                    };
                  });
                }}
                fullWidth
                style={{ marginBottom: '10px' }}
                size="medium"
                label="Téléphone"
                variant="outlined"
                type="tel"
                autoComplete="off"
              />
              <TextField
                error={pass.error}
                value={pass.data}
                onChange={(e) => {
                  setPass((pass) => {
                    return {
                      ...pass,
                      data: e.target.value,
                    };
                  });
                }}
                fullWidth
                style={{ marginBottom: '10px' }}
                size="medium"
                label="Mot de passe*"
                variant="outlined"
                type="password"
                autoComplete="new-password"
              />
              <TextField
                error={rpass.error}
                value={rpass.data}
                onChange={(e) => {
                  setRpass((rpass) => {
                    return {
                      ...rpass,
                      data: e.target.value,
                    };
                  });
                }}
                fullWidth
                style={{ marginBottom: '10px' }}
                size="medium"
                label="Répétez le mot de passe*"
                variant="outlined"
                type="password"
                autoComplete="new-password"
              />
            </>
          )}
          <Button
            type="submit"
            style={{ float: 'right' }}
            variant="contained"
            color="primary"
          >
            {isExist ? "Ajouter à l'utilisateur" : 'Ajouter un utilisateur'}
          </Button>
        </form>
      </Fade>
    </ViewStyle>
  );
}

export default UserCreate;
