"Use strict";
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import FolderIcon from "@material-ui/icons/Folder";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import Grow from "@material-ui/core/Grow";

export default function ClickList({ dense, secondary, primary, link }) {
    return (
        <List dense={dense}>
            {
                <a
                    href={link}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Grow in={true} timeout={300}>
                        <ListItem button>
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    primary ? (
                                        <span
                                            style={{
                                                color: "#0A374C",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {primary}
                                        </span>
                                    ) : null
                                }
                                secondary={secondary ? secondary : null}
                            />
                            {window.innerWidth < 500 ? null : (
                                <Tooltip title="Ouvrir" aria-label="open file">
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="open with arrow"
                                            color="secondary"
                                        >
                                            <ArrowForwardIosRoundedIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </Tooltip>
                            )}
                        </ListItem>
                    </Grow>
                </a>
            }
        </List>
    );
}
