'Use strict';
import React from 'react';
import ProductList from '../../../utils/ProductList/ProductList';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import NoRows from '../../../utils/NoRows/NoRows';
import Divider from '@material-ui/core/Divider';

function Materiel({ ctrId, accessToken }) {
  const [materiel, setMateriel] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      axios
        .get(
          process.env.REACT_APP_BACK_API_URL + '/contrat/materiel/' + ctrId,
          {
            headers: { token: accessToken },
          }
        )
        .then((materiel) => {
          if (materiel.data && mounted) {
            setMateriel(materiel.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      mounted = false;
    };
  }, [ctrId, accessToken]);

  return (
    <>
      {materiel.length !== 0 ? (
        materiel.map((el, i) => {
          return (
            <div key={i}>
              <ProductList accessToken={accessToken} article={el} />
              {i === materiel.length - 1 ? null : <Divider variant="middle" />}
            </div>
          );
        })
      ) : (
        <NoRows />
      )}
    </>
  );
}

export default Materiel;
