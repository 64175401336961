import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';

const DatePicker = ({ label, value, onChange, minDate, maxDate }) => {
  return (
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      format="DD/MM/yyyy"
      margin="normal"
      id="date-picker-inline"
      label={label}
      inputVariant="outlined"
      fullWidth
      InputLabelProps={{
        style: { position: 'relative', left: '1px', top: '16px' },
      }}
      style={{
        padding: 5,
        position: 'relative',
        bottom: '4px',
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }}
      value={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  );
};

export default DatePicker;
