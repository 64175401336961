'Use strict';
import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Grow from '@material-ui/core/Grow';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ViewStyle from '../ViewStyle';
import ProduitCard from '../../utils/Produits/ProduitCard';
import EyeButtonGroup from '../../utils/Produits/EyeButtonGroup';
import Pagination from '@material-ui/lab/Pagination';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Badge, IconButton } from '@material-ui/core';
import ProductDialog from '../../utils/Produits/ProductDialog';

const MAX_PER_PAGE = 18;

export default function Produits() {
  const history = useHistory();
  const accessToken = useSelector((state) => state.accessToken);
  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [token, setToken] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dialog, setDialog] = useState(false);
  const [search, setSearch] = useState('');
  const [steps, setSteps] = useState({
    arf_id: undefined,
    arf2_id: undefined,
    ctg_id: undefined,
  });
  const [adminConfig, setadminConfig] = useState({
    client_coef_pa: undefined,
    client_prix_journee: undefined,
    client_coef_loyer: undefined,
    client_min_entretien: undefined,
  });

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const breadLink = {
    view: 'Produits',
    bread: [
      {
        name: 'Produits',
        href: '/produits',
      },
    ],
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function products() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + '/produits', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res && res.data) {
                const {
                  client_coef_pa,
                  client_prix_journee,
                  client_coef_loyer,
                  client_min_entretien,
                  client_min_install,
                  client_coef_install,
                  client_coef_entretien,
                } = res.data.config;
                setadminConfig({
                  client_coef_pa,
                  client_prix_journee,
                  client_coef_loyer,
                  client_min_entretien,
                  client_min_install,
                  client_coef_install,
                  client_coef_entretien,
                });
                setProducts(res.data.products);
                setToken(res.data.token);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      async function role() {
        try {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/role', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res?.data?.role !== 1 && mounted) history.push('/');
            })
            .catch((err) => {
              history.push('/');
              console.error(err);
            });
        } catch (error) {
          throw error;
        }
      }
      products();
      role();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, history]);

  const totNumPages = (prod) => Math.ceil(prod.length / MAX_PER_PAGE);

  useEffect(() => {
    if (search !== '') setPage(1);
  }, [search]);

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const normalizedContains = useCallback((haystack, needle) => {
    const regExp = new RegExp(removeDiacritics(needle), 'gi');
    return regExp.test(removeDiacritics(haystack));
  }, []);

  const findMatching = useCallback(
    (strToMatch, prod) => {
      return prod.filter((p) => normalizedContains(p?.art_nom, strToMatch));
    },
    [normalizedContains]
  );

  const stepFilter = useCallback(
    (arr) =>
      arr.filter((p) => {
        if (steps?.arf_id) {
          return (
            p?.ctg_id === steps?.ctg_id &&
            p?.arf2_id === steps?.arf2_id &&
            p?.arf_id === steps?.arf_id
          );
        } else if (steps?.arf2_id) {
          return p?.ctg_id === steps?.ctg_id && p?.arf2_id === steps?.arf2_id;
        } else if (steps?.ctg_id) {
          return p?.ctg_id === steps?.ctg_id;
        }
        return p;
      }),
    [steps]
  );

  useEffect(() => {
    setNewProducts(() => {
      const prod =
        search !== ''
          ? findMatching(search, stepFilter(products))
          : stepFilter(products);
      setTotalPage(totNumPages(prod));
      return prod.slice((page - 1) * MAX_PER_PAGE, page * MAX_PER_PAGE);
    });
  }, [products, search, page, stepFilter, findMatching]);

  return (
    <>
      <ViewStyle
        noCard
        noPadding
        search
        handleSearch={(e) => setSearch(e.target.value)}
        width="lg"
        breadLink={breadLink}
      >
        <ProductDialog
          openDialog={dialog}
          setOpenDialog={setDialog}
          title="Sélection de produits"
          selected={selected}
          setSelected={setSelected}
          token={token}
          adminConfig={adminConfig}
        />
        <IconButton
          onClick={() => {
            setDialog((d) => !d);
          }}
          style={{
            zIndex: 20,
            backgroundColor: '#0A374C',
            color: 'white',
            position: 'fixed',
            bottom: '25px',
            right: '25px',
          }}
        >
          <Badge badgeContent={selected?.length} color="secondary">
            <ReceiptIcon />
          </Badge>
        </IconButton>
        <Grid container style={{ padding: 10 }} spacing={1} justify="center">
          <Grow in={true} timeout={600}>
            <Grid item xs={12}>
              <EyeButtonGroup steps={steps} setSteps={setSteps} />
            </Grid>
          </Grow>
          {newProducts.map((p, i) => (
            <Grow in={true} timeout={600} key={`${i} produit`}>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <ProduitCard
                  setSelected={setSelected}
                  selected={!!selected.find((s) => s?.art_id === p?.art_id)}
                  product={p}
                  token={token}
                />
              </Grid>
            </Grow>
          ))}
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Pagination
              count={totalPage}
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </Grid>
        </Grid>
      </ViewStyle>
    </>
  );
}
