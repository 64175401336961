'Use strict';
import React, { useState } from 'react';
import { withStyles, lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NoRows from '../NoRows/NoRows';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DestAlertDialog from '../../DestAlertDialog';
import ClearIcon from '@material-ui/icons/Clear';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, columns } = props; //onRequestSort, columns } = props;
  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        {columns
          ? columns.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <Box
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {headCell.label}
                </Box>
              </TableCell>
            ))
          : null}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title } = props;

  return (
    <Toolbar>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '500px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function EyeTable({ title, rows, columns, defaultSort, cltId }) {
  const classes = useStyles();
  const [openDest, setOpenDest] = useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(defaultSort);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const location = useLocation();
  const history = useHistory();
  const [contrat, setContrat] = useState(undefined);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAlert = (event, contrat) => {
    event.stopPropagation();
    setContrat(contrat);
    setOpenDest(true);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const capitaliseFirstLetter = (string) => {
    return string
      .split(' ')
      .map((el, i) => {
        return el.charAt(0).toUpperCase() + el.slice(1);
      })
      .join(' ')
      .split('-')
      .map((el, i) => {
        return el.charAt(0).toUpperCase() + el.slice(1);
      })
      .join('-');
  };

  return (
    <div className={classes.root}>
      {contrat && openDest && (
        <DestAlertDialog
          contrat={contrat}
          open={openDest}
          setOpen={setOpenDest}
        />
      )}
      <EnhancedTableToolbar title={title} />
      {rows && rows.length ? (
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              columns={columns}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.length
                ? stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      let d = new Date(row.ctr_date);
                      let date = moment(d).format('DD/MM/YYYY');
                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={row.ctr_id}
                          onClick={() => {
                            history.push({
                              pathname: '/contrat/informations',
                              state: {
                                ...location.state,
                                ctr_id: row.ctr_id,
                                clt_id: cltId,
                              },
                            });
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <TableCell
                            align="left"
                            padding="none"
                            size="small"
                            style={{ paddingLeft: '40px' }}
                          >
                            <Box>
                              {row?.ctr_ncors ? (
                                <IconButton
                                  size="small"
                                  onClick={(e) => handleAlert(e, row)}
                                  sx={{ ml: '40px' }}
                                >
                                  <NotificationsActiveIcon
                                    fontSize="small"
                                    style={{
                                      transform: 'scale(0.8)',
                                    }}
                                    htmlColor="#FF5E4D"
                                  />
                                </IconButton>
                              ) : (
                                <ClearIcon
                                  fontSize="small"
                                  style={{
                                    position: 'relative',
                                    top: '3px',
                                    transform: 'scale(0.8)',
                                  }}
                                  htmlColor="#333"
                                />
                              )}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            padding="default"
                            size="small"
                          >
                            <Box whiteSpace="nowrap">
                              {row.ctr_ref ? row.ctr_ref.toUpperCase() : ''}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            padding="default"
                            size="small"
                          >
                            <Box whiteSpace="nowrap">{date}</Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            padding="default"
                            size="small"
                          >
                            <Box whiteSpace="nowrap">
                              {row.ctr_adr_install
                                ? capitaliseFirstLetter(
                                    row.ctr_adr_install.toLowerCase()
                                  )
                                : null}
                            </Box>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })
                : null}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoRows />
      )}
      {rows ? (
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
}
