'Use strict';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

function Informations({ ctrId, accessToken }) {
  const location = useLocation();
  const history = useHistory();
  const [contrat, setContrat] = useState(null);
  const [date, setDate] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!location.state) {
        history.push({ pathname: '/', state: location.state });
      } else {
        axios
          .get(process.env.REACT_APP_BACK_API_URL + '/contrat/fiche/' + ctrId, {
            headers: { token: accessToken },
          })
          .then((res) => {
            if (res.data && mounted) {
              setContrat(res.data);
              if (res.data.ctr_date) {
                const options = {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                };
                let d = new Date(res.data.ctr_date);
                let date = d.toLocaleDateString('fr-FR', options);
                setDate(date);
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
    return () => {
      mounted = false;
    };
  }, [location.state, history, accessToken, ctrId]);

  useEffect(() => {
    async function userAccount() {
      try {
        if (ctrId) {
          await axios
            .get(
              process.env.REACT_APP_BACK_API_URL + '/user/contrat/' + ctrId,
              {
                headers: { token: accessToken },
              }
            )
            .then((res) => {
              if (res && res.data) {
                setUsers(res.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } catch (e) {
        throw e;
      }
    }
    userAccount();
  }, [accessToken, location, history, ctrId]);

  return (
    <>
      <Grid container spacing={1} style={{ padding: '10px' }}>
        <Grow in={true} timeout={300}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <ListItemText
              style={{ textAlign: 'center', padding: '15px' }}
              primary="Référence"
              secondary={
                contrat && contrat.ctr_ref ? (
                  contrat.ctr_ref
                ) : (
                  <span role="img" aria-label="empty">
                    ∅
                  </span>
                )
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={600}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <ListItemText
              style={{ textAlign: 'center', padding: '15px' }}
              primary="Création"
              secondary={
                date ? (
                  date
                ) : (
                  <span role="img" aria-label="empty">
                    ∅
                  </span>
                )
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1200}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <ListItemText
              style={{ textAlign: 'center', padding: '15px' }}
              primary="Entretien"
              secondary={
                contrat && contrat.ctr_contrat_entretien ? (
                  contrat.ctr_contrat_entretien
                ) : (
                  <span role="img" aria-label="empty">
                    ∅
                  </span>
                )
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1500}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <ListItemText
              style={{ textAlign: 'center', padding: '15px' }}
              primary="№ de contrat"
              secondary={
                contrat && contrat.ctr_num_contrat ? (
                  contrat.ctr_num_contrat
                ) : (
                  <span role="img" aria-label="empty">
                    ∅
                  </span>
                )
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1700}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ListItemText
              style={{ textAlign: 'center', padding: '15px' }}
              primary="Adresse du contrat"
              secondary={
                contrat && contrat.ctr_adr_install ? (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                      contrat.ctr_adr_install
                    )}`}
                    style={{ color: '#' }}
                  >
                    {contrat.ctr_adr_install}
                  </a>
                ) : (
                  <span role="img" aria-label="empty">
                    ∅
                  </span>
                )
              }
            />
          </Grid>
        </Grow>
      </Grid>
      {users && users.length ? (
        <List
          style={{
            backgroundColor: 'rgba(244, 151, 14, .05)',
          }}
          dense={false}
        >
          {users.map((el, i) => {
            return (
              <span key={i}>
                <ListItem>
                  <ListItemAvatar>
                    <SupervisorAccountIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${el.acc_firstname} ${el.acc_lastname}`}
                  />
                </ListItem>
                {i !== users.length - 1 && <Divider />}
              </span>
            );
          })}
        </List>
      ) : null}
    </>
  );
}

export default Informations;
