import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";

const Ajax = ({ steps, handleStep }) => {
  return steps.ctg_id === 1 &&
    steps?.arf2_id === 10 &&
    steps?.arf_id === undefined ? (
    <>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 41)}
        >
          Centrale
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 42)}
        >
          Radars
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 43)}
        >
          Contact
        </Button>
      </ButtonGroup>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 44)}
        >
          Chocs
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 45)}
        >
          Sirene
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 46)}
        >
          Autres
        </Button>
      </ButtonGroup>
    </>
  ) : (
    <></>
  );
};

export default Ajax;
