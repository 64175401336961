import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { Slide } from '@material-ui/core';

const ScreenIcon = ({ screen, show }) => {
    return (
        <Slide in={show} direction="down">
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={screen.active ? screen.exit : screen.enter}
                edge="start"
                size="medium"
            >
                {screen.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
        </Slide>
    );
};

export default ScreenIcon;
