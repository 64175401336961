'Use strict';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ChatIcon from '@material-ui/icons/Chat';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useHistory, useLocation } from 'react-router-dom';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  newTool: {
    backgroundColor: '#0a374c !important',
    color: 'white !important',
  },
}));

export default function BtnDial({ dial, role, openProposition }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  let actions = [];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (dial === 'info' && (role === 1 || role === 3)) {
    actions = [
      {
        icon: <ChatIcon className={classes.newTool} />,
        name: 'SAV',
        pathname: '/creation/ticket',
      },
      {
        icon: <GroupAddIcon className={classes.newTool} />,
        name: 'Utilisateur',
        pathname: '/creation/utilisateur/contrat',
      },
    ];
  } else if (dial === 'info') {
    actions = [
      {
        icon: <ChatIcon className={classes.newTool} />,
        name: 'SAV',
        pathname: '/creation/ticket',
      },
    ];
  } else if (dial === 'ctr' && role === 1) {
    actions = [
      {
        icon: <GroupAddIcon className={classes.newTool} />,
        name: 'Utilisateur',
        pathname: '/creation/utilisateur/client',
      },
    ];
  } else if (dial === 'admin' && role === 1) {
    actions = [
      {
        icon: <GroupAddIcon className={classes.newTool} />,
        name: 'Technicien',
        pathname: '/creation/utilisateur/technicien',
      },
    ];
  } else if (dial === 'proposition' && (role === 1 || role === 0)) {
    actions = [
      {
        icon: <NoteAddIcon className={classes.newTool} />,
        name: 'Créer une proposition',
        onClick: () => openProposition(true),
      },
    ];
  } else {
    return <span></span>;
  }

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions &&
          actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={
                action?.onClick
                  ? action?.onClick
                  : () => {
                      history.push({
                        pathname: action.pathname,
                        state: location.state,
                      });
                    }
              }
            />
          ))}
      </SpeedDial>
    </>
  );
}
