'Use strict';
import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ResetPass from './utils/ResetPass/ResetPass';

const PrivateRoute = ({ component: Component, Button, ...rest }) => {
  const [accessToken] = useState(useSelector((state) => state.accessToken));
  const [start, setStart] = useState(null);
  const location = useLocation();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      try {
        if (accessToken !== null) {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/info/token', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res.data === false) {
                setStart(false);
              } else {
                setStart(true);
              }
            })
            .catch((err) => {
              setStart(false);
              console.error(err);
            });
        } else {
          setStart(false);
        }
      } catch (e) {
        throw e;
      }
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, location]);

  return start === null ? null : (
    <Route
      {...rest}
      render={(props) =>
        start ? (
          <>
            <ResetPass />
            <Component Button {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: null,
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
