import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Slide } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '0px 15px',
        border: '1px solid rgba(255,255,255,.33)',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,.33)'
        }
    }
}));

const ScreenSubtype = ({ subtype, channel, ip, show }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const quality = async (newSubtype) => {
        await handleClose();
        if (ip)
            history.push({
                pathname: '/streaming',
                search: channel
                    ? `?ip=${ip}&channel=${channel}&subtype=${newSubtype}`
                    : `?ip=${ip}&subtype=${newSubtype}`,
                state: location.state
            });
    };

    return (
        <>
            <Slide in={show} direction="down">
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit"
                    size="small"
                    className={classes.button}
                >
                    {subtype === '1' ? 'SD' : 'HD'}
                </Button>
            </Slide>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    {subtype === '1' ? 'SD' : 'HD'}
                </MenuItem>
                <MenuItem onClick={() => quality(subtype === '1' ? 0 : 1)}>
                    {subtype === '1' ? 'HD' : 'SD'}
                </MenuItem>
            </Menu>
        </>
    );
};

export default ScreenSubtype;
