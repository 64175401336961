'Use strict';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

function StatusIcon({ children, big, active, button }) {
  let StyledBadge = withStyles((theme) => ({
    badge: big
      ? {
          cursor: 'pointer',
          width: '15px',
          height: '15px',
          backgroundColor: active === 0 ? 'red' : '#44b700',
          color: '#44b700',
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: -1,
            left: -1,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: active === 0 ? '' : '$ripple 3s infinite ease-in-out',
            border: active === 0 ? '' : '1px solid currentColor',
            content: '""',
          },
        }
      : {
          cursor: 'pointer',
          backgroundColor: active === 0 ? 'red' : '#44b700',
          color: '#44b700',
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: -1,
            left: -1,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: active === 0 ? '' : '$ripple 3s infinite ease-in-out',
            border: active === 0 ? '' : '1px solid currentColor',
            content: '""',
          },
        },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '30%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

  return (
    <StyledBadge
      onClick={button ? button : null}
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
    >
      {children}
    </StyledBadge>
  );
}

export default StatusIcon;
