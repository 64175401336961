"Use strict";
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Dialog from "@material-ui/core/Dialog";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Button } from "@material-ui/core";

export default function PreviewDialog({
    openDialog,
    setOpenDialog,
    selectedFile,
    sendFile,
}) {
    return (
        <Dialog
            onClose={() => {
                setOpenDialog(!openDialog);
            }}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
        >
            <span style={{ padding: "10px" }}>
                <List>
                    {selectedFile && (
                        <ListItem>
                            <div
                                style={{
                                    backgroundColor: "#e9e9e9",
                                    verticalAlign: "middle",
                                    maxWidth: "500px",
                                    overflow: "scroll",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    display: "block",
                                    // margin: "auto",
                                }}
                            >
                                <FileCopyIcon
                                    style={{
                                        color: "#0a374c",
                                        width: "35px",
                                        height: "35px",
                                        verticalAlign: "middle",
                                    }}
                                />
                                {selectedFile.name}
                            </div>
                        </ListItem>
                    )}
                    <ListItem>
                        <div
                            style={{
                                display: "block",
                                margin: "auto",
                                position: "relative",
                                top: "8px",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={sendFile}
                            >
                                ENVOYER
                            </Button>
                        </div>
                    </ListItem>
                </List>
            </span>
        </Dialog>
    );
}
