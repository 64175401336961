'Use strict';
import React, { useEffect, useState } from 'react';
import ViewStyle from '../ViewStyle';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Schedule from './Schedule';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import { Button, useMediaQuery } from '@material-ui/core';
import moment from 'moment';

const DEFAULT_VIEW = 'month';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function Synthese() {
  const accessToken = useSelector((state) => state.accessToken);
  const [notifications, setNotifications] = useState([]);
  const [updatedNotifications, setUpdatedNotifications] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment());
  const [currentView, setCurrentView] = useState(DEFAULT_VIEW);
  const [config, setConfig] = useState({
    from: moment(currentDate).startOf(DEFAULT_VIEW).toISOString(),
    to: moment(currentDate).endOf(DEFAULT_VIEW).toISOString(),
  });
  const [tab, setTab] = useState(0);
  const history = useHistory();
  const matches = useMediaQuery('(max-width:450px)');
  const breadLink = {
    view: 'Alarme intrusion',
    bread: [
      {
        name: 'Accueil',
        href: '/',
      },
      {
        name: 'Alarme intrusion',
        href: '/synthèse',
      },
    ],
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function role() {
        try {
          axios
            .get(
              process.env.REACT_APP_BACK_API_URL + '/client/is-notifications',
              {
                headers: { token: accessToken },
              }
            )
            .then((response) => {
              if (response.data && mounted) {
                if (!isNaN(response.data.count) && response.data.count === 0)
                  history.push('/');
              } else history.push('/');
            });
        } catch (error) {
          throw error;
        }
      }
      role();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, history]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const updateTimes = (date = currentDate, view = currentView) => {
    let start = undefined;
    let end = undefined;
    const updateDate = moment(date);
    if (view === 'day') {
      start = updateDate.startOf('day').toISOString();
      end = updateDate.endOf('day').toISOString();
    } else if (view === 'week') {
      start = updateDate.startOf('isoWeek').toISOString();
      end = updateDate.endOf('isoWeek').toISOString();
    } else if (view === 'month') {
      start = updateDate.startOf('month').toISOString();
      end = updateDate.endOf('month').toISOString();
    } else if (view === 'agenda') {
      start = updateDate.startOf('day').toISOString();
      end = updateDate.endOf('day').add(30, 'day').toISOString();
    }
    if (start && end)
      setConfig({
        from: start,
        to: end,
      });
  };

  const onView = (view) => {
    setCurrentView(view);
    updateTimes(currentDate, view);
  };

  const onNavigate = (date, view, action) => {
    const newDate = moment(date);
    setCurrentDate(newDate);
    updateTimes(newDate, view);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (matches) setCurrentView('agenda');
  }, [matches]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function notif() {
        try {
          await axios
            .get(
              `${process.env.REACT_APP_BACK_API_URL}/notif-centrale?du=${moment(
                config.from
              ).toISOString()}&au=${moment(config.to).toISOString()}`,
              {
                headers: { token: accessToken },
              }
            )
            .then((res) => {
              if (res.data && mounted) {
                setNotifications(res.data);
                setUpdatedNotifications(res.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      notif();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, config]);

  const handleSearch = (event) => {
    if (event.target.value) {
      let newNotif = notifications.filter(
        (el) =>
          el.ctr_ref.toUpperCase().indexOf(event.target.value.toUpperCase()) !==
          -1
      );
      setUpdatedNotifications(newNotif);
    } else setUpdatedNotifications(notifications);
  };

  return (
    <ViewStyle
      noPadding
      breadLink={breadLink}
      width="xl"
      search
      handleSearch={handleSearch}
    >
      <AppBar position="static" color="default">
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          orientation={'horizontal'}
          variant="scrollable"
          value={tab}
          onChange={handleChangeTab}
          aria-label="Vertical tabs example"
          scrollButtons="on"
        >
          {updatedNotifications.map((notif, i) => (
            <Tab key={notif.ctr_ref} label={notif.ctr_ref} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>
      {updatedNotifications.map((notif, i) => {
        return (
          <TabPanel value={tab} index={i} key={i}>
            <Schedule
              currentView={currentView}
              onNavigate={onNavigate}
              onView={onView}
              setConfig={setConfig}
              events={notif.notifications}
              ctr_ref={notif.ctr_ref}
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  marginTop: '15px',
                  border: '1px solid #CCCCCC',
                }}
                onClick={() => {
                  history.push({
                    pathname: '/contrat/informations',
                    state: {
                      ctr_id: notif.ctr_id,
                      clt_id: notif.clt_id,
                    },
                  });
                }}
              >
                Accéder au contrat
              </Button>
            </div>
          </TabPanel>
        );
      })}
    </ViewStyle>
  );
}

export default Synthese;
