'Use strict';
import React from 'react';
import ReactDOM from 'react-dom';
import Contrats from './components/view/Contrat/Contrat';
import Client from './components/view/Client/Client';
import Connexion from './components/view/Connexion/Connexion';
import Support from './components/view/Support/Support';
import Administration from './components/view/Administration/Administration';
import NotFound from './components/view/NotFound/NotFound';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers/rootReducer';
import { loadState, saveState } from './localStorage';
import './static/css/index.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Profil from './components/view/Profil/Profil';
import ClientProfile from './components/view/ClientProfile/ClientProfile';
import SupportType from './components/view/SupportType/SupportType';
import { frFR } from '@material-ui/core/locale';
import TicketCreate from './components/view/TicketCreate/TicketCreate';
import UserCreate from './components/view/UserCreate/UserCreate';
import Fiche from './components/view/Informations/Fiche';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Ticket from './components/view/Ticket/Ticket';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Synthese from './components/view/Synthese/Synthese';
import Streaming from './components/view/Streaming/Streaming';
import CssBaseline from '@material-ui/core/CssBaseline';
import Produits from './components/view/Produits/Produits';
import Propositions from './components/view/Propositions/Propositions';
import Reporting from './components/view/Reporting/Reporting';

require('dotenv').config();

const theme = createMuiTheme(
  {
    palette: {
      type: 'light',
      primary: {
        main: '#0A374C',
      },
      secondary: {
        main: '#F4970E',
      },
    },
  },
  frFR
);

const persistedState = loadState();

const store = createStore(rootReducer, persistedState);

store.subscribe(() => {
  saveState(store.getState());
});

const Root = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path="/" component={Connexion} />
        <Route exact path="/sav" component={Ticket} />
        <PrivateRoute exact path="/clients" component={Client} />
        <PrivateRoute exact path="/client" component={ClientProfile} />
        <PrivateRoute exact path="/contrats" component={Contrats} />
        <PrivateRoute exact path="/produits" component={Produits} />
        <PrivateRoute
          exact
          path="/contrat/informations"
          component={Fiche}
          Button
        />
        <PrivateRoute exact path="/support" component={Support} />
        <PrivateRoute path="/support/:type" component={SupportType} />
        <PrivateRoute exact path="/administration" component={Administration} />
        <PrivateRoute exact path="/propositions" component={Propositions} />
        <PrivateRoute exact path="/synthese" component={Synthese} />
        <PrivateRoute exact path="/reporting" component={Reporting} />
        <PrivateRoute exact path="/profil" component={Profil} />
        <PrivateRoute path="/creation/ticket" component={TicketCreate} />
        <PrivateRoute
          path="/creation/utilisateur/:type"
          component={UserCreate}
        />
        <PrivateRoute path="/streaming" component={Streaming} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
    <ToastContainer />
  </MuiThemeProvider>
);

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Root />
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root')
);
