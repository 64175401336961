'Use strict';
import React, { useEffect, useRef, useState } from 'react';
import 'react-chat-elements/dist/main.css';
import { Input } from 'react-chat-elements';
import MessageBox from '../../utils/MessageBox/MessageBox';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '@material-ui/icons/Home';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import TicketDialog from '../../utils/TicketDialog/TicketDialog';
import ChatDialog from '../../utils/ChatDialog/ChatDialog';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PreviewDialog from '../../utils/PreviewDialog/PreviewDialog';
import LoadingDialog from '../../utils/LoadingDialog/LoadingDialog';
import { io } from 'socket.io-client';
import StatusIcon from '../../utils/StatusIcon/StatusIcon';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';

const Ticket = () => {
  const location = useLocation();
  const history = useHistory();

  const inputRef = useRef(null);

  const accessToken = useSelector((state) => state.accessToken);
  const userId = useSelector((state) => state.userId).acc_id;

  const [sav, setSAV] = useState({});
  const [messages, setMessages] = useState([]);
  const [reply, setReply] = useState(null);
  const [input, setInput] = useState('');
  const [openSettingDialog, setOpenSettingDialog] = useState(false);
  const [activeSettingDialog, setActiveSettingDialog] = useState(0);
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [active, setActive] = useState(false);

  const [role, setRole] = useState(null);

  const [showMess, setShowMess] = useState(null);

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  let socket = useRef(null);

  let SettingLines = [];

  useEffect(() => {
    if (location && location.state && location.state.sav_id)
      axios
        .post(
          process.env.REACT_APP_BACK_API_URL +
            '/ticket/read/' +
            location.state.sav_id,
          {},
          {
            headers: { token: accessToken },
          }
        )
        .catch((err) => {
          console.error(err);
        });
  }, [location, accessToken]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      setShowMess(
        messages.map((el, i) => {
          let replyObj = null;
          if (el.com_reply) {
            let replyBdd = JSON.parse(el.com_reply);
            replyObj = {
              title: replyBdd.title,
              titleColor: replyBdd.user !== userId ? '#01374C' : '#F4970E',
              message: replyBdd.message,
              user: replyBdd.user,
              com_id: replyBdd.com_id,
              date: replyBdd.date,
              type: replyBdd.type,
            };
          }
          let m = new Date(el.com_date);
          var date =
            m.getUTCDate().toString().padStart(2, '0') +
            '/' +
            (m.getUTCMonth() + 1).toString().padStart(2, '0') +
            '/' +
            m.getUTCFullYear() +
            ' à ' +
            m.getUTCHours().toString().padStart(2, '0') +
            'h' +
            m.getUTCMinutes().toString().padStart(2, '0');
          if (el.com_data && el.com_data[0] === '<') {
            return null;
          } else {
            return (
              <div key={i}>
                {el.com_pj ? (
                  <MessageBox
                    role={role}
                    com_id={el.com_id}
                    position={el.com_user === userId ? 'right' : 'left'}
                    handleReply={handleReply}
                    type={'file'}
                    text={getLastPart(el.com_pj, '-')}
                    data={{
                      uri: el.com_pj,
                    }}
                    dateString={date}
                    title={el.acc_firstname + ' ' + el.acc_lastname}
                    titleColor={el.com_user === userId ? '#F4970E' : '#01374C'}
                    reply={replyObj}
                    user={el.com_user}
                  />
                ) : (
                  <MessageBox
                    role={role}
                    com_id={el.com_id}
                    position={el.com_user === userId ? 'right' : 'left'}
                    handleReply={handleReply}
                    type={'text'}
                    text={el.com_data}
                    data={null}
                    dateString={date}
                    title={
                      el && el.acc_firstname && el.acc_lastname
                        ? el.acc_firstname + ' ' + el.acc_lastname
                        : ''
                    }
                    titleColor={el.com_user === userId ? '#F4970E' : '#01374C'}
                    reply={replyObj}
                    user={el.com_user}
                  />
                )}
              </div>
            );
          }
        })
      );
    }
  }, [messages, userId, role]);

  useEffect(() => {
    let mounted = true;
    if (location.state && mounted) {
      socket.current = io.connect(process.env.REACT_APP_BACK_URL);
      socket.current.on('sendMessage', (data) => {
        if (
          data &&
          data.com_user &&
          data.com_user !== userId &&
          data.com_sav === location.state.sav_id &&
          mounted
        ) {
          setMessages((messages) => [...messages, data]);
          if (location && location.state && location.state.sav_id)
            axios
              .post(
                process.env.REACT_APP_BACK_API_URL +
                  '/ticket/read/' +
                  location.state.sav_id,
                {},
                {
                  headers: { token: accessToken },
                }
              )
              .catch((err) => {
                console.error(err);
              });
        }
      });
      return () => {
        socket.current.disconnect();
      };
    }
    return () => {
      mounted = false;
    };
  }, [userId, location, accessToken]);

  useEffect(() => {
    let mounted = true;
    if (accessToken && mounted) {
      axios
        .get(process.env.REACT_APP_BACK_API_URL + '/user/role', {
          headers: { token: accessToken },
        })
        .then((res) => {
          if (
            res.data.role !== undefined &&
            res.data.role !== null &&
            mounted
          ) {
            setRole(res.data.role);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  const handleFile = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    let mounted = true;
    if (selectedFile !== null && mounted) {
      setOpenChatDialog(false);
      setOpenPreviewDialog(true);
    }
    return () => {
      mounted = false;
    };
  }, [selectedFile]);

  const handleSendFile = () => {
    if (selectedFile) {
      setOpenPreviewDialog(false);
      setOpenLoadingDialog(true);
      let data = new FormData();
      data.append('file', selectedFile);
      axios
        .post(
          process.env.REACT_APP_BACK_API_URL +
            '/ticket/messages/upload/' +
            location.state.sav_id,
          data,
          {
            headers: { token: accessToken },
          }
        )
        .then((res) => {
          axios
            .get(
              process.env.REACT_APP_BACK_API_URL +
                '/ticket/message/' +
                res.data,
              {
                headers: { token: accessToken },
              }
            )
            .then((res) => {
              if (res.data) {
                setMessages([...messages, ...res.data]);
                socket.current.emit('sendMessage', res.data[0]);
              }
            })
            .then(() => {
              setOpenLoadingDialog(false);
              axios
                .post(
                  process.env.REACT_APP_BACK_API_URL +
                    '/ticket/read/' +
                    location.state.sav_id,
                  {},
                  {
                    headers: { token: accessToken },
                  }
                )
                .catch((err) => {
                  console.error(err);
                });
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  if (sav) {
    SettingLines = [
      {
        type: 'arianne',
        arianne: sav.sav_arianne ? JSON.parse(sav.sav_arianne) : null,
      },
      {
        type: 'text',
        primary: sav.clt_raisonsociale,
        onClick: () => {
          history.push({
            pathname: '/contrats',
            state: {
              ...location.state,
              clt_id: sav.sav_clt,
            },
          });
        },
      },
      {
        type: 'text',
        primary: sav.ctr_ref,
        onClick: () => {
          history.push({
            pathname: '/contrat/informations',
            state: {
              ...location.state,
              clt_id: sav.sav_clt,
              ctr_id: sav.sav_ctr,
            },
          });
        },
      },
      {
        type: 'date',
        debut: sav.sav_debut,
        fin: sav.sav_fin,
        sav_id: sav.sav_id,
        role: role,
      },
      {
        type: 'affectation',
        affectation:
          sav && sav.sav_affectation ? sav.sav_affectation.split(',') : [],
        clt_id: sav.sav_clt,
        ctr_id: sav.sav_ctr,
        sav_id: sav.sav_id,
        role: role,
      },
      {
        type: 'description',
        description: sav.sav_desc,
        sav_id: sav.sav_id,
        role: role,
      },
      {
        type: 'creation',
        by: 'Créé par ' + sav.acc_lastname + ' ' + sav.acc_firstname,
      },
    ];
  }

  const ChatLines = [
    {
      primary: 'Documents',
      secondary: 'Chargez un document depuis votre appareil.',
      icon: (
        <form method="post" enctype="multipart/form-data">
          <input type="file" hidden onChange={handleFile} />
          <FileCopyIcon
            style={{
              color: '#0a374c',
              width: '35px',
              height: '35px',
            }}
          />
        </form>
      ),
    },
  ];

  if (!location.state) {
    history.push({
      pathname: '/',
    });
  }

  useEffect(() => {
    let mounted = true;
    if (location.state && mounted) {
      async function sav() {
        try {
          await axios
            .get(
              process.env.REACT_APP_BACK_API_URL +
                '/ticket/one/' +
                location.state.sav_id,
              {
                headers: { token: accessToken },
              }
            )
            .then((res) => {
              if (Array.isArray(res.data) && mounted) {
                let m = new Date(res.data[0].sav_creation);
                var date =
                  m.getUTCDate().toString().padStart(2, '0') +
                  '/' +
                  (m.getUTCMonth() + 1).toString().padStart(2, '0') +
                  '/' +
                  m.getUTCFullYear();
                setActive((active) =>
                  res.data[0].sav_actif === 1 ? true : false
                );
                setSAV((sav) => ({
                  ...res.data[0],
                  dateCreateDate: date,
                }));
              } else {
                history.push({
                  pathname: res.data,
                  state: location.state,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      sav();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, location, history, openSettingDialog]);

  useEffect(() => {
    let mounted = true;
    if (location.state && mounted) {
      async function messages() {
        try {
          await axios
            .get(
              process.env.REACT_APP_BACK_API_URL +
                '/ticket/messages/' +
                location.state.sav_id,
              {
                headers: { token: accessToken },
              }
            )
            .then((res) => {
              if (Array.isArray(res.data) && mounted) {
                setMessages(res.data);
              } else {
                history.push({
                  pathname: res.data,
                  state: location.state,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      messages();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, location, history, activeSettingDialog]);

  const removeText = () => {
    setOpenLoadingDialog(false);
    setInput((input) => '');
    setReply((reply) => null);
    if (inputRef.current) {
      inputRef.current.clear();
      inputRef.current.input.value = '';
      inputRef.current.input.focus();
    }
  };

  const handleSubmit = () => {
    if (input.length !== 0 && input.length < 255) {
      setOpenLoadingDialog(true);
      axios
        .post(
          process.env.REACT_APP_BACK_API_URL + '/ticket/messages/',
          {
            com_sav: location.state.sav_id,
            com_data: input,
            com_reply: reply ? JSON.stringify(reply) : null,
          },
          {
            headers: { token: accessToken },
          }
        )
        .then((res) => {
          if (res.data) {
            axios
              .get(
                process.env.REACT_APP_BACK_API_URL +
                  '/ticket/message/' +
                  res.data,
                {
                  headers: { token: accessToken },
                }
              )
              .then((res) => {
                if (res.data) {
                  removeText();
                  setMessages([...messages, ...res.data]);
                  socket.current.emit('sendMessage', res.data[0]);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .then(() => {
          axios
            .post(
              process.env.REACT_APP_BACK_API_URL +
                '/ticket/read/' +
                location.state.sav_id,
              {},
              {
                headers: { token: accessToken },
              }
            )
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleReply = (el) => {
    inputRef.current.input.focus();
    setReply((reply) => ({
      title: el.acc_firstname + ' ' + el.acc_lastname,
      user: el.com_user,
      message: el.com_data,
      com_id: el.com_id,
      date: el.date,
      type: el.type,
    }));
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleCloseReply = () => {
    setReply(null);
  };

  const getLastPart = (str, splitChar) => {
    let isChar = str.indexOf(splitChar);
    if (isChar !== -1) {
      let newStr = str.split(splitChar);
      delete newStr[0];
      let finalStr = newStr.join('');
      return finalStr;
    } else {
      return str;
    }
  };

  const handleActive = () => {
    setActive(!active);
    axios
      .post(
        process.env.REACT_APP_BACK_API_URL + '/ticket/active',
        {
          active: !active ? 1 : 0,
          sav_id: sav.sav_id,
        },
        {
          headers: { token: accessToken },
        }
      )
      .catch((err) => {
        console.error(err);
      });
  };

  let currentReplyColor =
    reply && reply.user === userId ? '#F4970E' : '#01374C';

  return (
    <div style={{ height: '100%' }}>
      <LoadingDialog
        openDialog={openLoadingDialog}
        setOpenDialog={setOpenLoadingDialog}
      />
      {selectedFile !== null ? (
        <PreviewDialog
          openDialog={openPreviewDialog}
          setOpenDialog={setOpenPreviewDialog}
          selectedFile={selectedFile}
          sendFile={handleSendFile}
        />
      ) : null}
      {openSettingDialog && (
        <TicketDialog
          openDialog={openSettingDialog}
          setOpenDialog={setOpenSettingDialog}
          Lines={SettingLines}
          title={
            sav.dateCreateDate ? (
              <>
                <StatusIcon
                  button={role !== 1 && role !== 0 ? null : handleActive}
                  big
                  active={active ? 1 : 0}
                ></StatusIcon>
                <span
                  style={{
                    position: 'relative',
                    top: '1px',
                    left: '20px',
                  }}
                >
                  Créé le {sav.dateCreateDate}
                </span>
              </>
            ) : null
          }
        />
      )}
      <ChatDialog
        openDialog={openChatDialog}
        setOpenDialog={setOpenChatDialog}
        Lines={ChatLines}
      />
      <Slide appear={true} direction={'down'} in={true}>
        <div
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 9,
            padding: 0,
            margin: 0,
            width: '100%',
            height: '60px',
            backgroundColor: '#0a374c',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Link
            to={{
              pathname: '/',
              state: location.state,
            }}
          >
            <IconButton
              aria-label="full screen"
              style={{
                marginTop: '7px',
                marginLeft: '7px',
              }}
            >
              <HomeIcon style={{ color: 'white' }} fontSize="inherit" />
            </IconButton>
          </Link>
          <span
            style={{
              marginTop: '6px',
              color: 'white',
              textAlign: 'center',
            }}
          >
            {sav.clt_raisonsociale}
            <br />
            {sav.ctr_ref}
          </span>
          <span>
            <IconButton
              onClick={() => {
                setOpenSettingDialog(!openSettingDialog);
                setActiveSettingDialog((active) => active + 1);
              }}
              aria-label="full screen"
              style={{
                marginTop: '7px',
                marginRight: '7px',
              }}
            >
              <ReceiptIcon style={{ color: 'white' }} fontSize="inherit" />
            </IconButton>
          </span>
        </div>
      </Slide>
      <div
        id="messages"
        style={{
          padding: 0,
          margin: 0,
          width: '100%',
          maxWidth: '100vw',
          height: '100%',
          minHeight: 'calc(100vh - 118px)',
          maxHeight: '100%',
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showMess}
        <AlwaysScrollToBottom />
      </div>
      <Slide appear={true} direction={'up'} in={true}>
        <div
          style={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            padding: 0,
            margin: 0,
          }}
        >
          {reply ? (
            <Slide appear={true} direction={'up'} in={true}>
              <Container
                maxWidth="sm"
                className="replyTo"
                style={{
                  background:
                    'linear-gradient(0deg, rgba(11,55,76,0.06) 30%, rgba(251,252,253,1) 100%)',
                  backdropFilter: 'blur(3px)',
                  padding: 0,
                  borderRadius: '13px',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    borderTop: 'solid 4px ' + currentReplyColor,
                    borderRadius: '13px',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    width: '100%',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <span
                    style={{
                      position: 'relative',
                      left: '8px',
                    }}
                  >
                    En réponse à:
                  </span>
                  <IconButton
                    aria-label="full screen"
                    style={{
                      width: '15px',
                      height: '15px',
                      float: 'right',
                    }}
                    onClick={handleCloseReply}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </div>
                <MessageBox
                  isReply={true}
                  type={reply.type}
                  text={reply.message}
                  dateString={reply.date}
                  title={reply.title}
                  titleColor={reply.user === userId ? '#F4970E' : '#01374C'}
                  com_id={reply.com_id}
                  data={reply.data}
                  user={reply.user}
                />
              </Container>
            </Slide>
          ) : null}
          <Input
            defaultValue=""
            className="bg-primary"
            ref={inputRef}
            autofocus={true}
            placeholder="Écrivez ici..."
            multiline={false}
            onKeyPress={handleEnter}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            leftButtons={
              <IconButton
                aria-label="delete"
                style={{ color: 'white' }}
                onClick={() => {
                  setOpenChatDialog(!openChatDialog);
                }}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            }
            rightButtons={
              <IconButton
                aria-label="delete"
                style={{ color: 'white' }}
                onClick={handleSubmit}
              >
                <SendIcon fontSize="inherit" />
              </IconButton>
            }
          />
        </div>
      </Slide>
    </div>
  );
};

export default Ticket;
