import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DatePicker from './DatePicker';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import MultiSelectResp from './MultiSelectResp';
import MultiSelectCtr from './MultiSelectCtr';
import { toast } from 'react-toastify';

toast.configure({
  autoClose: 8000,
  draggable: true,
  position: 'bottom-center',
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'light',
});

const FilterReporting = ({
  selectedResponsables,
  setSelectedResponsables,
  selectedContrats,
  setSelectedContrats,
  dates,
  setDates,
  displayEvent,
  setDisplayEvent,
}) => {
  console.log('FilterReporting');

  // Calculer les dates par défaut pour le mois précédent
  const currentDate = moment();
  const defaultEndDate = currentDate
    .clone()
    .subtract(1, 'month')
    .endOf('month'); // Dernier jour du mois dernier

  const [responsables, setResponsables] = useState([]);
  const [contratOptions, setContratOptions] = useState([]);

  const accessToken = useSelector((state) => state.accessToken);
  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function getInitialFilter() {
        try {
          axios
            .get(
              process.env.REACT_APP_BACK_API_URL + '/client/reporting/filter',
              {
                headers: { token: accessToken },
              }
            )
            .then((response) => {
              if (response.data && mounted) {
                setResponsables(response.data);
              } else {
                toast.error('Erreur lors de la récupération des données');
                history.push('/');
              }
            });
        } catch (error) {
          throw error;
        }
      }
      getInitialFilter();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, history, setResponsables]);

  const responsableOptions = responsables.map((r) => ({
    value: r.responsable.acc_id,
    label: r.responsable.name,
  }));

  const handleDateChange = (key) => (date) => {
    setDates((prevDates) => ({
      ...prevDates,
      [key]: date,
    }));
  };

  return (
    <Paper>
      <Grid
        container
        style={{
          paddingBottom: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          item
          xs={12}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 800,
          }}
        >
          <Grid item xs={12} sm={4}>
            <DatePicker
              label="Date de début"
              value={dates.not_date_debut}
              onChange={handleDateChange('not_date_debut')}
              maxDate={defaultEndDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker
              label="Date de fin"
              value={dates.not_date_fin}
              onChange={handleDateChange('not_date_fin')}
              minDate={dates.not_date_debut}
              maxDate={currentDate}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <FormControlLabel
              value={displayEvent}
              control={
                <Switch
                  checked={displayEvent}
                  onChange={(e) => setDisplayEvent(e.target.checked)}
                  color="primary"
                  name="displayEvent"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={`Autres événements`}
              labelPlacement="top"
            />
          </Grid>
          <Grid xs={12} style={{ marginTop: '8px' }}>
            <Button
              onClick={() => {
                setSelectedResponsables(
                  responsables.map((r) => r.responsable.acc_id)
                );
              }}
              style={{
                fontSize: '12px',
                position: 'relative',
                left: '5px',
              }}
            >
              Séléctionner tous les responsables
            </Button>
            <MultiSelectResp
              data={selectedResponsables}
              setData={setSelectedResponsables}
              options={responsableOptions}
              setSelectedContrats={setSelectedContrats}
              setContratOptions={setContratOptions}
              responsables={responsables}
            />
          </Grid>
          <Grid xs={12} style={{ marginTop: '8px' }}>
            <Button
              onClick={() => {
                setSelectedContrats(contratOptions.map((c) => c.value));
              }}
              disabled={selectedResponsables.length === 0}
              style={{
                fontSize: '12px',
                position: 'relative',
                left: '5px',
              }}
            >
              Séléctionner tous les contrats
            </Button>
            <MultiSelectCtr
              data={selectedContrats}
              setData={setSelectedContrats}
              disabled={selectedResponsables.length === 0}
              options={contratOptions}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterReporting;
