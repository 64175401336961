'Use strict';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';
import VideocamIcon from '@material-ui/icons/Videocam';
import { useHistory, useLocation } from 'react-router';

function DataList({
    dense,
    secondary,
    primary,
    active,
    color,
    lastOn,
    show,
    ip
}) {
    const history = useHistory();
    const location = useLocation();

    return (
        <List style={{ backgroundColor: color }} dense={dense}>
            <Grow in={true} timeout={300}>
                <ListItem>
                    {active ? (
                        <CheckCircleOutlineRoundedIcon
                            style={{ color: '#27A844', marginRight: '15px' }}
                        />
                    ) : (
                        <HighlightOffRoundedIcon
                            style={{ color: '#DC3444', marginRight: '15px' }}
                        />
                    )}
                    <ListItemText
                        primary={
                            primary ? (
                                <span
                                    style={{
                                        color: '#0A374C',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {primary}
                                </span>
                            ) : null
                        }
                        secondary={secondary ? secondary : null}
                    />
                    <ListItemSecondaryAction>
                        {show ? (
                            <Tooltip
                                title="Streaming"
                                aria-label="streaming tooltip"
                            >
                                <IconButton
                                    edge="end"
                                    aria-label="streaming icon"
                                    color="primary"
                                    onClick={() =>
                                        history.push({
                                            pathname: '/streaming',
                                            search: `?ip=${ip}&subtype=1`,
                                            state: location.state
                                        })
                                    }
                                >
                                    <VideocamIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                        <Tooltip title={lastOn} aria-label="ip adress">
                            <IconButton
                                edge="end"
                                aria-label="ip adress"
                                color="secondary"
                            >
                                <SignalCellularAltIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            </Grow>
        </List>
    );
}

export default DataList;
