'Use strict';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/fr';
import axios from 'axios';
import { useSelector } from 'react-redux';

moment.locale('fr'); // it is required to select default locale manually

function Picker({ startDefault, endDefault, sav_id, role }) {
  const [du, setDu] = useState(startDefault);
  const [au, setAu] = useState(endDefault);
  const [locale] = useState('fr');
  const accessToken = useSelector((state) => state.accessToken);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setDu(startDefault);
      setAu(endDefault);
    }
    return () => {
      mounted = false;
    };
  }, [startDefault, endDefault]);

  const handleDu = async (du) => {
    await axios
      .post(
        process.env.REACT_APP_BACK_API_URL + '/ticket/du',
        {
          du: du,
          sav_id: sav_id,
        },
        {
          headers: { token: accessToken },
        }
      )
      .catch((err) => {
        console.error(err);
      });
    setDu(du);
  };

  const handleAu = async (au) => {
    await axios
      .post(
        process.env.REACT_APP_BACK_API_URL + '/ticket/au',
        {
          au: au,
          sav_id: sav_id,
        },
        {
          headers: { token: accessToken },
        }
      )
      .catch((err) => {
        console.error(err);
      });
    setAu(au);
  };

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <DatePicker
        style={{ marginTop: '15px', marginRight: '2.5px' }}
        fullWidth
        disabled={role !== 1 ? true : false}
        autoOk={true}
        animateYearScrolling={true}
        showTodayButton={true}
        todayLabel="Aujourd'hui"
        cancelLabel="ANNULER"
        value={du}
        inputVariant="outlined"
        label="Du"
        onChange={(e) => {
          if (role !== 1) {
            return null;
          } else {
            handleDu(e.format('YYYY-MM-DD'));
          }
        }}
        readOnly={role !== 1 ? true : false}
        format="D MMM YYYY"
      />
      <DatePicker
        style={{ marginTop: '15px', marginLeft: '2.5px' }}
        fullWidth
        disabled={role !== 1 ? true : false}
        autoOk={true}
        animateYearScrolling={true}
        showTodayButton={true}
        todayLabel="Aujourd'hui"
        cancelLabel="ANNULER"
        minDate={du ? du : Date.now()}
        value={au}
        inputVariant="outlined"
        label="Au"
        onChange={(e) => {
          if (role !== 1) {
            return null;
          } else {
            handleAu(e.format('YYYY-MM-DD'));
          }
        }}
        readOnly={role !== 1 ? true : false}
        format="D MMM YYYY"
      />
    </MuiPickersUtilsProvider>
  );
}

export default Picker;
