"Use strict";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ImageNotFound from "../../../static/svg/undraw_page_not_found_su7k.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        textAlign: "center",
        marginTop: "20px",
        marginBottom: "20px",
    },
    pathname: {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
    },
}));

function NotFound({ location }) {
    const classes = useStyles();

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="center"
            style={{ marginTop: "100px" }}
        >
            <Grid item>
                <img
                    src={ImageNotFound}
                    style={{ width: "75vw", maxWidth: "500px" }}
                    alt="svg for 404 not found"
                />
            </Grid>
            <Grid item>
                <div className={classes.root}>
                    <span className={classes.pathname}>
                        {location.pathname}
                    </span>
                    <br />
                    La ressource demandée n'est pas disponible
                </div>
            </Grid>
            <Grid item>
                <Link
                    to={{
                        pathname: "/",
                        state: null,
                    }}
                    style={{ textDecoration: "none" }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<ArrowBackIcon />}
                    >
                        Retour
                    </Button>
                </Link>
            </Grid>
        </Grid>
    );
}

export default NotFound;
