import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ProductsDialog from './ProductsDialog';
import ImageInputFile from './ImageInputFile';
import AddIcon from '@material-ui/icons/Add';
import CanvasPlanDialog from './CanvasPlanDialog';
import ProductAccordion from './ProductAccordion';
import axios from 'axios';

const PropositionDialog = ({
  fullScreen,
  openProposition,
  mode,
  contrat,
  setOpenProposition,
  accessToken,
  setClient,
  client,
  handleClients,
  contratsProposition,
  setLoading,
}) => {
  const canvasPlanRef = useRef(null);
  const canvasProductsRef = useRef(null);
  const [data, setData] = useState(
    contrat && mode !== 'insert'
      ? contrat
      : {
          clt_raisonsociale: client ? client?.clt_raisonsociale : '',
          clt_lastname: client ? client?.clt_lastname : '',
          clt_firstname: client ? client?.clt_firstname : '',
          ctr_ref: '',
          ctr_plan: '',
          products: [],
        }
  );
  const [openProducts, setOpenProducts] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const [selectedProductPosition, setSelectedProductPosition] = useState(null);

  const handleSave = async () => {
    if (
      data?.ctr_ref?.length < 3 ||
      data?.clt_raisonsociale?.length < 3 ||
      data?.clt_lastname?.length < 3 ||
      data?.clt_firstname?.length < 3
    ) {
      alert('Veuillez remplir les champs obligatoires');
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();

      const isProductSet = data?.products?.find((p) => p?.ctl_x && p?.ctl_y);
      if (data?.ctr_plan && isProductSet) {
        const blob = await canvasToBlob();
        const canvasFile = new File([blob], 'canvas.png', {
          type: 'image/png',
        });
        if (canvasFile) formData.append('files', canvasFile, 'canvas.png');
      }

      if (mode === 'insert') {
        if (client?.clt_id) formData.append('clt_id', client?.clt_id);
        else {
          formData.append('clt_lastname', data?.clt_lastname);
          formData.append('clt_firstname', data?.clt_firstname);
          formData.append('clt_raisonsociale', data?.clt_raisonsociale);
        }
      } else {
        formData.append('ctr_id', data?.ctr_id);
      }
      formData.append('ctr_ref', data?.ctr_ref);
      if (!(typeof data?.ctr_plan === 'string') && data?.ctr_plan) {
        const ctrPlanExtension = data?.ctr_plan?.name.split('.').pop();
        formData.append(
          'files',
          data?.ctr_plan,
          'ctr_plan.' + ctrPlanExtension
        );
      }
      if (data?.products?.length > 0) {
        formData.append(
          'products',
          JSON.stringify(
            data?.products
              ?.sort((a, b) => b.ctl_position - a.ctl_position)
              ?.map((p) => ({
                art_id: p?.art_id,
                ctl_id: p?.ctl_id,
                ctl_position: p?.ctl_position,
                ctl_x: p?.ctl_x ? parseFloat(p?.ctl_x?.toFixed(3)) : 0,
                ctl_y: p?.ctl_y ? parseFloat(p?.ctl_y?.toFixed(3)) : 0,
                ctl_ref: p?.ctl_ref,
                ctl_comment: p?.ctl_comment,
                ctl_vision:
                  !(typeof p?.ctl_vision === 'string') && p?.ctl_vision
                    ? 'vision' + p?.ctl_position
                    : undefined,
                ctl_emplacement:
                  !(typeof p?.ctl_emplacement === 'string') &&
                  p?.ctl_emplacement
                    ? 'emplacement' + p?.ctl_position
                    : undefined,
              }))
          )
        );
        if (data?.products)
          data.products
            .sort((a, b) => b.ctl_position - a.ctl_position)
            .forEach((p) => {
              if (!(typeof p?.ctl_vision === 'string') && p?.ctl_vision) {
                const ctlVisionExtension = p?.ctl_vision?.name.split('.').pop();
                formData.append(
                  'files',
                  p?.ctl_vision,
                  `vision${p?.ctl_position}.${ctlVisionExtension}`
                );
              }
              if (
                !(typeof p?.ctl_emplacement === 'string') &&
                p?.ctl_emplacement
              ) {
                const ctlEmplacementExtension = p?.ctl_emplacement?.name
                  .split('.')
                  .pop();
                formData.append(
                  'files',
                  p?.ctl_emplacement,
                  `emplacement${p?.ctl_position}.${ctlEmplacementExtension}`
                );
              }
            });
      }
      axios(process.env.REACT_APP_BACK_API_URL + '/contrat/proposition', {
        headers: { token: accessToken, 'Content-Type': 'multipart/form-data' },
        method: mode === 'insert' ? 'post' : 'put',
        data: formData,
      })
        .then((res) => {
          if (res?.data) {
            // console.log('result ' + mode, res?.data);
            if (mode === 'insert') {
              handleClients().then(async () => {
                setClient(res.data);
              });
            } else contratsProposition();
            setOpenProposition((p) => ({
              ...p,
              open: false,
              mode: null,
            }));
          } else {
            console.error(res);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const canvasToBlob = () => {
    return new Promise((resolve, reject) => {
      const canvas = canvasPlanRef.current;
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  };

  useEffect(() => {
    if (contrat && mode !== 'insert') setData(contrat);
    else
      setData({
        clt_raisonsociale: client ? client?.clt_raisonsociale : '',
        clt_lastname: client ? client?.clt_lastname : '',
        clt_firstname: client ? client?.clt_firstname : '',
        ctr_ref: '',
        ctr_plan: '',
        products: [],
      });
  }, [contrat, mode, client]);

  return (
    <>
      <ProductsDialog
        fullScreen={fullScreen}
        openProducts={openProducts}
        setOpenProducts={setOpenProducts}
        setData={setData}
        accessToken={accessToken}
      />
      <CanvasPlanDialog
        openCanvas={openCanvas}
        setOpenCanvas={setOpenCanvas}
        data={data}
        setData={setData}
        selectedProductPosition={selectedProductPosition}
        canvasRef={canvasProductsRef}
      />
      <Dialog
        style={{ zIndex: 99999999 }}
        fullScreen={fullScreen}
        onClose={() => {
          setOpenProposition((p) => ({
            ...p,
            open: false,
            mode: null,
          }));
        }}
        open={openProposition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {mode === 'insert'
            ? 'Nouvelle proposition'
            : 'Modifier la proposition'}
        </DialogTitle>
        <Divider />
        <DialogContent style={{ paddingBottom: '30px', paddingTop: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Informations
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nom"
                value={data?.clt_lastname}
                onChange={(e) =>
                  setData((data) => ({
                    ...data,
                    clt_lastname: e.target.value,
                  }))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus={mode === 'insert' && !client}
                error={data?.clt_lastname?.length < 3 && data?.clt_lastname}
                variant="outlined"
                disabled={client || (!client && mode === 'update')}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Prénom"
                value={data?.clt_firstname}
                onChange={(e) =>
                  setData((data) => ({
                    ...data,
                    clt_firstname: e.target.value,
                  }))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                error={data?.clt_firstname?.length < 3 && data?.clt_firstname}
                variant="outlined"
                disabled={client || (!client && mode === 'update')}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Raison sociale"
                value={data?.clt_raisonsociale}
                onChange={(e) =>
                  setData((data) => ({
                    ...data,
                    clt_raisonsociale: e.target.value,
                  }))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  data?.clt_raisonsociale?.length < 3 && data?.clt_raisonsociale
                }
                variant="outlined"
                disabled={client || (!client && mode === 'update')}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nom de la proposition"
                value={data?.ctr_ref}
                onChange={(e) =>
                  setData((data) => ({
                    ...data,
                    ctr_ref: e.target.value,
                  }))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                error={data?.ctr_ref?.length < 3 && data?.ctr_ref}
                variant="outlined"
                autoFocus={mode === 'insert' && client}
                size="small"
              />
            </Grid>
          </Grid>
          <Typography variant="h6" style={{ marginTop: '10px' }}>
            Plan
          </Typography>
          <ImageInputFile
            id={`contained-button-file-plan`}
            label="Plan"
            src={data?.ctr_plan}
            setFile={(file) => {
              setData((data) => ({
                ...data,
                ctr_plan: file,
              }));
            }}
            canvasRef={canvasPlanRef}
            canvasProducts={data?.products}
          />
          <Typography variant="h6" style={{ marginTop: '20px' }} gutterBottom>
            Produits
          </Typography>
          {data?.products
            ?.sort((a, b) => a?.ctl_position - b?.ctl_position)
            ?.map((product) => (
              <ProductAccordion
                key={`product-accordion-${product?.ctl_position}`}
                product={product}
                data={data}
                setData={setData}
                setOpenCanvas={setOpenCanvas}
                setSelectedProductPosition={setSelectedProductPosition}
              />
            ))}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            onClick={() => setOpenProducts(true)}
          >
            Ajouter un produit
          </Button>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              setOpenProposition((p) => ({
                ...p,
                open: false,
                mode: null,
              }));
            }}
            style={{ color: 'red' }}
          >
            Fermer
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={
              data?.ctr_ref?.length < 3 ||
              data?.clt_raisonsociale?.length < 3 ||
              data?.clt_lastname?.length < 3 ||
              data?.clt_firstname?.length < 3
            }
          >
            {mode === 'insert' ? 'Enregistrer' : 'Modifier'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PropositionDialog;
