import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ModifyPassDialog({
    open,
    title,
    text,
    handleClick,
    handleModify,
    pass,
    setPass,
    rpass,
    setRpass,
}) {
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClick}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{text}</DialogContentText>
                    <TextField
                        error={pass.error}
                        value={pass.data}
                        onChange={(e) => {
                            setPass((pass) => {
                                return {
                                    ...pass,
                                    data: e.target.value,
                                };
                            });
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                        size="medium"
                        label="Mot de passe*"
                        variant="outlined"
                        type="password"
                        autoComplete="new-password"
                    />
                    <TextField
                        error={rpass.error}
                        value={rpass.data}
                        onChange={(e) => {
                            setRpass((rpass) => {
                                return {
                                    ...rpass,
                                    data: e.target.value,
                                };
                            });
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                        size="medium"
                        label="Répétez le mot de passe*"
                        variant="outlined"
                        type="password"
                        autoComplete="new-password"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClick} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleModify} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
