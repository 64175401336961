'Use strict';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Picker from '../Picker/Picker';
import BreadcrumbSAV from '../../utils/Breadcrumb/BreadcrumbSAV';
import MultiSelect from '../MultiSelect/MultiSelect';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(245,245,245, 1)'
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#0B374C'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function TicketDialog({
    openDialog,
    setOpenDialog,
    Lines,
    title
}) {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const [affectation, setAffectation] = useState([]);
    const [techniciens, setTechniciens] = useState([]);
    const accessToken = useSelector((state) => state.accessToken);

    useEffect(() => {
        let affect = Lines.find((el) => el.type === 'affectation').affectation;
        async function getNameTech() {
            try {
                await axios
                    .get(
                        process.env.REACT_APP_BACK_API_URL +
                            '/ticket/techniciens/name',
                        {
                            headers: { token: accessToken }
                        }
                    )
                    .then((res) => {
                        if (Array.isArray(res.data)) {
                            setTechniciens(res.data);
                        } else {
                            history.push({
                                pathname: '/',
                                state: location.state
                            });
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } catch (e) {
                throw e;
            }
        }
        getNameTech();

        if (affect && affect.length) {
            async function getNameAffect() {
                try {
                    await axios
                        .post(
                            process.env.REACT_APP_BACK_API_URL +
                                '/ticket/affectation/name',
                            {
                                affectation: affect
                            },
                            {
                                headers: { token: accessToken }
                            }
                        )
                        .then((res) => {
                            if (Array.isArray(res.data)) {
                                setAffectation(res.data);
                            } else {
                                history.push({
                                    pathname: '/',
                                    state: location.state
                                });
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } catch (e) {
                    throw e;
                }
            }
            getNameAffect();
        } else {
            setAffectation([]);
        }
    }, [Lines, history, location.state, accessToken]);

    const handlePost = async (value, sav_id) => {
        await axios
            .post(
                process.env.REACT_APP_BACK_API_URL + '/ticket/description',
                {
                    desc: value,
                    sav_id: sav_id
                },
                {
                    headers: { token: accessToken }
                }
            )
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <Dialog
            fullScreen
            open={openDialog}
            onClose={() => {
                setOpenDialog(!openDialog);
            }}
            TransitionComponent={Transition}
        >
            <div style={{ height: '100%' }} className={classes.root}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setOpenDialog(!openDialog);
                            }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Container>
                    <Card
                        elevation={3}
                        style={{
                            paddingBottom: '30px'
                        }}
                    >
                        <CardContent
                            style={{
                                padding: 0
                            }}
                        >
                            <List>
                                {Lines &&
                                    Lines.map((el, i) => {
                                        if (el.type === 'text') {
                                            return (
                                                <span key={i}>
                                                    <ListItem
                                                        button
                                                        onClick={el.onClick}
                                                    >
                                                        <ListItemText
                                                            primary={el.primary}
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </span>
                                            );
                                        } else if (el.type === 'date') {
                                            return (
                                                <span key={i}>
                                                    <ListItem>
                                                        <Picker
                                                            start={el.debut}
                                                            end={el.fin}
                                                            sav_id={el.sav_id}
                                                            role={el.role}
                                                        />
                                                    </ListItem>
                                                </span>
                                            );
                                        } else if (el.type === 'arianne') {
                                            return (
                                                <span key={i}>
                                                    <ListItem>
                                                        <BreadcrumbSAV
                                                            array={el.arianne}
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </span>
                                            );
                                        } else if (el.type === 'affectation') {
                                            return (
                                                <span key={i}>
                                                    <div
                                                        style={{
                                                            width: '97px',
                                                            height: '20px'
                                                        }}
                                                    ></div>
                                                    <ListItem>
                                                        <MultiSelect
                                                            role={el.role}
                                                            affectation={
                                                                affectation
                                                            }
                                                            techniciens={
                                                                techniciens
                                                            }
                                                            clt_id={el.clt_id}
                                                            ctr_id={el.ctr_id}
                                                            sav_id={el.sav_id}
                                                        />
                                                    </ListItem>
                                                </span>
                                            );
                                        } else if (el.type === 'description') {
                                            return (
                                                <span key={i}>
                                                    <ListItem>
                                                        <TextField
                                                            multiline
                                                            disabled={
                                                                el.role !== 1
                                                                    ? true
                                                                    : false
                                                            }
                                                            onBlur={
                                                                el.role !== 1
                                                                    ? null
                                                                    : (e) => {
                                                                          handlePost(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              el.sav_id
                                                                          );
                                                                      }
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            id="outlined-helperText"
                                                            label="Description"
                                                            defaultValue={
                                                                el.description
                                                            }
                                                        />
                                                    </ListItem>
                                                </span>
                                            );
                                        } else if (el.type === 'creation') {
                                            return (
                                                <span
                                                    style={{
                                                        position: 'fixed',
                                                        bottom: 0
                                                    }}
                                                    key={i}
                                                >
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={el.by}
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </span>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                            </List>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        </Dialog>
    );
}
