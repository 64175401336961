import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/sass/styles.scss';
import { Backdrop, Box, Fade, Modal, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CalendarToolbar from './CalendarToolbar';

moment().locale('fr'); // it is required to select default locale manually

const localizer = momentLocalizer(moment);
const DEFAULT_VIEW = 'month';

const Schedule = (props) => {
  const { currentView, onNavigate, onView, events, ctr_ref } = props;

  const theme = useTheme();
  const today = new Date();
  const [selected, setSelected] = useState(undefined);

  // console.log(ctr_ref, selected);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={selected ? true : false}
        onClose={() => setSelected(undefined)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={selected ? true : false}>
          <Box
            style={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: '10px',
              boxShadow: theme.shadows[5],
            }}
          >
            <div
              style={{
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                height: '50px',
                padding: '10px',
                width: '100%',
                backgroundColor: selected ? selected.style.backgroundColor : '',
                color: selected
                  ? theme.palette.getContrastText(
                      selected.style.backgroundColor
                    )
                  : '',
              }}
            >
              <h2 style={{ padding: 0, margin: 0 }}>{ctr_ref}</h2>
            </div>
            {selected ? (
              <Box p={2} pt={0}>
                <h2>
                  {selected?.title ? selected?.title : <Skeleton width={150} />}
                </h2>
                <ul>
                  {selected?.place !== 'system' && (
                    <>
                      <li>
                        à <b>{selected?.place}</b>
                      </li>
                      <br />
                    </>
                  )}
                  <li>
                    le{' '}
                    <b>
                      {moment(selected?.start).format(
                        'dddd Do MMMM YYYY à HH:mm'
                      )}
                    </b>
                  </li>
                  <br />
                  <li>
                    par <b>{selected?.who}</b>
                  </li>
                </ul>
              </Box>
            ) : (
              <Skeleton variant="rect" height={200} />
            )}
          </Box>
        </Fade>
      </Modal>
      <Calendar
        view={currentView}
        localizer={localizer}
        defaultDate={new Date()}
        events={events}
        onNavigate={onNavigate}
        onView={onView}
        startAccessor="start"
        endAccessor="end"
        defaultView={DEFAULT_VIEW}
        style={{ height: 'calc(100vh - 180px)', minHeight: '500px' }}
        messages={{
          next: 'Suivant',
          previous: 'Précédent',
          today: "Aujourd'hui",
          month: 'Mois',
          week: 'Semaine',
          day: 'Jour',
          event: 'Événement',
          time: 'Heure',
          noEventsInRange: 'Aucun événement',
          showMore: (count) => `+${count} événements supplémentaires`,
        }}
        scrollToTime={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
        }
        views={['month', 'week', 'day', 'agenda']}
        onSelectEvent={(event) => setSelected(event)}
        eventPropGetter={(event) => {
          return {
            className: '',
            style: event.style,
          };
        }}
        components={{
          toolbar: (toolbar) => <CalendarToolbar toolbar={toolbar} />,
        }}
      />
    </>
  );
};

export default Schedule;
