'Use strict';
import React from 'react';
import DataList from '../../../utils/List/DataList';
import Divider from '@material-ui/core/Divider';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NoRows from '../../../utils/NoRows/NoRows';

export default function AdresseIp({ ctrId, accessToken }) {
  const [ip, setIp] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      axios
        .get(process.env.REACT_APP_BACK_API_URL + '/contrat/ip/' + ctrId, {
          headers: { token: accessToken },
        })
        .then((res) => {
          if (res.data && mounted) setIp(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      mounted = false;
    };
  }, [ctrId, accessToken]);

  return (
    <>
      {ip.length !== 0 ? (
        ip.map((el, i) => {
          let color = '#ffffff';
          let active = null;
          if (el.status === 0) {
            color = '#dd344515';
            active = false;
          } else if (el.status === 1) {
            color = '#27a84315';
            active = true;
          } else if (el.status === 2) {
            color = '#dd344515';
            active = false;
          } else if (el.status === 3) {
            color = '#ffffff';
            active = null;
          }

          return (
            <div key={i}>
              <DataList
                dense={false}
                secondary={
                  <>
                    <span style={{ fontWeight: 'bold' }}>{el.adress}</span>
                    <span style={{ fontWeight: 'lighter' }}>:{el.port}</span>
                  </>
                }
                color={color}
                active={active}
                primary={el.name}
                lastOn={
                  <>
                    Dernière connexion:{' '}
                    <span style={{ fontWeight: 'bold' }}>{el.last_on}</span>
                  </>
                }
                show={el.ip_show}
                ip={el.adress}
              />
              {i === ip.length - 1 ? null : <Divider variant="middle" />}
            </div>
          );
        })
      ) : (
        <NoRows />
      )}
    </>
  );
}
