"Use strict";
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import { ListItemAvatar } from "@material-ui/core";

export default function ChatDialog({ openDialog, setOpenDialog, Lines }) {
    return (
        <Dialog
            onClose={() => {
                setOpenDialog(!openDialog);
            }}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
        >
            <List>
                {Lines &&
                    Lines.map((el, i) => (
                        <span key={i}>
                            <ListItem
                                component="label"
                                button
                                onClick={el.onClick}
                            >
                                <ListItemAvatar>{el.icon}</ListItemAvatar>
                                <ListItemText
                                    primary={el.primary}
                                    secondary={el.secondary}
                                />
                            </ListItem>
                        </span>
                    ))}
            </List>
        </Dialog>
    );
}
