import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

const Step = ({ steps, reset, back }) => {
  return (
    <ButtonGroup
      orientation="horizontal"
      color="primary"
      aria-label="vertical contained primary button group"
      variant="contained"
      fullWidth={true}
      size="large"
      disabled={!steps?.ctg_id}
    >
      <Button
        style={{
          height: "50px",
          width: "60px",
          padding: "10px",
          border: "white 0.1px solid",
        }}
        fullWidth
        variant="contained"
        color="secondary"
        onClick={reset}
      >
        <RotateLeftIcon fontSize="large" color="inherit" />
      </Button>
      <Button
        style={{
          height: "50px",
          padding: "10px",
          border: "white 0.1px solid",
        }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={back}
      >
        <ArrowBackIcon fontSize="large" color="inherit" />
      </Button>
    </ButtonGroup>
  );
};

export default Step;
