'Use strict';
import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Zoom from '@material-ui/core/Zoom';
import { Fade } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(3),
        width: '100px',
        height: '100px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    container: {
        padding: '10px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function Connexion() {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [remember, setRemember] = useState(true);
    const [user, setUser] = useState({
        data: '',
        error: false
    });
    const [password, setPassword] = useState({
        data: '',
        error: false
    });

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (user.data.length < 3 || password.data.length < 3) {
            toast.info('❌ Connexion refusée !', {
                position: 'bottom-left',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            setUser({ ...user, error: true });
            setPassword({ ...password, error: true });
        } else {
            axios
                .post(process.env.REACT_APP_BACK_API_URL + '/user/auth', {
                    user: user.data,
                    password: password.data,
                    remember: remember
                })
                .then((res) => {
                    axios
                        .get(
                            process.env.REACT_APP_BACK_API_URL +
                                '/info/pathRole',
                            {
                                headers: { token: res.data.accessToken }
                            }
                        )
                        .then((result) => {
                            if (result.data) {
                                dispatch({
                                    type: 'SET_USER_ACCESS_TOKEN',
                                    accessToken: res.data.accessToken
                                });
                                dispatch({
                                    type: 'SET_USER_ID',
                                    userId: res.data.isExistUser
                                });
                                history.push({
                                    pathname: result.data.pathname,
                                    state: result.data.state
                                });
                                toast.info('✅ Connexion réussie !', {
                                    position: 'bottom-left',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                            } else {
                                toast.info('❌ Connexion refusée !', {
                                    position: 'bottom-left',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                                setUser({ ...user, error: true });
                                setPassword({ ...password, error: true });
                            }
                        })
                        .catch((err) => {
                            setUser({ ...user, error: true });
                            setPassword({ ...password, error: true });
                            toast.info('❌ Connexion refusée !', {
                                position: 'bottom-left',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            });
                            console.error(err);
                        });
                })
                .catch((err) => {
                    toast.info('❌ Connexion refusée !', {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    setUser({ ...user, error: true });
                    setPassword({ ...password, error: true });
                    console.error(err);
                });
        }
    };

    return (
        <>
            <div className={classes.paper}>
                <Zoom in={true} timeout={600}>
                    <Avatar className={classes.avatar} src="/logo192.png" />
                </Zoom>
                <Fade in={true} timeout={1000}>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="user"
                            label="Utilisateur / Email"
                            name="user"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) =>
                                setUser({ ...user, data: e.target.value })
                            }
                            error={user.error}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) =>
                                setPassword({
                                    ...password,
                                    data: e.target.value
                                })
                            }
                            error={password.error}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="remember"
                                    color="primary"
                                    onChange={(e) =>
                                        setRemember(e.target.checked)
                                    }
                                    checked={remember}
                                />
                            }
                            label="Se souvenir de moi"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Se connecter
                        </Button>
                    </form>
                </Fade>
            </div>
        </>
    );
}
