'Use strict';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ViewStyle from '../ViewStyle';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Backdrop,
  CircularProgress,
  List,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { Autocomplete } from '@material-ui/lab';
import PropositionDialog from '../../utils/Proposition/PropositionDialog';
import ListItemProposition from '../../utils/Proposition/ListItemProposition';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function Propositions() {
  const accessToken = useSelector((state) => state.accessToken);
  const [clients, setClients] = useState([]);
  const [contrats, setContrats] = useState([]);
  const [client, setClient] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [role, setRole] = useState(null);
  const [value, setValue] = useState(0);
  const [openProposition, setOpenProposition] = useState({
    open: false,
    mode: null,
    contrat: null,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const breadLink = {
    view: 'Administration',
    bread: [
      {
        name: 'Accueil',
        href: '/',
      },
      {
        name: 'Propositions',
        href: '/propositions',
      },
    ],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenProposition = async (contrat) => {
    setOpenProposition({
      open: true,
      mode: 'update',
      contrat,
    });
  };

  const handleRole = useCallback(async () => {
    try {
      setLoading(true);
      axios
        .get(process.env.REACT_APP_BACK_API_URL + '/user/role', {
          headers: { token: accessToken },
        })
        .then((res) => {
          if (res && res.data && res.data.role) {
            if (
              res.data.role !== 0 &&
              res.data.role !== 1 &&
              res.data.role !== 3
            ) {
              history.push('/');
            } else setRole(res.data.role);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  }, [accessToken, history]);

  const handleClients = useCallback(async () => {
    try {
      setLoading(true);
      await axios
        .get(
          process.env.REACT_APP_BACK_API_URL + '/client/propositions/clients',
          {
            headers: { token: accessToken },
          }
        )
        .then((res) => {
          if (res?.data) {
            // console.log('clients', res.data);
            if (res.data.clients) setClients(res.data.clients);
            //set default contrats
            if (res.data.propositions) setContrats(res.data.propositions);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      throw e;
    }
  }, [accessToken]);

  const contratsProposition = useCallback(async () => {
    if (client?.clt_id) {
      try {
        setLoading(true);
        await axios
          .get(
            process.env.REACT_APP_BACK_API_URL +
              `/client/${client?.clt_id}/propositions`,
            {
              headers: { token: accessToken },
            }
          )
          .then((res) => {
            if (res?.data) {
              // console.log('contrats', res.data);
              setContrats(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (e) {
        throw e;
      }
    } else {
      handleClients();
    }
  }, [accessToken, client, handleClients]);

  useEffect(() => {
    handleRole();
  }, [handleRole]);

  useEffect(() => {
    if (!client) handleClients();
  }, [handleClients, client]);

  useEffect(() => {
    if (client) contratsProposition();
  }, [contratsProposition, client]);

  // console.log(client);

  return (
    <ViewStyle
      noPadding
      breadLink={breadLink}
      width="lg"
      dial="proposition"
      role={role}
      openProposition={() => {
        setOpenProposition((p) => ({
          ...p,
          open: true,
          mode: 'insert',
        }));
      }}
    >
      <Backdrop
        style={{
          zIndex: 9999999999,
          position: 'absolute',
          width: '100%',
          height: '100%',
          color: '#fff',
        }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PropositionDialog
        fullScreen={fullScreen}
        openProposition={openProposition?.open}
        contrat={openProposition?.contrat}
        mode={openProposition?.mode}
        setOpenProposition={setOpenProposition}
        accessToken={accessToken}
        setClient={setClient}
        client={client}
        handleClients={handleClients}
        contratsProposition={contratsProposition}
        setLoading={setLoading}
      />
      {clients ? (
        <Autocomplete
          id="client-box"
          options={clients}
          getOptionLabel={(option) =>
            `${option.clt_raisonsociale} ${option.clt_lastname} ${option.clt_firstname}`
          }
          renderOption={(option, props) => {
            return (
              <div {...props}>
                <b>{option.clt_raisonsociale}</b> {option.clt_lastname}{' '}
                {option.clt_firstname}
              </div>
            );
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionSelected={(option, value) =>
            option?.clt_id === value?.clt_id
          }
          onChange={(event, newValue) => {
            if (newValue === null) setContrats([]);
            setClient(newValue);
          }}
          value={client}
          inputValue={inputValue}
          style={{ padding: 8 }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sélectionnez un client"
              variant="outlined"
            />
          )}
        />
      ) : (
        <></>
      )}
      {contrats?.length > 0 ? (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab label={'Propositions'} fullWidth disabled />
          </Tabs>
          <TabPanel value={value} index={0}>
            <List
              sx={{
                width: '100%',
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              {contrats?.map((contrat, i) => {
                return (
                  <Fragment key={contrat?.ctr_id}>
                    <ListItemProposition
                      isClientSelected={!!client}
                      contrat={contrat}
                      handleOpenProposition={handleOpenProposition}
                      isEnd={!(i !== contrats?.length - 1)}
                      contratsProposition={contratsProposition}
                      accessToken={accessToken}
                      setLoading={setLoading}
                    />
                  </Fragment>
                );
              })}
            </List>
          </TabPanel>
        </>
      ) : (
        <></>
      )}
    </ViewStyle>
  );
}

export default Propositions;
