import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, CloudDownload } from '@material-ui/icons';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import TableChartIcon from '@material-ui/icons/TableChart';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PDFViewer({ file, downloadExcel, downloadBrutExcel }) {
  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function downloadPDF(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Set the filename for download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Clean up the Object URL
    setAnchorEl(null);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        style={{
          borderRadius: '10px',
          margin: '10px',
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(255,255,255,.3)',
            backdropFilter: 'blur(3em)',
            margin: 0,
            padding: 0,
            borderRadius: '10px',
          }}
        >
          <Button
            onClick={() => setPage((page) => (page > 1 ? page - 1 : page))}
            disabled={page === 1}
            variant="contained"
            color={page === 1 ? 'default' : 'primary'}
            style={{
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <ChevronLeft />
          </Button>
          <Button
            style={{ borderRadius: 0 }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            <CloudDownload />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                downloadPDF(file, 'reporting-eye-tech-rituals.pdf')
              }
            >
              <PictureAsPdfIcon style={{ marginRight: 10 }} /> Télécharger au
              format PDF
            </MenuItem>
            <Divider />
            <MenuItem onClick={downloadExcel}>
              <TableChartIcon style={{ marginRight: 10 }} /> Télécharger au
              format Excel
            </MenuItem>
            <Divider />
            <MenuItem onClick={downloadBrutExcel}>
              <TableChartIcon style={{ marginRight: 10 }} /> Télécharger au
              format Excel (base de données)
            </MenuItem>
          </Menu>
          <Button
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disableElevation
            style={{ height: '36px' }}
          >
            {page && numPages ? `${page} / ${numPages}` : `0 / 0`}
          </Button>
          <Button
            onClick={() =>
              setPage((page) => (page < numPages ? page + 1 : page))
            }
            disabled={page === numPages}
            variant="contained"
            color={page === numPages ? 'default' : 'primary'}
            style={{
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
            }}
          >
            <ChevronRight />
          </Button>
        </div>
      </ButtonGroup>
      <Document
        file={file}
        className={'PDFDocument'}
        onLoadSuccess={onDocumentLoadSuccess}
        error={'Une erreur est apparue lors du chargement du PDF'}
        loading={<CircularProgress />}
        noData={<CircularProgress />}
        onLoadError={() =>
          alert('Une erreur est apparue lors du chargement du PDF')
        }
        onSourceError={() =>
          alert('Une erreur est apparue car la source du PDF est incorrecte')
        }
        renderMode="canvas"
      >
        <Page
          className={'PDFPage'}
          error={'Une erreur est apparue sur la page'}
          loading={<CircularProgress />}
          noData={<CircularProgress />}
          onLoadError={() =>
            alert('Une erreur est apparue lors du chargement de la page')
          }
          onRenderError={() =>
            alert('Une erreur est apparue lors du rendu de la page')
          }
          pageNumber={page}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    </Box>
  );
}
