import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import MessageMenu from './MessageMenu';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  rootl: {
    maxWidth: '600px',
    margin: 4,
    float: 'left',
    borderBottomRightRadius: '25px',
    borderBottomLeftRadius: '25px',
    borderTopRightRadius: '25px',
    borderTopLeftRadius: '2px',
  },
  rootr: {
    maxWidth: '600px',
    margin: 4,
    float: 'right',
    borderBottomLeftRadius: '25px',
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    borderBottomRightRadius: '2px',
  },
  reply: {
    width: '100%',
    padding: 0,
    transform: 'scale(1, 1)',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '15px',
  },
  smallReply: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '10px',
  },
}));

export default function MessageBox({
  com_id,
  position,
  type,
  text,
  data,
  dateString,
  title,
  titleColor,
  reply,
  handleReply,
  isReply,
  user,
  role,
}) {
  const accessToken = useSelector((state) => state.accessToken);
  const classes = useStyles();

  const UploadMessage = () => {
    return (
      <a
        href={
          process.env.REACT_APP_BACK_API_URL +
          '/file?com_id=' +
          com_id +
          '&token=' +
          accessToken
        }
        target="_blank"
        style={{ textDecoration: 'none' }}
        rel="noopener noreferrer"
      >
        <CardContent style={{ padding: '10px 10px', cursor: 'pointer' }}>
          <Card
            style={{
              backgroundColor: 'rgba(245,245,245, 1)',
            }}
            className={classes.reply}
          >
            <CardHeader
              style={{ padding: '10px' }}
              avatar={
                <Avatar
                  aria-label="recipe"
                  className={classes.smallReply}
                  style={{
                    color: '#555',
                    backgroundColor: 'rgba(245,245,245, 1)',
                    transform: 'translateX(5px)',
                  }}
                >
                  <AttachmentIcon />
                </Avatar>
              }
              title={<span style={{}}>{text}</span>}
            />
          </Card>
        </CardContent>
      </a>
    );
  };

  const ReplyMessage = () => {
    return reply.type === 'text' ? (
      <CardContent
        onClick={() => {
          const yOffset = -65;
          const element = document.getElementById(reply.com_id);
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }}
        style={{ padding: '10px 5px', cursor: 'pointer' }}
      >
        <Card
          style={{
            borderTop: 'solid 1px ' + reply.titleColor,
            backgroundColor: 'rgba(245,245,245, 1)',
          }}
          className={classes.reply}
        >
          <CardHeader
            style={{ padding: 0 }}
            avatar={
              <Avatar
                aria-label="recipe"
                className={classes.smallReply}
                style={{
                  backgroundColor: reply.titleColor,
                  transform: 'translateX(5px)',
                  fontSize: '10px',
                }}
              >
                {reply.title.split(' ')[0].substr(0, 1).toUpperCase() +
                  reply.title.split(' ')[1].substr(0, 1).toUpperCase()}
              </Avatar>
            }
            title={<span style={{ fontSize: '10px' }}>{reply.title}</span>}
            subheader={<span style={{ fontSize: '10px' }}>{reply.date}</span>}
          />
          <Divider />
          <CardContent style={{ padding: 5 }}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ fontSize: '10px' }}
            >
              {reply.message.length >= 70
                ? reply.message.substr(0, 70) + '...'
                : reply.message}
            </Typography>
          </CardContent>
        </Card>
      </CardContent>
    ) : (
      <CardContent
        onClick={() => {
          const yOffset = -65;
          const element = document.getElementById(reply.com_id);
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }}
        style={{ padding: '10px 10px', cursor: 'pointer' }}
      >
        <Card
          style={{
            backgroundColor: 'rgba(245,245,245, 1)',
          }}
          className={classes.reply}
        >
          <CardHeader
            style={{ padding: 0 }}
            avatar={
              <Avatar
                aria-label="recipe"
                className={classes.smallReply}
                style={{
                  backgroundColor: reply.titleColor,
                  transform: 'translateX(5px)',
                  fontSize: '10px',
                }}
              >
                {reply.title.split(' ')[0].substr(0, 1).toUpperCase() +
                  reply.title.split(' ')[1].substr(0, 1).toUpperCase()}
              </Avatar>
            }
            title={<span style={{ fontSize: '10px' }}>{reply.title}</span>}
            subheader={<span style={{ fontSize: '10px' }}>{reply.date}</span>}
          />
          <Divider />
          <CardHeader
            style={{ padding: '10px' }}
            avatar={
              <Avatar
                aria-label="recipe"
                className={classes.smallReply}
                style={{
                  color: '#555',
                  backgroundColor: 'rgba(245,245,245, 1)',
                  transform: 'translateX(5px)',
                }}
              >
                <AttachmentIcon />
              </Avatar>
            }
            title={
              <span style={{}}>
                {reply.message.length >= 70
                  ? reply.message.substr(0, 70) + '...'
                  : reply.message}
              </span>
            }
          />
        </Card>
      </CardContent>
    );
  };

  let classRoot = null;

  if (isReply) {
    classRoot = classes.reply;
  } else if (position === 'left') {
    classRoot = classes.rootl;
  } else if (position === 'right') {
    classRoot = classes.rootr;
  }

  return (
    <Card id={com_id} className={classRoot}>
      <CardHeader
        style={{ padding: 2 }}
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.small}
            style={{
              backgroundColor: titleColor,
              transform: 'translateX(5px)',
            }}
          >
            {title.split(' ')[0].substr(0, 1).toUpperCase() +
              title.split(' ')[1].substr(0, 1).toUpperCase()}
          </Avatar>
        }
        action={
          isReply === true ? null : (
            <MessageMenu
              title={title}
              text={text}
              date={dateString}
              com_id={com_id}
              handleReply={handleReply}
              data={data}
              type={type}
              user={user}
              role={role}
            />
          )
        }
        title={title}
        subheader={dateString}
      />
      <Divider />
      {reply && <ReplyMessage />}
      {type && type === 'file' && <UploadMessage />}
      {type && type === 'text' && (
        <CardContent style={{ padding: '9px 12px' }}>
          <Typography variant="body2" color="textSecondary" component="p">
            {text}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
}
