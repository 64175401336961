import React, { useCallback, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import ScreenIcon from './ScreenIcon';
import JSMpeg from '@cycjimmy/jsmpeg-player';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ScreenSubtype from './ScreenSubtype';
import { useHistory, useLocation } from 'react-router';

const StreamScreen = ({ ip, channel, grid, fullscreen, subtype }) => {
  let cursor = 'auto';
  if (grid && !fullscreen) cursor = 'pointer';

  const useStyles = makeStyles((theme) => ({
    upbar: {
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.2) 70%, rgba(0,0,0,0) 100%)',
      width: '100%',
      height: '50px',
      position: 'absolute',
      zIndex: 999,
    },
    containerStream: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: '#333',
      cursor: cursor,
    },
    title: {
      color: 'white',
      fontSize: '13pt',
      position: 'relative',
      top: '10px',
      left: '15px',
    },
    streamOpen: {
      width: '100%',
      height: '100vh',
      border: '1px solid #ddd',
    },
    streamClose: {
      width: '100%',
      height:
        grid && fullscreen
          ? '50vh'
          : grid
          ? 'calc(50vh - 32px)'
          : 'calc(100vh - 64px)',
      border: '1px solid #ddd',
    },
    loader: {
      width: '100%',
      height:
        grid && fullscreen
          ? '50vh'
          : grid
          ? 'calc(50vh - 32px)'
          : 'calc(100vh - 64px)',
      border: '1px solid #ddd',
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#333',
      color: 'white',
      position: 'absolute',
      zIndex: 9,
    },
  }));

  const classes = useStyles();
  const screen = useFullScreenHandle();
  const accessToken = useSelector((state) => state.accessToken);
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [stream, setStream] = useState(null);

  const reportChange = useCallback(
    (state, handle) => {
      if (handle === screen) {
        // console.log("screen went to", state); //, handle);
      }
    },
    [screen]
  );

  const handleClick = () => {
    history.push({
      pathname: '/streaming',
      search: `?ip=${ip}&channel=${channel}&subtype=${subtype}`,
      state: location.state,
    });
  };

  useEffect(() => {
    setStream(null);
  }, [subtype]);

  useEffect(() => {
    let mounted = true;
    // console.log('appel de la requete channel ', channel);
    if (
      location.pathname &&
      ip &&
      accessToken &&
      channel &&
      !stream &&
      mounted
    ) {
      // console.log('ip detecte requete axios');

      axios
        .post(
          `${process.env.REACT_APP_BACK_API_URL}/rtsp`,
          { ip, channel, subtype },
          {
            headers: { token: accessToken },
          }
        )
        .then((res) => {
          //   console.log(res);
          if (mounted && res && res.data && res.data.uuid) {
            setStream(() => {
              const protocol =
                process.env.REACT_APP_ENV === 'dev' ? 'ws' : 'wss';
              //   console.log(
              //     `${protocol}://${process.env.REACT_APP_URL}/stream/${res.data.uuid}`
              //   );
              const stream = new JSMpeg.VideoElement(
                `#stream${channel}`,
                `${protocol}://${process.env.REACT_APP_URL}/stream/${res.data.uuid}`,
                {},
                {
                  autoplay: true,
                  audio: false,
                  loop: true,
                  onVideoDecode: (decoder, time) => setLoading(false),
                }
              );
              stream.els.canvas.style.objectFit = 'cover';
              stream.els.canvas.style.height = '100%';
              return stream;
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return () => (mounted = false);
  }, [accessToken, channel, ip, stream, subtype, location.pathname]);

  useEffect(() => {
    if (stream) {
      return () => {
        stream.destroy();
      };
    }
  }, [stream]);

  return (
    <FullScreen handle={screen} onChange={reportChange}>
      <div
        className={classes.containerStream}
        onClick={!fullscreen && handleClick}
      >
        <div className={classes.upbar}>
          <Typography variant="button" className={classes.title}>
            {`Channel ${channel}`}
          </Typography>
          <div
            style={{
              float: 'right',
              color: 'white',
            }}
          >
            <ScreenSubtype
              channel={channel}
              subtype={subtype}
              ip={ip}
              show={!screen.active && !grid}
            />
            <ScreenIcon screen={screen} show={!grid} />
          </div>
        </div>
        {loading && (
          <div className={classes.loader}>
            <CircularProgress color="inherit" />
          </div>
        )}
        <div
          id={`stream${channel}`}
          className={screen.active ? classes.streamOpen : classes.streamClose}
        />
      </div>
    </FullScreen>
  );
};

export default StreamScreen;
