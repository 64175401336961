import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmDialog({
    open,
    handleClick,
    title,
    text,
    handleConfirm,
    data,
    label,
    inputConfirm,
    setInputConfirm,
}) {
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClick}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{text}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={label}
                        error={inputConfirm.error}
                        type="text"
                        fullWidth
                        value={inputConfirm.data}
                        onChange={(e) => {
                            setInputConfirm((confirm) => {
                                return { ...confirm, data: e.target.value };
                            });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClick} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
