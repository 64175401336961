"Use strict";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingLeft: "30px",
        // paddingRight: "30px",
        textAlign: "center",
        margin: "center",
    },
}));

export default function BreadcrumbSAV({ array }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography wrap="nowrap" variant="body2" color="secondary">
                {array &&
                    array.map((el, i) => {
                        return (
                            <span key={i}>
                                {el.toUpperCase()}{" "}
                                {i === array.length - 1 ? null : (
                                    <NavigateNextIcon
                                        style={{
                                            position: "relative",
                                            top: "6px",
                                        }}
                                        color="primary"
                                    />
                                )}
                            </span>
                        );
                    })}
            </Typography>
        </div>
    );
}
