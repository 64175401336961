import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Grow,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import EyeButtonGroup from '../Produits/EyeButtonGroup';
import ProduitCard from '../Produits/ProduitCard';
import { Pagination } from '@material-ui/lab';

const MAX_PER_PAGE = 18;

const ProductsDialog = ({
  openProducts,
  setOpenProducts,
  fullScreen,
  accessToken,
  setData,
}) => {
  const [catalog, setCatalog] = useState([]);
  const [newCatalog, setNewCatalog] = useState([]);
  const [token, setToken] = useState(null);
  const [steps, setSteps] = useState({
    arf_id: undefined,
    arf2_id: undefined,
    ctg_id: undefined,
  });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function products() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + '/produits', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res && res.data) {
                setCatalog(res.data.products);
                setToken(res.data.token);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      products();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  const totNumPages = (prod) => Math.ceil(prod.length / MAX_PER_PAGE);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const normalizedContains = useCallback((haystack, needle) => {
    const regExp = new RegExp(removeDiacritics(needle), 'gi');
    return regExp.test(removeDiacritics(haystack));
  }, []);

  const findMatching = useCallback(
    (strToMatch, prod) => {
      return prod.filter((p) => normalizedContains(p?.art_nom, strToMatch));
    },
    [normalizedContains]
  );

  const stepFilter = useCallback(
    (arr) =>
      arr.filter((p) => {
        if (steps?.arf_id) {
          return (
            p?.ctg_id === steps?.ctg_id &&
            p?.arf2_id === steps?.arf2_id &&
            p?.arf_id === steps?.arf_id
          );
        } else if (steps?.arf2_id) {
          return p?.ctg_id === steps?.ctg_id && p?.arf2_id === steps?.arf2_id;
        } else if (steps?.ctg_id) {
          return p?.ctg_id === steps?.ctg_id;
        }
        return p;
      }),
    [steps]
  );

  useEffect(() => {
    setNewCatalog(() => {
      const prod = stepFilter(catalog);
      setTotalPage(totNumPages(prod));
      return prod.slice((page - 1) * MAX_PER_PAGE, page * MAX_PER_PAGE);
    });
  }, [catalog, page, stepFilter, findMatching]);

  return (
    <Dialog
      style={{ zIndex: 999999999 }}
      fullScreen={fullScreen}
      open={openProducts}
      onClose={() => setOpenProducts(false)}
    >
      <DialogTitle>Ajouter un produit</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container style={{ padding: 10 }} spacing={1} justify="center">
          <Grow in={true} timeout={600}>
            <Grid item xs={12}>
              <EyeButtonGroup steps={steps} setSteps={setSteps} />
            </Grid>
          </Grow>
          {newCatalog?.map((p, i) => (
            <Grow in={true} timeout={600} key={`${i} produit`}>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <ProduitCard
                  setSelected={() => {
                    setData((data) => ({
                      ...data,
                      products: [
                        ...data?.products,
                        { ...p, ctl_position: data?.products?.length + 1 },
                      ],
                    }));
                    setOpenProducts(false);
                  }}
                  product={p}
                  token={token}
                />
              </Grid>
            </Grow>
          ))}
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Pagination
              count={totalPage}
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProductsDialog;
