import React from "react";
import Alarme from "./Alarme";
import Autres from "./Autres";
import Controle from "./Controle";
import Particulier from "./Particulier";
import Videosurveillance from "./Videosurveillance";

const Fam2 = ({ steps, handleStep }) => {
  return (
    <>
      <Videosurveillance steps={steps} handleStep={handleStep} />
      <Alarme steps={steps} handleStep={handleStep} />
      <Controle steps={steps} handleStep={handleStep} />
      <Autres steps={steps} handleStep={handleStep} />
      <Particulier steps={steps} handleStep={handleStep} />
    </>
  );
};

export default Fam2;
