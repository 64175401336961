"Use strict";
import React, { useEffect } from "react";
import NoRows from "../../utils/NoRows/NoRows";
import ViewStyle from "../ViewStyle";

function SupportType(props) {
    let breadName = "";
    let breadHref = "";

    if (props.match.params.type === "video-surveillance") {
        breadName = "Vidéosurveillance";
        breadHref = "/support/video-surveillance";
    } else if (props.match.params.type === "alarme") {
        breadName = "Alarme";
        breadHref = "/support/alarme";
    } else if (props.match.params.type === "controle") {
        breadName = "Contrôle d'accès";
        breadHref = "/support/controle";
    }
    const breadLink = {
        view: "SupportType",
        bread: [
            {
                name: "Accueil",
                href: "/",
            },
            {
                name: "Support",
                href: "/support",
            },
            {
                name: breadName,
                href: breadHref,
            },
        ],
    };

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <ViewStyle breadLink={breadLink} width="lg">
            {/*props.match.params.type*/}
            <NoRows />
        </ViewStyle>
    );
}

export default SupportType;
