import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DescriptionIcon from "@material-ui/icons/Description";
import Bdc from "./Actions/Bdc";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    marginTop: "60px",
    maxWidth: "800px",
  },
}));

export default function Actions({
  token,
  articles,
  offre,
  setOffre,
  vente,
  loyer,
  entretien,
}) {
  const classes = useStyles();
  const [action, setAction] = useState(null);

  return action === null ? (
    <List className={classes.root}>
      <ListItem button onClick={() => setAction("BDC")}>
        <ListItemAvatar>
          <Avatar>
            <DescriptionIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText style={{ color: "#0A374C" }} primary="Bon de commande" />
      </ListItem>
    </List>
  ) : (
    <List className={classes.root}>
      <Bdc
        action={action}
        setAction={setAction}
        token={token}
        articles={articles}
        offre={offre}
        setOffre={setOffre}
        vente={vente}
        loyer={loyer}
        entretien={entretien}
      />
    </List>
  );
}
