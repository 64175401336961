"Use strict";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import pic1 from "../../../static/svg/undraw_video_files_fu10.svg";
import pic2 from "../../../static/svg/undraw_Surveillance_re_8tkl.svg";
import pic3 from "../../../static/svg/undraw_Login_re_4vu2.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        minWidth: 300,
        width: "100%",
    },
    image: {
        position: "relative",
        height: 200,
        [theme.breakpoints.down("xs")]: {
            width: "100% !important", // Overrides inline-style
            height: 100,
        },
        "&:hover, &$focusVisible": {
            zIndex: 1,
            "& $imageBackdrop": {
                opacity: 0.15,
            },
            "& $imageMarked": {
                opacity: 0,
            },
            "& $imageTitle": {
                border: "4px solid currentColor",
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: "40%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    imageBackdrop: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create("opacity"),
    },
    imageTitle: {
        position: "relative",
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
            theme.spacing(1) + 6
        }px`,
        fontWeight: "bold",
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: "absolute",
        bottom: -2,
        left: "calc(50% - 9px)",
        transition: theme.transitions.create("opacity"),
    },
}));

export default function EyeButton({ type }) {
    const [redirect, setRedirect] = useState(null);
    const image = [
        {
            name: "video",
            url: pic1,
            title: "VIDÉO SURVEILLANCE",
            width: "100%",
            href: "/support/video-surveillance",
        },
        {
            name: "alarme",
            url: pic2,
            title: "ALARME",
            width: "100%",
            href: "/support/alarme",
        },
        {
            name: "controle",
            url: pic3,
            title: "CONTRÔLE D'ACCÈS",
            width: "100%",
            href: "/support/controle",
        },
    ].find((el) => el.name === type);

    const classes = useStyles();

    const RedirectTo = () =>
        redirect ? <Redirect to={{ pathname: redirect, state: null }} /> : null;

    return (
        <div className={classes.root}>
            {RedirectTo()}
            <ButtonBase
                onClick={() => {
                    setRedirect(image.href);
                }}
                focusRipple
                key={image.title}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={{
                    width: image.width,
                }}
            >
                <span
                    className={classes.imageSrc}
                    style={{
                        backgroundImage: `url(${image.url})`,
                    }}
                />
                <span className={classes.imageBackdrop} />
                <span className={classes.imageButton}>
                    <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                        className={classes.imageTitle}
                    >
                        {image.title}
                        <span className={classes.imageMarked} />
                    </Typography>
                </span>
            </ButtonBase>
        </div>
    );
}
