import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";

const Nvr = ({ steps, handleStep }) => {
  return steps.ctg_id === 1 &&
    steps?.arf2_id === 2 &&
    steps?.arf_id === undefined ? (
    <>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 5)}
        >
          4 voies
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 6)}
        >
          8 voies
        </Button>
      </ButtonGroup>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 7)}
        >
          16 voies
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 8)}
        >
          +32 voies
        </Button>
      </ButtonGroup>
    </>
  ) : (
    <></>
  );
};

export default Nvr;
