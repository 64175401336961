'Use strict';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import AppsIcon from '@material-ui/icons/Apps';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import axios from 'axios';
import StatusIcon from '../StatusIcon/StatusIcon';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import CameraIcon from '@material-ui/icons/Camera';
import AssignmentIcon from '@material-ui/icons/Assignment';
// import SecurityIcon from '@material-ui/icons/Security';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function LeftMenu({ role }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.accessToken);

  const classes = useStyles();
  const [newRole, setNewRole] = useState(role);
  const [state, setState] = useState({
    left: false,
  });
  const [isNotif, setIsNotif] = useState(false);

  const [initial, setInitial] = useState({});

  useEffect(() => {
    let mounted = true;
    if (!role && mounted) {
      async function role() {
        try {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/role', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res && res.data && res.data.role && mounted) {
                setNewRole(res.data.role);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (error) {
          throw error;
        }
      }
      role();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, history, role]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      try {
        if (accessToken) {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/initial', {
              headers: { token: accessToken },
            })
            .then((response1) => {
              if (response1.data && mounted) {
                axios
                  .get(
                    process.env.REACT_APP_BACK_API_URL +
                      '/client/is-notifications',
                    {
                      headers: { token: accessToken },
                    }
                  )
                  .then((response2) => {
                    if (response2.data && mounted) {
                      setInitial({
                        initial: response1.data.initial,
                        name: response1.data.name,
                      });
                      if (isNaN(response2.data.count)) setIsNotif(true);
                      else setIsNotif(response2.data.count);
                    } else setIsNotif(false);
                  });
              }
            });
        }
      } catch (error) {
        throw error;
      }
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const Disconnect = () => {
    dispatch({ type: 'SET_USER_ACCESS_TOKEN', accessToken: null });
    history.push({
      pathname: '/',
      state: location.state,
    });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: false,
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link
          to={{ pathname: '/profil', state: null }}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItem button>
            <ListItemIcon>
              <StatusIcon>
                <Avatar style={{ backgroundColor: '#F4970E' }}>
                  {initial.initial}
                </Avatar>
              </StatusIcon>
            </ListItemIcon>
            <ListItemText primary={initial.name} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Link
          to={{ pathname: '/', state: null }}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItem button>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary={'Accueil'} />
          </ListItem>
        </Link>
        <Link
          to={{ pathname: '/support', state: null }}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItem button>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary={'Support'} />
          </ListItem>
        </Link>
        {/* {isNotif === true || isNotif > 0 ? (
          <Link
            to={{ pathname: '/synthese', state: null }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary={'Alarme intrusion'} />
            </ListItem>
          </Link>
        ) : (
          <></>
        )} */}
        {isNotif === true || isNotif > 0 ? (
          <Link
            to={{ pathname: '/reporting', state: null }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <InsertChartIcon />
              </ListItemIcon>
              <ListItemText primary={'Reporting'} />
            </ListItem>
          </Link>
        ) : (
          <></>
        )}
        {newRole === 1 ? (
          <Link
            to={{ pathname: '/administration', state: null }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={'Administration'} />
            </ListItem>
          </Link>
        ) : (
          <></>
        )}
        {newRole === 1 ? (
          <Link
            to={{ pathname: '/propositions', state: null }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary={'Propositions'} />
            </ListItem>
          </Link>
        ) : (
          <></>
        )}
        {newRole === 1 ? (
          <Link
            to="/produits"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItem button>
              <ListItemIcon>
                <CameraIcon />
              </ListItemIcon>
              <ListItemText primary={'Produits'} />
            </ListItem>
          </Link>
        ) : (
          <></>
        )}
      </List>
      <Divider />
      <List>
        <ListItem onClick={Disconnect} button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={'Déconnexion'} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <IconButton
        onClick={toggleDrawer('left', true)}
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        style={{ zIndex: 9999999999 }}
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {list('left')}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
