import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Grid } from '@material-ui/core';
import { useQuery } from '../../utils/useQuery';
import StreamScreen from '../../utils/Streaming/StreamScreen';
import StreamLeftMenu from '../../utils/Streaming/StreamLeftMenu';
import StreamAppBar from '../../utils/Streaming/StreamAppBar/StreamAppBar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

const NBPERPAGE = 4;
const SCREENTAB = [1, 2, 3, 4];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    marginTop: '64px',
  },
  openContent: {
    width: '100%',
    height: '100vh',
  },
}));

export default function Streaming() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const fullscreen = useFullScreenHandle();
  const query = useQuery();
  const ip = query.get('ip');
  const channel = query.get('channel');
  const subtype = query.get('subtype') ?? 1;
  const [open, setOpen] = useState(false);
  const [ipData, setIp] = useState(null);
  const [page, setPage] = useState(1);
  const accessToken = useSelector((state) => state.accessToken);
  const reportChange = useCallback(
    (state, handle) => {
      if (handle === fullscreen) {
        // console.log('Fullscreen went to', state); //, handle);
      }
    },
    [fullscreen]
  );

  useEffect(() => {
    if (channel) setPage(Math.ceil(channel / NBPERPAGE));
  }, [channel]);

  useEffect(() => {
    let mounted = true;
    if (accessToken && mounted) {
      axios
        .get(`${process.env.REACT_APP_BACK_API_URL}/contrat/ctrip/${ip}`, {
          headers: { token: accessToken },
        })
        .then((res) => {
          // console.log(res);
          if (res.data && mounted) setIp(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, history, ip]);

  return (
    <div className={classes.root}>
      <StreamAppBar
        open={open}
        setOpen={setOpen}
        screen={fullscreen}
        ip={ip}
        channel={channel}
        subtype={subtype}
        page={page}
        setPage={setPage}
        nbPage={
          ipData && ipData.ip_number
            ? Math.ceil(ipData.ip_number / NBPERPAGE)
            : 1
        }
      />
      <StreamLeftMenu
        cltId={
          location.state && location.state.clt_id
            ? location.state.clt_id
            : ipData && ipData.ip_clt
            ? ipData.ip_clt
            : null
        }
        ctrId={
          location.state && location.state.ctr_id
            ? location.state.ctr_id
            : ipData && ipData.ip_ctr
            ? ipData.ip_ctr
            : null
        }
        open={open}
        setOpen={setOpen}
        ip={ip}
        subtype={subtype}
        channel={channel}
        number={ipData && ipData.ip_number ? ipData.ip_number : null}
        setPage={setPage}
      />
      <main
        className={fullscreen.active ? classes.openContent : classes.content}
      >
        <FullScreen handle={fullscreen} onChange={reportChange}>
          <Grid container style={{ width: '100%', height: '100%' }}>
            {ipData &&
              SCREENTAB.map((number) => {
                const i = number + NBPERPAGE * (page - 1);
                if (ip && ipData.ip_number && i <= ipData.ip_number) {
                  if (channel) {
                    return channel === i.toString() ? (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        style={{
                          height: 'calc(100vh - 64px)',
                        }}
                      >
                        <StreamScreen
                          ip={ip}
                          channel={i}
                          fullscreen={fullscreen.active}
                          subtype={subtype}
                        />
                      </Grid>
                    ) : null;
                  } else {
                    return (
                      <Grid
                        key={i}
                        item
                        xs={6}
                        style={{
                          height: fullscreen.active
                            ? '50vh'
                            : 'calc(50vh - 32px)',
                        }}
                      >
                        <StreamScreen
                          ip={ip}
                          channel={i}
                          grid={!channel}
                          fullscreen={fullscreen.active}
                          subtype={subtype}
                        />
                      </Grid>
                    );
                  }
                } else {
                  return null;
                }
              })}
          </Grid>
        </FullScreen>
      </main>
    </div>
  );
}
