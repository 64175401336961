import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";

const Vauban = ({ steps, handleStep }) => {
  return steps.ctg_id === 1 &&
    steps?.arf2_id === 13 &&
    steps?.arf_id === undefined ? (
    <>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 53)}
        >
          Catégorie 1
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 54)}
        >
          Catégorie 2
        </Button>
      </ButtonGroup>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 55)}
        >
          Catégorie 3
        </Button>
      </ButtonGroup>
    </>
  ) : (
    <></>
  );
};

export default Vauban;
