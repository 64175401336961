import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Grow from "@material-ui/core/Grow";
import List from "@material-ui/core/List";

function DataList({ article, accessToken }) {
    return (
        <List dense={false}>
            <Grow in={true} timeout={300}>
                <ListItem>
                    {article.art_photo ? (
                        <img
                            src={
                                process.env.REACT_APP_XAVSIT +
                                "/fileProduct.php?id=" +
                                article.art_id +
                                "&jwt=" +
                                accessToken
                            }
                            style={{
                                objectFit: "cover",
                                width: "10%",
                                minWidth: "75px",
                            }}
                            alt="Product"
                        />
                    ) : null}
                    <ListItemText
                        style={{ marginLeft: "15px" }}
                        primary={
                            article.art_ref ? (
                                <span
                                    style={{
                                        color: "#0A374C",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {article.art_ref}
                                </span>
                            ) : null
                        }
                        secondary={
                            article.art_desc
                                ? article.art_desc
                                      .split("\n")
                                      .map(function (item, i) {
                                          return (
                                              <span
                                                  key={i}
                                                  style={{
                                                      fontWeight: "lighter",
                                                  }}
                                              >
                                                  {item}
                                                  <br />
                                              </span>
                                          );
                                      })
                                : null
                        }
                    />
                    <Tooltip title={"Quantité"} aria-label="quantity">
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="quantity"
                                color="primary"
                                style={{
                                    marginRight: "5px",
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: "rgb(255, 255, 255, 0.8)",
                                }}
                            >
                                {article.ctl_qte}
                            </IconButton>
                        </ListItemSecondaryAction>
                    </Tooltip>
                </ListItem>
            </Grow>
        </List>
    );
}

export default DataList;
