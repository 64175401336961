"Use strict";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Paper,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import NoRows from "../NoRows/NoRows";

const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        position: "fixed",
        overflow: "scroll",
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    item: {
        overflowX: "hidden",
        textOverflow: "ellipsis",
    },
    subheader: {
        whiteSpace: "nowrap",
        backgroundColor: "#fafafa",
        border: "1px solid #eee",
        overflowX: "hidden",
        textOverflow: "ellipsis",
    },
    appBar: {
        top: "auto",
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: "absolute",
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: "0 auto",
    },
    date: {
        fontSize: "11px",
    },
}));

export default function Notifications({ notifsA }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const location = useLocation();
    const history = useHistory();

    const getLastPart = (str, splitChar) => {
        let isChar = str.indexOf(splitChar);
        if (isChar !== -1) {
            let newStr = str.split(splitChar);
            delete newStr[0];
            let finalStr = newStr.join("");
            return finalStr;
        } else {
            return str;
        }
    };

    const [notifs, setNotifs] = useState(() => {
        if (notifsA)
            notifsA.map((el) => {
                let m = new Date(el.com_date);
                var date =
                    m.getUTCDate().toString().padStart(2, "0") +
                    "/" +
                    (m.getUTCMonth() + 1).toString().padStart(2, "0") +
                    "/" +
                    m.getUTCFullYear() +
                    " à " +
                    m.getUTCHours().toString().padStart(2, "0") +
                    "h" +
                    m.getUTCMinutes().toString().padStart(2, "0");
                let message = "";
                if (!el.com_data && el.com_pj) {
                    message = (
                        <>
                            <FileCopyIcon
                                style={{
                                    fontSize: "20px",
                                    transform: "translateY(3px)",
                                }}
                            />{" "}
                            {getLastPart(el.com_pj, "-")}
                        </>
                    );
                } else {
                    message = el.com_data;
                }
                return {
                    id: el.sav_id,
                    title: el.clt_raisonsociale + " → " + el.ctr_ref,
                    sender: el.acc_firstname + " " + el.acc_lastname,
                    message,
                    initial:
                        el && el.acc_firstname && el.acc_lastname
                            ? el.acc_firstname.substr(0, 1).toUpperCase() +
                              el.acc_lastname.substr(0, 1).toUpperCase()
                            : null,
                    date,
                };
            });
    });

    useEffect(() => {
        if (notifsA)
            setNotifs((notifs) =>
                notifsA.map((el) => {
                    let m = new Date(el.com_date);
                    var date =
                        m.getUTCDate().toString().padStart(2, "0") +
                        "/" +
                        (m.getUTCMonth() + 1).toString().padStart(2, "0") +
                        "/" +
                        m.getUTCFullYear() +
                        " à " +
                        m.getUTCHours().toString().padStart(2, "0") +
                        "h" +
                        m.getUTCMinutes().toString().padStart(2, "0");
                    let message = "";
                    if (!el.com_data && el.com_pj) {
                        message = (
                            <>
                                <FileCopyIcon
                                    style={{
                                        fontSize: "20px",
                                        transform: "translateY(3px)",
                                    }}
                                />{" "}
                                {getLastPart(el.com_pj, "-")}
                            </>
                        );
                    } else {
                        message = el.com_data;
                    }
                    return {
                        id: el.sav_id,
                        title: el.clt_raisonsociale + " → " + el.ctr_ref,
                        sender: el.acc_firstname + " " + el.acc_lastname,
                        message,
                        initial:
                            el.acc_firstname.substr(0, 1).toUpperCase() +
                            el.acc_lastname.substr(0, 1).toUpperCase(),
                        date,
                    };
                })
            );
    }, [notifsA]);

    return (
        <Paper
            elevation={3}
            className={classes.paper}
            style={
                matches
                    ? {
                          height: "calc(100vh - 80px)",
                          width: "50vw",
                          right: theme.spacing(1),
                          top: theme.spacing(9),
                      }
                    : {
                          height: "calc(100vh - 65px)",
                          width: "100vw",
                          right: 0,
                          top: theme.spacing(8),
                      }
            }
        >
            <Typography className={classes.text} variant="h5" gutterBottom>
                Notifications
            </Typography>
            <List className={classes.list}>
                {notifs && notifs.length ? (
                    notifs.map(
                        ({ id, title, sender, message, initial, date }) => (
                            <React.Fragment key={id}>
                                <ListSubheader className={classes.subheader}>
                                    {title}
                                </ListSubheader>
                                <ListItem
                                    onClick={() => {
                                        history.push({
                                            pathname: "/sav",
                                            state: {
                                                ...location.state,
                                                sav_id: id,
                                            },
                                        });
                                    }}
                                    className={classes.item}
                                    button
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            style={{
                                                backgroundColor: "#05384C",
                                            }}
                                        >
                                            {
                                                <span
                                                    style={{
                                                        transform:
                                                            "translateY(0.5px)",
                                                    }}
                                                >
                                                    {initial}
                                                </span>
                                            }
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={sender}
                                        secondary={message}
                                    />
                                    <span className={classes.date}>{date}</span>
                                </ListItem>
                            </React.Fragment>
                        )
                    )
                ) : (
                    <NoRows />
                )}
            </List>
        </Paper>
    );
}
