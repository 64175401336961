'Use strict';
import React from 'react';
import ClickList from '../../../utils/List/ClickList';
import Divider from '@material-ui/core/Divider';
import { useEffect, useState } from 'react';
import axios from 'axios';
import NoRows from '../../../utils/NoRows/NoRows';

function Factures({ ctrId, accessToken }) {
  const [factures, setFactures] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      axios
        .get(process.env.REACT_APP_BACK_API_URL + '/contrat/facture/' + ctrId, {
          headers: { token: accessToken },
        })
        .then((res) => {
          if (res.data && mounted) setFactures(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      mounted = false;
    };
  }, [ctrId, accessToken]);

  return (
    <>
      {factures.length !== 0 ? (
        factures.map((el, i) => (
          <div key={i}>
            {el.cls_pj1 ? (
              <div key={i}>
                <ClickList
                  dense={false}
                  primary={el.cls_sujet}
                  secondary={el.cls_pj1}
                  link={
                    process.env.REACT_APP_XAVSIT +
                    '/file.php?p=1&id=' +
                    el.cls_id +
                    '&jwt=' +
                    accessToken
                  }
                />
                {i === factures.length - 1 ? null : (
                  <Divider variant="middle" />
                )}
              </div>
            ) : null}
            {el.cls_pj2 ? (
              <div key={i}>
                <ClickList
                  dense={false}
                  primary={el.cls_sujet}
                  secondary={el.cls_pj2}
                  link={
                    process.env.REACT_APP_XAVSIT +
                    '/file.php?p=2&id=' +
                    el.cls_id +
                    '&jwt=' +
                    accessToken
                  }
                />
                {i === factures.length - 1 ? null : (
                  <Divider variant="middle" />
                )}
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <NoRows />
      )}
    </>
  );
}

export default Factures;
