'Use strict';
import React, { useEffect, useState, useRef } from 'react';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Notifications from '../Notifications/Notifications';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import SoundNotif from '../../../static/mp3/notification.mp3';

const defaultProps = {
  color: 'secondary',
  children: <NotificationsIcon />,
};

export default function NotificationsBtn() {
  const [open, setOpen] = useState(false);
  const [accessToken] = useState(useSelector((state) => state.accessToken));
  const [notifications, setNotifications] = useState([]);
  const [shakeNotif, setShakeNotif] = useState(false);

  let socket = useRef(null);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      socket.current = io.connect(process.env.REACT_APP_BACK_URL);
      socket.current.on('sendMessage', (data) => {
        axios
          .get(process.env.REACT_APP_BACK_API_URL + '/user/notifications', {
            headers: { token: accessToken },
          })
          .then((res) => {
            if (res.data && mounted) {
              if (
                res.data.length !== notifications.length &&
                res.data.length > 0
              ) {
                var audio = new Audio(SoundNotif);
                audio.play();
                setShakeNotif(true);
                setTimeout(() => {
                  setShakeNotif(false);
                }, 4000);
              }
              setNotifications(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
      return () => {
        socket.current.disconnect();
      };
    }
    return () => (mounted = false);
  }, [accessToken, notifications.length]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      axios
        .get(process.env.REACT_APP_BACK_API_URL + '/user/notifications', {
          headers: { token: accessToken },
        })
        .then((res) => {
          if (res.data && mounted) {
            setNotifications(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  return (
    <>
      <Tooltip title="Notifications" aria-label="notifications">
        <IconButton
          className={shakeNotif ? 'shake' : ''}
          aria-label="show more"
          aria-haspopup="true"
          color="inherit"
          onClick={() => (notifications ? setOpen(!open) : null)}
        >
          <Badge
            badgeContent={notifications && notifications.length}
            max={100}
            {...defaultProps}
          />
        </IconButton>
      </Tooltip>
      <Backdrop open={open} onClick={() => setOpen(!open)} />
      {open ? <Notifications notifsA={notifications} /> : null}
    </>
  );
}
