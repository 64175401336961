"Use strict";
import React from "react";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        height: theme.spacing(3),
        color: "white",
        fontWeight: theme.typography.fontWeightRegular,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.grey[800],
        },
        "&:active": {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.primary.main, 0.12),
        },
    },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

const StyledBreadcrumbO = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        height: theme.spacing(3),
        color: "white",
        fontWeight: theme.typography.fontWeightRegular,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.grey[800],
        },
        "&:active": {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.primary.main, 0.12),
        },
    },
}))(Chip);

export default function Breadcrumb(props) {
    let { array, back } = props;
    const location = useLocation();
    const history = useHistory();

    return (
        // <Slide in={true} timeout={400}>
        <Breadcrumbs
            maxItems={3}
            itemsAfterCollapse={1}
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
        >
            {back ? (
                <StyledBreadcrumbO
                    onClick={() => {
                        history.push(back);
                    }}
                    label={
                        <>
                            <KeyboardBackspaceIcon
                                style={{ position: "relative", top: "3px" }}
                                fontSize="small"
                            />
                            <span
                                style={{ position: "relative", bottom: "3px" }}
                            >
                                Retour
                            </span>
                        </>
                    }
                />
            ) : null}
            {array.bread.map((el, i) => {
                return i === array.bread.length - 1 ? (
                    <StyledBreadcrumbO key={i} label={el.name} />
                ) : (
                    <Link
                        style={{ textDecoration: "none" }}
                        key={i}
                        to={{ pathname: el.href, state: location.state }}
                    >
                        <StyledBreadcrumb label={el.name} />
                    </Link>
                );
            })}
        </Breadcrumbs>
        // </Slide>
    );
}
