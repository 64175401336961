import React, { useCallback, useEffect, useState } from 'react';

export const handleChooseCtg = (art_fam) => {
  if (art_fam <= 22) return 'VideoSurveillance';
  else if (art_fam > 22 && art_fam <= 49) return 'Alarme';
  else if (art_fam > 49 && art_fam <= 64) return 'Controle';
  else if (art_fam > 64) return 'Thermique';
};

const Canvas = ({
  style,
  src,
  selectedProduct,
  setData,
  products,
  canvasRef,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [mousePosition, setMousePosition] = useState({
    x: selectedProduct?.ctl_x || 0,
    y: selectedProduct?.ctl_y || 0,
  });

  const drawCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    const image = new Image();
    image.setAttribute('crossorigin', 'anonymous');
    image.src = src;
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const drawProduct = (x, y, position, art_fam) => {
        const ctg_nom = handleChooseCtg(art_fam);
        const xPos = x * image.width;
        const yPos = y * image.height;

        ctx.beginPath();
        ctx.arc(
          xPos,
          yPos,
          Math.min(image.width / 26, image.height / 26),
          0,
          2 * Math.PI
        );

        if (ctg_nom === 'VideoSurveillance') ctx.fillStyle = 'LightSkyBlue';
        else if (ctg_nom === 'Alarme') ctx.fillStyle = 'Crimson';
        else if (ctg_nom === 'Controle') ctx.fillStyle = 'YellowGreen';
        else if (ctg_nom === 'Thermique') ctx.fillStyle = 'DarkOrange';
        ctx.fill();
        ctx.closePath();

        ctx.font = `${Math.min(image.width / 16, image.height / 16)}px Arial`;
        ctx.fillStyle = 'black';
        ctx.fontWeight = 'bold';
        ctx.fillText(
          position,
          xPos - Math.min(image.width / 60, image.height / 60),
          yPos + Math.min(image.width / 45, image.height / 45)
        );
      };

      if (selectedProduct) {
        drawProduct(
          mousePosition.x,
          mousePosition.y,
          selectedProduct?.ctl_position,
          selectedProduct?.art_fam
        );
      } else if (products) {
        products.forEach((product) => {
          drawProduct(
            product.ctl_x,
            product.ctl_y,
            product.ctl_position,
            product.art_fam
          );
        });
      }
    };
  }, [src, mousePosition, selectedProduct, products, canvasRef]);

  const handleCanvasClick = (e) => {
    if (selectedProduct) {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const mouseX = (e.clientX - rect.left) / canvas.offsetWidth;
      const mouseY = (e.clientY - rect.top) / canvas.offsetHeight;
      setMousePosition({ x: mouseX, y: mouseY });
      if (setData)
        setData((data) => ({
          ...data,
          products: data.products.map((product) => {
            if (product.ctl_position === selectedProduct.ctl_position) {
              return {
                ...product,
                ctl_x: mouseX,
                ctl_y: mouseY,
              };
            }
            return product;
          }),
        }));
    }
  };

  const handleMouseDown = (e) => {
    if (selectedProduct) setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging && selectedProduct) {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const mouseX = (e.clientX - rect.left) / canvas.offsetWidth;
      const mouseY = (e.clientY - rect.top) / canvas.offsetHeight;
      setMousePosition({ x: mouseX, y: mouseY });
    }
  };

  const handleMouseUp = () => {
    if (isDragging && selectedProduct) {
      if (setData)
        setData((data) => ({
          ...data,
          products: data.products.map((product) => {
            if (product.ctl_position === selectedProduct.ctl_position) {
              return {
                ...product,
                ctl_x: mousePosition.x,
                ctl_y: mousePosition.y,
              };
            }
            return product;
          }),
        }));
      setIsDragging(false);
    }
  };

  useEffect(() => {
    drawCanvas();
  }, [drawCanvas]);

  return (
    <>
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onClick={handleCanvasClick}
        style={style}
      ></canvas>
    </>
  );
};

export default Canvas;
