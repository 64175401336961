import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { EditLocation } from '@material-ui/icons';
import React from 'react';
import ImageInputFile from './ImageInputFile';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { handleChooseCtg } from './Canvas';

const ProductAccordion = ({
  key,
  product,
  data,
  setData,
  setOpenCanvas,
  setSelectedProductPosition,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleUpdatePositionUp = () => {
    setAnchorEl(null);
    let products = [...data?.products];
    let index = products.findIndex(
      (p) => p.ctl_position === product?.ctl_position
    );
    products[index].ctl_position = index;
    products[index - 1].ctl_position = index + 1;
    setData((data) => ({
      ...data,
      products: products,
    }));
  };

  const handleUpdatePositionDown = () => {
    setAnchorEl(null);
    let products = [...data?.products];
    let index = products.findIndex(
      (p) => p.ctl_position === product?.ctl_position
    );
    products[index].ctl_position = index + 2;
    products[index + 1].ctl_position = index + 1;
    setData((data) => ({
      ...data,
      products: products,
    }));
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    if (window?.confirm('Voulez-vous supprimer ce produit ?')) {
      setData((prevData) => {
        const updatedProducts = prevData?.products
          .filter((p) => p.ctl_position !== product?.ctl_position)
          .map((p, index) => ({ ...p, ctl_position: index + 1 }));

        return {
          ...prevData,
          products: updatedProducts,
        };
      });
    }
  };

  const handleOpenMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleChooseColor = (art_fam) => {
    const ctg_nom = handleChooseCtg(art_fam);
    if (ctg_nom === 'VideoSurveillance') return 'LightSkyBlue';
    else if (ctg_nom === 'Alarme') return 'Crimson';
    else if (ctg_nom === 'Controle') return 'YellowGreen';
    else if (ctg_nom === 'Thermique') return 'DarkOrange';
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        style={{ zIndex: 9999999999 }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={handleUpdatePositionUp}
          disabled={
            data?.products.findIndex(
              (p) => p.ctl_position === product?.ctl_position
            ) === 0
          }
        >
          Monter le produit
        </MenuItem>
        <MenuItem
          onClick={handleUpdatePositionDown}
          disabled={
            data?.products.findIndex(
              (p) => p.ctl_position === product?.ctl_position
            ) ===
            data?.products.length - 1
          }
        >
          Descendre le produit
        </MenuItem>
        <MenuItem onClick={handleDelete} style={{ color: 'red' }}>
          Supprimer le produit
        </MenuItem>
      </Menu>
      <Accordion
        key={key}
        style={{ backgroundColor: '#f5f5f5', marginBottom: '10px' }}
      >
        <AccordionSummary
          expandIcon={<MoreHorizIcon onClick={handleOpenMenu} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {product?.ctl_x && product?.ctl_y ? (
            <span
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                marginRight: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                backgroundColor: handleChooseColor(product?.art_fam),
                color: 'black',
              }}
            >
              {product?.ctl_position}
            </span>
          ) : (
            <></>
          )}
          <Typography>
            {product?.ctl_ref ? `${product?.ctl_ref} - ` : ''}
            <span style={{ color: 'grey' }}>{product?.art_nom}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                style={{ backgroundColor: 'white' }}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="Référence"
                value={product?.ctl_ref ?? ''}
                onChange={(e) => {
                  let products = [...data?.products];
                  let index = products.findIndex(
                    (p) => p.ctl_position === product?.ctl_position
                  );
                  products[index].ctl_ref = e.target.value;
                  setData((data) => ({
                    ...data,
                    products: products,
                  }));
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ backgroundColor: 'white' }}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="Commentaire"
                value={product?.ctl_comment ?? ''}
                onChange={(e) => {
                  let products = [...data?.products];
                  let index = products.findIndex(
                    (p) => p.ctl_position === product?.ctl_position
                  );
                  products[index].ctl_comment = e.target.value;
                  setData((data) => ({
                    ...data,
                    products: products,
                  }));
                }}
                variant="outlined"
                size="small"
                multiline
                minRows={2}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={
                handleChooseCtg(product?.art_fam) === 'VideoSurveillance'
                  ? 6
                  : 12
              }
            >
              <ImageInputFile
                id={`contained-button-file-emplacement-${product?.ctl_position}`}
                label="Emplacement"
                src={product?.ctl_emplacement}
                setFile={(file) => {
                  let products = [...data?.products];
                  let index = products.findIndex(
                    (p) => p.ctl_position === product?.ctl_position
                  );
                  products[index].ctl_emplacement = file;
                  setData((data) => ({
                    ...data,
                    products: products,
                  }));
                }}
              />
            </Grid>
            {handleChooseCtg(product?.art_fam) === 'VideoSurveillance' && (
              <Grid item xs={12} sm={6}>
                <ImageInputFile
                  id={`contained-button-file-vision-${product?.ctl_position}`}
                  label="Champs de vision"
                  src={product?.ctl_vision}
                  setFile={(file) => {
                    let products = [...data?.products];
                    let index = products.findIndex(
                      (p) => p.ctl_position === product?.ctl_position
                    );
                    products[index].ctl_vision = file;
                    setData((data) => ({
                      ...data,
                      products: products,
                    }));
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color={product?.ctl_x && product?.ctl_y ? undefined : 'primary'}
                component="span"
                disabled={!data?.ctr_plan}
                startIcon={<EditLocation />}
                onClick={() => {
                  setSelectedProductPosition(product?.ctl_position);
                  setOpenCanvas(true);
                }}
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                Placer le produit sur le plan
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProductAccordion;
