'Use strict';
import React, { useEffect, useState } from 'react';
import DataListAdmin from '../../../utils/List/DataListAdmin';
import NoRows from '../../../utils/NoRows/NoRows';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import ImgCard from '../../../utils/ImgCard/ImgCard';
import IlluCtr from '../../../../static/svg/undraw_active_options_8je6.svg';
import IlluClt from '../../../../static/svg/undraw_certificate_343v.svg';

const Account = ({ accessToken }) => {
  const [accounts, setAccounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState(null);
  const handleClose = () => {
    setOpen((open) => false);
  };

  const handleOpen = (res) => {
    setBody((body) => res);
    setOpen((open) => true);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function client() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/adminAccount', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res.data && mounted) {
                setAccounts(res.data);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      client();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid container justify="center">
          {body &&
            body.map((el, i) => (
              <Grid item key={i}>
                <ImgCard
                  Illustration={el.ctr_ref ? IlluCtr : IlluClt}
                  data={el}
                />
              </Grid>
            ))}
        </Grid>
      </Dialog>
      {accounts.length ? (
        accounts.map((el, i) => {
          return (
            <span key={i}>
              <DataListAdmin
                handleOpen={handleOpen}
                name={el.acc_lastname + ' ' + el.acc_firstname}
                initial={
                  el.acc_firstname.substr(0, 1).toUpperCase() +
                  el.acc_lastname.substr(0, 1).toUpperCase()
                }
                email={el.acc_user}
                id={el.acc_id}
                res={el.res}
              />
              {i === accounts.length - 1 ? null : <Divider variant="middle" />}
            </span>
          );
        })
      ) : (
        <NoRows />
      )}
    </>
  );
};

export default Account;
