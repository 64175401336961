'Use strict';
import { Card, Container } from '@material-ui/core';
import React, { cloneElement } from 'react';
import Breadcrumb from '../utils/Breadcrumb/Breadcrumb';
import Header from '../utils/Header/Header';
import { Grow } from '@material-ui/core';
import BtnDial from '../utils/BtnSAV/BtnDial';
import { Zoom } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link style={{ color: '#0A374C' }} href="https://www.eye-tech.fr/">
        Eye Tech
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function ViewStyle({
  children,
  breadLink,
  width,
  noPadding,
  noCard,
  dial,
  clt,
  ctr,
  search,
  handleSearch,
  role,
  openProposition,
  preChildren = <></>,
}) {
  let propsHeader = { role };
  if (ctr) {
    propsHeader = {
      ...propsHeader,
      ctr,
    };
  }
  if (clt) {
    propsHeader = {
      ...propsHeader,
      clt,
    };
  }
  if (search) {
    propsHeader = {
      ...propsHeader,
      search,
    };
  }
  if (handleSearch) {
    propsHeader = {
      ...propsHeader,
      handleSearch,
    };
  }

  return (
    <>
      {cloneElement(<Header />, propsHeader)}
      <Container maxWidth={width}>
        {breadLink ? (
          <Card
            style={{
              marginTop: '20px',
              padding: '10px',
              backgroundColor: 'white',
            }}
          >
            <Breadcrumb array={breadLink} />
          </Card>
        ) : null}
        {noCard ? (
          <span style={{ marginTop: breadLink ? '10px' : '20px' }}>
            {preChildren}
            {children}
          </span>
        ) : (
          <>
            <Grow in={true} timeout={300}>
              <Card
                style={{
                  marginTop: breadLink ? '10px' : '20px',
                  padding: noPadding ? '0' : '10px',
                  backgroundColor: 'white',
                }}
              >
                {preChildren}
              </Card>
            </Grow>
            <Grow in={true} timeout={600}>
              <Card
                style={{
                  marginTop: breadLink ? '10px' : '20px',
                  padding: noPadding ? '0' : '10px',
                  backgroundColor: 'white',
                }}
              >
                {children}
              </Card>
            </Grow>
          </>
        )}
        {dial && role !== null && role !== undefined && (
          <Zoom in={true} timeout={600}>
            <BtnDial
              dial={dial}
              role={role}
              openProposition={openProposition}
            />
          </Zoom>
        )}
        <Fade in={true} timeout={600}>
          <Box mt={8} pb={3}>
            <Copyright />
          </Box>
        </Fade>
      </Container>
    </>
  );
}

export default ViewStyle;
