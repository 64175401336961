/* eslint-disable no-useless-computed-key */
"Use strict";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import { Backdrop, Grid, Grow, MenuItem, Select } from "@material-ui/core";
import ProduitCard from "./ProduitCard";
import NoRows from "../NoRows/NoRows";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import Actions from "./Actions";
import BlockIcon from "@material-ui/icons/Block";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(245,245,245, 1)",
    height: "100%",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#0B374C",
    ["@media (max-width:600px)"]: {
      paddingTop: "56px",
    },
    ["@media (min-width:600px)"]: {
      paddingTop: "64px",
    },
  },
  icon: {
    position: "relative",
    bottom: "10px",
  },
  bottomBar: {
    overflow: "hidden",
    padding: 0,
    position: "fixed",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    backgroundColor: "white",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "10px",
    transition: "height .4s ease",
    zIndex: 99999,
  },
  bottomBarDisplayed: {
    overflowY: "scroll",
    overflowX: "hidden",
    padding: 0,
    backgroundColor: "white",
    position: "fixed",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "75vh",
    transition: "height .8s ease",
    zIndex: 99999,
  },
  bottomBarTop: {
    width: "100%",
    zIndex: 1000000,
    position: "fixed",
    backgroundColor: "#0B374C",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    display: "flex",
    color: "white",
    padding: "0 16px",
    paddingTop: "16px",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductDialog({
  setSelected,
  selected,
  openDialog,
  setOpenDialog,
  title,
  token,
  adminConfig,
}) {
  const classes = useStyles();
  const [actions, setActions] = useState(false);
  const [offre, setOffre] = useState(1);

  const totalItems = selected.reduce(function (acc, article) {
    return acc + article?.quantity;
  }, 0);

  const installedDays = totalItems * adminConfig.client_coef_install;

  const totalPa = selected.reduce(function (acc, article) {
    return acc + article?.art_pa * article?.quantity;
  }, 0);

  const installedFees = Math.max(
    adminConfig.client_min_install,
    installedDays * adminConfig.client_prix_journee
  );

  const pv = Math.round(installedFees + totalPa * adminConfig.client_coef_pa);

  const location = Math.round(pv / adminConfig.client_coef_loyer);

  const entretien = Math.round(
    Math.max(
      adminConfig.client_min_entretien,
      location * adminConfig.client_coef_entretien
    )
  );

  let displayTotal;

  if (selected.length === 0) {
    displayTotal = "";
  } else if (offre === 2) {
    displayTotal = `${new Intl.NumberFormat("fr-FR", {
      // style: "currency",
      currency: "EUR",
    }).format(pv)} € + ${new Intl.NumberFormat("fr-FR", {
      // style: "currency",
      currency: "EUR",
    }).format(entretien)} € / mois`;
  } else {
    displayTotal = `${new Intl.NumberFormat("fr-FR", {
      // style: "currency",
      currency: "EUR",
    }).format(location)} € / mois`;
  }

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => {
        setOpenDialog(!openDialog);
      }}
      TransitionComponent={Transition}
    >
      <Backdrop
        open={actions}
        style={{ zIndex: 9999 }}
        onClick={() => setActions(false)}
      />
      <div style={{ height: "100%" }} className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenDialog(!openDialog);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container
          style={{
            paddingBottom: "60px",
            backgroundColor: "rgba(245,245,245, 1)",
          }}
        >
          <Grid container style={{ padding: 10 }} spacing={1} justify="center">
            {selected?.length === 0 && <NoRows noData />}
            {selected.map((p, i) => (
              <Grow in={true} timeout={600} key={`${i} select produit`}>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ProduitCard
                    setSelected={setSelected}
                    selected={selected.find((s) => s?.art_id === p?.art_id)}
                    product={p}
                    token={token}
                    count
                  />
                </Grid>
              </Grow>
            ))}
          </Grid>
        </Container>
        <Toolbar
          className={actions ? classes.bottomBarDisplayed : classes.bottomBar}
        >
          <div className={classes.bottomBarTop}>
            {selected.length > 0 ? (
              <Select
                value={offre}
                onChange={(e) => setOffre(e.target.value)}
                style={{ zIndex: 9999999, color: "#fff" }}
                // fullWidth
              >
                <MenuItem value={1}>
                  <div style={{ fontWeight: "bold", fontSize: 18 }}>
                    Location
                  </div>
                </MenuItem>
                <MenuItem value={2}>
                  <div style={{ fontWeight: "bold", fontSize: 18 }}>Vente</div>
                </MenuItem>
              </Select>
            ) : (
              <div></div>
            )}
            <IconButton
              className={classes.icon}
              disabled={selected?.length === 0}
              onClick={() => setActions((a) => !a)}
            >
              {selected?.length === 0 ? (
                <BlockIcon htmlColor="red" />
              ) : (
                <UnfoldMoreIcon htmlColor="white" />
              )}
            </IconButton>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                fontSize: 16,
              }}
              onClick={() => setActions((a) => !a)}
            >
              {displayTotal ? displayTotal : ""}
            </Typography>
          </div>
          <Actions
            token={token}
            articles={selected
              .map((s) => `${s?.art_id}:${s?.quantity}`)
              .join(",")}
            offre={offre}
            setOffre={setOffre}
            vente={pv}
            entretien={entretien}
            loyer={location}
          />
        </Toolbar>
      </div>
    </Dialog>
  );
}
