"Use strict";
import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import LockIcon from "@material-ui/icons/Lock";
import ModifyPassDialog from "../../utils/ModifyPassDialog/ModifyPassDialog";
import { toast } from "react-toastify";

function DataListAdmin({ id, email, res, name, initial, handleOpen }) {
    const location = useLocation();
    const history = useHistory();
    const accessToken = useSelector((state) => state.accessToken);

    const [confirm, setConfirm] = useState(false);
    const [passModal, setPassModal] = useState(false);

    const [inputConfirm, setInputConfirm] = useState({
        data: "",
        error: false,
    });

    const [pass, setPass] = useState({
        data: "",
        error: false,
    });
    const [rpass, setRpass] = useState({
        data: "",
        error: false,
    });

    const handleModify = () => {
        let error = false;
        if (pass.data.length < 8) {
            setPass((pass) => {
                return { ...pass, error: true };
            });
            toast.info("❌ Mot de passe trop court !", {
                position: "bottom-left",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            error = true;
        } else {
            setPass((pass) => {
                return { ...pass, error: false };
            });
        }
        if (rpass.data !== pass.data) {
            setRpass((rpass) => {
                return { ...rpass, error: true };
            });
            toast.info("❌ Les mots de passe ne sont pas identiques !", {
                position: "bottom-left",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            error = true;
        } else {
            setRpass((rpass) => {
                return { ...rpass, error: false };
            });
        }
        if (!error) {
            axios
                .post(
                    process.env.REACT_APP_BACK_API_URL + "/user/reset/",
                    {
                        id,
                        pass: pass.data,
                        repass: rpass.data,
                    },
                    {
                        headers: { token: accessToken },
                    }
                )
                .then((res) => {
                    if (res.data && res.data.serverStatus === 2) {
                        setPassModal(false);
                        toast.info(
                            `✅ Mot de passe modifié pour l'utilisateur ${email} !`,
                            {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                        setPass({
                            data: "",
                            error: false,
                        });
                        setRpass({
                            data: "",
                            error: false,
                        });
                    }
                })
                .catch((err) => {
                    setPass((inputPass) => {
                        return { ...inputPass, error: true };
                    });
                    setRpass((inputRepass) => {
                        return { ...inputRepass, error: true };
                    });
                    console.error(err);
                });
        }
    };

    const handleConfirm = () => {
        if (inputConfirm.data === email) {
            axios
                .post(
                    process.env.REACT_APP_BACK_API_URL + "/user/delete",
                    {
                        id: id,
                    },
                    {
                        headers: { token: accessToken },
                    }
                )
                .then((res) => {
                    if (res.data) {
                        history.push({
                            pathname: "/",
                            state: location.state,
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            setInputConfirm((confirm) => {
                return { ...confirm, error: true };
            });
        }
    };

    return (
        <>
            <ModifyPassDialog
                pass={pass}
                setPass={setPass}
                rpass={rpass}
                setRpass={setRpass}
                open={passModal}
                handleClick={() => {
                    setPassModal((PassModal) => !PassModal);
                }}
                title={"Changement de mot de passe"}
                text={
                    <>
                        Veuillez entrer les nouveau mot de passe de connexion du
                        compte{" "}
                        <span style={{ fontWeight: "bold", color: "#0a374c" }}>
                            {email}
                        </span>
                    </>
                }
                handleModify={handleModify}
            />
            <ConfirmDialog
                inputConfirm={inputConfirm}
                setInputConfirm={setInputConfirm}
                open={confirm}
                handleClick={() => {
                    setConfirm((confirm) => !confirm);
                }}
                title={"Voulez-vous vraiment supprimer cet email ?"}
                text={
                    <>
                        Veuillez confirmer la suppression du compte en recopiant
                        l'adresse email suivante :{" "}
                        <span style={{ fontWeight: "bold", color: "#0a374c" }}>
                            {email}
                        </span>
                    </>
                }
                handleConfirm={handleConfirm}
                label="Adresse email"
                data={email}
            />
            <List
                style={{ backgroundColor: "white", padding: 0 }}
                dense={false}
            >
                <Grow in={true} timeout={300}>
                    <List dense={true}>
                        <ListItem
                            button
                            onClick={() => {
                                handleOpen(res);
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: "#05384C" }}>
                                    <span
                                        style={{
                                            transform: "translateY(0.3px)",
                                        }}
                                    >
                                        {initial}
                                    </span>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <>
                                        {name}
                                        <br />
                                        {email}
                                    </>
                                }
                                secondary={null}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    id={email}
                                    onClick={() => {
                                        setPassModal(true);
                                    }}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <LockIcon />
                                </IconButton>
                                <IconButton
                                    id={email}
                                    onClick={() => {
                                        setConfirm(true);
                                    }}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grow>
            </List>
        </>
    );
}

export default DataListAdmin;
