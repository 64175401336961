import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";

const Dahua = ({ steps, handleStep }) => {
  return steps.ctg_id === 1 &&
    steps?.arf2_id === 18 &&
    steps?.arf_id === undefined ? (
    <>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 69)}
        >
          KIT
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 70)}
        >
          Totem
        </Button>
      </ButtonGroup>
      <ButtonGroup
        orientation="horizontal"
        color="primary"
        aria-label="vertical contained primary button group"
        variant="contained"
        fullWidth={true}
        size="large"
      >
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 71)}
        >
          Portique
        </Button>
        <Button
          style={{
            height: "100px",
            padding: "20px",
            border: "white 0.1px solid",
          }}
          onClick={() => handleStep("arf_id", 72)}
        >
          Portable
        </Button>
      </ButtonGroup>
    </>
  ) : (
    <></>
  );
};

export default Dahua;
