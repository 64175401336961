"Use strict";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import React from "react";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import { Divider, Typography } from "@material-ui/core";

const PurpleSwitch = withStyles({
    switchBase: {
        color: "#F4970E",
        "&$checked": {
            color: "#F4970E",
        },
        "&$checked + $track": {
            backgroundColor: "#F4970E",
        },
    },
    checked: {},
    track: {},
})(Switch);

function TlsEntretien({ contrat, ctrId }) {
    let {
        tls_entretien,
        tls_telesurveillance,
        tls_carte,
        tls_doute,
        tls_gestion,
        ctr_aquila,
        tls_nature,
        ctr_ncors,
        tls_pass,
        tls_lundi,
        tls_mardi,
        tls_mercredi,
        tls_jeudi,
        tls_vendredi,
        tls_samedi,
        tls_dimanche,
        tls_obs,
        tls_nom,
        tls_qualite,
        tls_phone,
    } = contrat;

    let name = tls_nom ? tls_nom.split("\n") : null;
    let qualite = tls_qualite ? tls_qualite.split("\n") : null;
    let tel = tls_phone ? tls_phone.split("\n") : null;

    return (
        <>
            <Grid style={{ padding: "20px" }} container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel
                        control={
                            <PurpleSwitch
                                checked={tls_entretien === 1}
                                onChange={null}
                                name="checkedA"
                            />
                        }
                        label="Entretien / Garantie"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel
                        control={
                            <PurpleSwitch
                                checked={tls_telesurveillance === 1}
                                onChange={null}
                                name="checkedA"
                            />
                        }
                        label="Télésurveillance"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel
                        control={
                            <PurpleSwitch
                                checked={tls_gestion === 1}
                                onChange={null}
                                name="checkedA"
                            />
                        }
                        label="Gestion des MES / MHS"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel
                        control={
                            <PurpleSwitch
                                checked={tls_carte === 1}
                                onChange={null}
                                name="checkedA"
                            />
                        }
                        label="Carte GSM + Abo"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel
                        control={
                            <PurpleSwitch
                                checked={tls_doute === 1}
                                onChange={null}
                                name="checkedA"
                            />
                        }
                        label="Levée de doute vidéo"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel
                        control={
                            <PurpleSwitch
                                checked={ctr_aquila === 1}
                                onChange={null}
                                name="checkedA"
                            />
                        }
                        label="Intervention vigile"
                    />
                </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: "20px" }} container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        id="outlined-read-only-input"
                        label="NATURE"
                        defaultValue={
                            tls_nature ? tls_nature.toUpperCase() : null
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        id="outlined-read-only-input"
                        label="N° ABONNÉ"
                        defaultValue={ctr_ncors}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextField
                        id="outlined-read-only-input"
                        label="MOT DE PASSE"
                        defaultValue={tls_pass || " "}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: "20px" }} container spacing={3}>
                <Grid
                    style={{ textAlign: "center" }}
                    item
                    xs={12}
                    md={12}
                    lg={12}
                >
                    <Typography variant="h6">HORAIRES D'OUVERTURES</Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Lundi
                                    </TableCell>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Mardi
                                    </TableCell>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Mercredi
                                    </TableCell>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Jeudi
                                    </TableCell>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Vendredi
                                    </TableCell>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Samedi
                                    </TableCell>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Dimanche
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        <Box whiteSpace="nowrap">
                                            {tls_lundi}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box whiteSpace="nowrap">
                                            {tls_mardi}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box whiteSpace="nowrap">
                                            {tls_mercredi}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box whiteSpace="nowrap">
                                            {tls_jeudi}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box whiteSpace="nowrap">
                                            {tls_vendredi}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box whiteSpace="nowrap">
                                            {tls_samedi}
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box whiteSpace="nowrap">
                                            {tls_dimanche}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: "20px" }} container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        multiline
                        id="outlined-read-only-input"
                        label="OBSERVATIONS"
                        defaultValue={tls_obs || " "}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: "20px" }} container spacing={3}>
                <Grid
                    style={{ textAlign: "center" }}
                    item
                    xs={12}
                    md={12}
                    lg={12}
                >
                    <Typography variant="h6">
                        DESTINATAIRES D'ALERTES
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Nom
                                    </TableCell>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Qualité
                                    </TableCell>
                                    <TableCell
                                        // style={{ fontWeight: "bold" }}
                                        align="center"
                                    >
                                        Téléphone
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {name &&
                                    name.map((name, i) => {
                                        let telephone =
                                            tel && tel[i] ? tel[i] : null;
                                        let telephoneSpace =
                                            tel && tel[i]
                                                ? tel[i].replace(/\s/g, "")
                                                : null;
                                        return (
                                            <TableRow key={i}>
                                                <TableCell align="center">
                                                    <Box whiteSpace="nowrap">
                                                        {name}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Box whiteSpace="nowrap">
                                                        {qualite && qualite[i]
                                                            ? qualite[i]
                                                            : null}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Box whiteSpace="nowrap">
                                                        <a
                                                            style={{
                                                                color: "black",
                                                            }}
                                                            href={`tel:${telephoneSpace}`}
                                                        >
                                                            {telephone}
                                                        </a>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}

export default TlsEntretien;
