import { Drawer } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VideocamIcon from '@material-ui/icons/Videocam';
import { Link } from 'react-router-dom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import AppsIcon from '@material-ui/icons/Apps';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    }
}));

const StreamLeftMenu = ({
    open,
    setOpen,
    ip,
    subtype,
    channel,
    ctrId,
    cltId,
    number,
    setPage
}) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const Disconnect = () => {
        dispatch({ type: 'SET_USER_ACCESS_TOKEN', accessToken: null });
        history.push({
            pathname: '/',
            state: location.state
        });
    };

    const handleCameras = () => {
        const eachItems = Array.from(Array(number).keys());

        return eachItems.map((number) => {
            const i = number + 1;
            return (
                <ListItem
                    button
                    key={number}
                    onClick={() => {
                        setPage(Math.ceil(i / 4));
                        history.push({
                            pathname: '/streaming',
                            search: `?ip=${ip}&channel=${i}&subtype=${subtype}`,
                            state: location.state
                        });
                    }}
                >
                    <ListItemIcon>
                        <VideocamIcon
                            color={
                                channel === i.toString() ? 'primary' : 'inherit'
                            }
                            style={{ position: 'relative', bottom: '2px' }}
                        />{' '}
                        {i}
                    </ListItemIcon>
                    <ListItemText primary={`Channel ${i}`} />
                </ListItem>
            );
        });
    };

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })
            }}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            {ctrId && cltId ? (
                <>
                    <Divider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push({
                                pathname: '/contrat/informations',
                                state: {
                                    ctr_id: ctrId,
                                    clt_id: cltId
                                }
                            })
                        }
                    >
                        <ListItemIcon>
                            <ChevronLeftIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Contrat'} />
                    </ListItem>
                </>
            ) : null}
            {number ? (
                <>
                    <Divider />
                    <List>{handleCameras()}</List>
                </>
            ) : null}
            <Divider />
            <List>
                <Link
                    to={{ pathname: '/', state: null }}
                    style={{ textDecoration: 'none', color: 'black' }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <AppsIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Accueil'} />
                    </ListItem>
                </Link>
                <Link
                    to={{ pathname: '/support', state: null }}
                    style={{ textDecoration: 'none', color: 'black' }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Support'} />
                    </ListItem>
                </Link>
                <Link
                    to={{ pathname: '/synthese', state: null }}
                    style={{ textDecoration: 'none', color: 'black' }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <InsertChartIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Synthèse'} />
                    </ListItem>
                </Link>
                <Link
                    to={{ pathname: '/administration', state: null }}
                    style={{ textDecoration: 'none', color: 'black' }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Administration'} />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <List>
                <ListItem onClick={Disconnect} button>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Déconnexion'} />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default StreamLeftMenu;
