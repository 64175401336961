import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';

function ResetPass() {
  const [active, setActive] = useState(false);
  const [accessToken] = useState(useSelector((state) => state.accessToken));
  const [inputPass, setInputPass] = useState({
    data: '',
    error: false,
  });
  const [inputRepass, setInputRepass] = useState({
    data: '',
    error: false,
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      try {
        if (accessToken) {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/reset', {
              headers: { token: accessToken },
            })
            .then((response) => {
              if (response.data && mounted) {
                setActive(response.data.acc_resetPass === 0 ? true : false);
              }
            })
            .catch((err) => {
              throw err;
            });
        }
      } catch (error) {
        throw error;
      }
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  const handleConfirm = (e) => {
    e.preventDefault();
    let error = false;
    if (inputPass.data.length < 8) {
      setInputPass((inputPass) => {
        return { ...inputPass, error: true };
      });
      toast.info('❌ Mot de passe trop court !', {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      error = true;
    } else {
      setInputPass((inputPass) => {
        return { ...inputPass, error: false };
      });
    }
    if (inputRepass.data !== inputPass.data) {
      setInputRepass((inputRepass) => {
        return { ...inputRepass, error: true };
      });
      toast.info('❌ Les mots de passe ne sont pas identiques !', {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      error = true;
    } else {
      setInputRepass((inputRepass) => {
        return { ...inputRepass, error: false };
      });
    }
    if (!error) {
      axios
        .post(
          process.env.REACT_APP_BACK_API_URL + '/user/reset/',
          {
            pass: inputPass.data,
            repass: inputRepass.data,
          },
          {
            headers: { token: accessToken },
          }
        )
        .then((res) => {
          if (res.data && res.data.serverStatus === 2) {
            setActive((active) => !active);
          }
        })
        .catch((err) => {
          setInputPass((inputPass) => {
            return { ...inputPass, error: true };
          });
          setInputRepass((inputRepass) => {
            return { ...inputRepass, error: true };
          });
          console.error(err);
        });
    }
  };

  return (
    <Dialog open={active} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Modification de votre mot de passe
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Le mot de passe qui vous à été fourni n'est <b>pas sécurisé</b> et{' '}
          <b>doit être changé</b>.
        </DialogContentText>
        <form noValidate>
          <TextField
            autoFocus
            margin="dense"
            id="pass"
            label={'Mot de passe'}
            error={inputPass.error}
            type="password"
            fullWidth
            value={inputPass.data}
            autoComplete="new-password"
            onChange={(e) => {
              setInputPass((pass) => {
                return { ...pass, data: e.target.value };
              });
            }}
          />
          <TextField
            margin="dense"
            id="repass"
            label={'Répétez le mot de passe'}
            error={inputRepass.error}
            type="password"
            fullWidth
            value={inputRepass.data}
            autoComplete="new-password"
            onChange={(e) => {
              setInputRepass((pass) => {
                return { ...pass, data: e.target.value };
              });
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetPass;
