import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useHistory, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

const StreamGrid = ({ channel, ip, subtype, setPage, page }) => {
    const history = useHistory();
    const location = useLocation();
    const useStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
            top: '9px'
        },
        grid: {
            border: '1px solid rgba(255,255,255,.33)',
            backgroundColor: channel ? '' : 'rgba(255,255,255,.33)',
            '&:hover': {
                backgroundColor: 'rgba(255,255,255,.33)'
            }
        },
        fullscreen: {
            border: '1px solid rgba(255,255,255,.33)',
            backgroundColor: channel ? 'rgba(255,255,255,.33)' : '',
            '&:hover': {
                backgroundColor: 'rgba(255,255,255,.33)'
            }
        }
    }));
    const classes = useStyles();

    return (
        <ButtonGroup
            className={classes.root}
            variant="text"
            color="inherit"
            size="small"
            aria-label="small outlined button group"
        >
            <Button
                className={classes.grid}
                onClick={() =>
                    history.push({
                        pathname: `/streaming`,
                        search: `?ip=${ip}&subtype=1`,
                        state: location.state
                    })
                }
            >
                <BorderAllIcon />
            </Button>
            <Button
                className={classes.fullscreen}
                onClick={() => {
                    const viewChannel = 4 * (page - 1) + 1;
                    history.push({
                        pathname: `/streaming`,
                        search: `?ip=${ip}&channel=${viewChannel}&subtype=${subtype}`,
                        state: location.state
                    });
                }}
            >
                <CheckBoxOutlineBlankIcon />
            </Button>
        </ButtonGroup>
    );
};

export default StreamGrid;
