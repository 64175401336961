import { makeStyles, Slide } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
    ul: {
        '& .MuiPaginationItem-root': {
            color: '#fff',
            border: '1px solid rgba(255, 255, 255, .33)',
            '&:hover': {
                backgroundColor: 'rgba(255,255,255,.33)'
            }
        },
        '& .Mui-selected': {
            backgroundColor: 'rgba(255,255,255,.33)'
        }
    }
}));

const StreamPagination = ({ page, setPage, nbPage, show }) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(false);

    const handleChange = (event, value) => {
        if (!disabled && page !== value) {
            setDisabled(true);
            setPage(value);
            const time = setTimeout(() => {
                setDisabled(false);
                clearTimeout(time);
            }, 3000);
        }
    };

    return (
        <Slide in={show} direction="down">
            <Pagination
                count={nbPage}
                className={classes.ul}
                onChange={handleChange}
                page={page}
                disabled={disabled}
            />
        </Slide>
    );
};

export default StreamPagination;
