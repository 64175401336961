"Use strict";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
    Card,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@material-ui/core";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import { useSelector } from "react-redux";
import ViewStyle from "../ViewStyle";
import { useLocation, useHistory } from "react-router-dom";
import ClientInfo from "../../utils/ClientInfo/ClientInfo";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        padding: 0,
        margin: 0,
    },
    card: {
        backgroundColor: theme.palette.background.default,
    },
}));

export default function ClientProfile(props) {
    const location = useLocation();
    const history = useHistory();
    const accessToken = useSelector((state) => state.accessToken);
    const classes = useStyles();
    const [client, setClient] = useState({});
    const [users, setUsers] = useState([]);

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    const breadLink = {
        view: "Contrat",
        bread: [
            {
                name: "Clients",
                href: "/clients",
            },
            {
                name: "Contrats",
                href: "/contrats",
            },
            {
                name:
                    client && client.clt_raisonsociale
                        ? client.clt_raisonsociale
                        : null,
                href: "/client",
            },
        ],
    };

    useEffect(() => {
        async function client() {
            try {
                if (location && location.state && location.state.clt_id) {
                    await axios
                        .get(
                            process.env.REACT_APP_BACK_API_URL +
                                "/client/" +
                                location.state.clt_id,
                            {
                                headers: { token: accessToken },
                            }
                        )
                        .then((res) => {
                            if (res && res.data && res.data[0]) {
                                setClient(res.data[0]);
                            } else {
                                history.push("/");
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } else {
                    await axios
                        .get(process.env.REACT_APP_BACK_API_URL + "/client/", {
                            headers: { token: accessToken },
                        })
                        .then((res) => {
                            if (res && res.data && res.data[0]) {
                                setClient(res.data[0]);
                            } else {
                                history.push("/");
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            } catch (e) {
                throw e;
            }
        }
        client();
    }, [accessToken, location, history]);

    useEffect(() => {
        async function userAccount() {
            try {
                if (client && client.clt_id) {
                    await axios
                        .get(
                            process.env.REACT_APP_BACK_API_URL +
                                "/user/client/" +
                                client.clt_id,
                            {
                                headers: { token: accessToken },
                            }
                        )
                        .then((res) => {
                            if (res && res.data) {
                                setUsers(res.data);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            } catch (e) {
                throw e;
            }
        }
        userAccount();
    }, [accessToken, location, history, client]);

    return (
        <>
            <ViewStyle noCard width="lg" breadLink={breadLink}>
                <Grid container spacing={1}>
                    <Grow in={true} timeout={600}>
                        <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className={classes.grid}
                        >
                            <Card
                                style={{
                                    marginTop: "10px",
                                    padding: "0px",
                                    backgroundColor: "white",
                                }}
                            >
                                <ClientInfo client={client} />
                                <Divider />
                                {users && users.length ? (
                                    <List
                                        style={{
                                            backgroundColor:
                                                "rgba(244, 151, 14, .05)",
                                        }}
                                        dense={false}
                                    >
                                        {users.map((el, i) => {
                                            return (
                                                <span key={i}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <SupervisorAccountIcon />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={`${el.acc_firstname} ${el.acc_lastname}`}
                                                        />
                                                    </ListItem>
                                                    {i !== users.length - 1 && (
                                                        <Divider />
                                                    )}
                                                </span>
                                            );
                                        })}
                                    </List>
                                ) : null}
                            </Card>
                        </Grid>
                    </Grow>
                </Grid>
            </ViewStyle>
        </>
    );
}
