import { Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Canvas from './Canvas';

const ImageInputFile = ({
  id,
  label,
  src,
  setFile,
  canvasRef,
  canvasProducts,
}) => {
  const [imageSrc, setImageSrc] = useState(undefined);

  const compressImage = async (file, { quality = 1, type = file.type }) => {
    // Get as image data
    const imageBitmap = await createImageBitmap(file);

    // Draw to canvas
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageBitmap, 0, 0);

    // Turn into Blob
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, type, quality)
    );

    // Turn Blob into File
    return new File([blob], file.name, {
      type: blob.type,
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image')) {
      const compressedFile = await compressImage(file, {
        quality: 0.26,
        type: 'image/jpeg',
      });
      setFile(compressedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    } else {
      alert('Veuillez upload uniquement des images.');
    }
  };

  const openImageInNewTab = () => {
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        });
    } else if (src) {
      window.open(src, '_blank');
    }
  };

  const isSrc = imageSrc || src;

  return (
    <>
      <input
        onChange={handleFileInputChange}
        accept="image/*"
        id={id}
        type="file"
        style={{ display: 'none' }}
      />
      <label htmlFor={id}>
        <Button
          variant="contained"
          fullWidth
          color={imageSrc || src ? undefined : 'primary'}
          component="span"
          startIcon={<CloudUploadIcon />}
          style={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {label}
        </Button>
      </label>
      {isSrc && canvasProducts && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'white',
            marginBottom: '10px',
            padding: '10px 0',
            borderRadius: '5px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            border: '1px solid #ccc',
          }}
        >
          <Canvas
            src={imageSrc ? imageSrc : src}
            products={canvasProducts}
            canvasRef={canvasRef}
            style={{
              margin: 'auto',
              maxWidth: '100%',
              maxHeight: '250px',
            }}
          />
        </Box>
      )}
      {isSrc && !canvasProducts && (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
              marginBottom: '10px',
              padding: '10px 0',
              borderRadius: '5px',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              border: '1px solid #ccc',
            }}
          >
            <img
              onClick={openImageInNewTab}
              src={imageSrc ? imageSrc : src}
              alt={`Uploaded ${label}`}
              style={{
                margin: 'auto',
                maxWidth: '100%',
                maxHeight: '100px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default ImageInputFile;
