import React from 'react';
import {
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import ViewListIcon from '@material-ui/icons/ViewList';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';

const ListItemProposition = ({
  contrat,
  handleOpenProposition,
  isEnd,
  isClientSelected,
  contratsProposition,
  accessToken,
  setLoading,
}) => {
  const labelId = `checkbox-list-label-${contrat?.ctr_id}`;
  const isPlan = contrat?.products?.find((p) => p?.ctl_x && p?.ctl_y);
  const isProducts = contrat?.products?.length > 0;

  const getFile = async (url) => {
    try {
      setLoading(true);
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: { token: accessToken },
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const objectUrl = URL.createObjectURL(blob);
      setLoading(false);
      window.open(objectUrl, '_blank');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleProducts = async () => {
    await getFile(
      `${process.env.REACT_APP_BACK_API_URL}/contrat/${contrat.ctr_id}/proposition-products.pdf`
    );
  };

  const handlePlan = async () => {
    await getFile(
      `${process.env.REACT_APP_BACK_API_URL}/contrat/${contrat.ctr_id}/proposition-plan.pdf`
    );
  };

  const handleDelete = async () => {
    if (window?.confirm('Voulez-vous supprimer cette proposition ?')) {
      try {
        setLoading(true);
        await axios
          .delete(
            process.env.REACT_APP_BACK_API_URL + `/contrat/${contrat.ctr_id}`,
            {
              headers: { token: accessToken },
            }
          )
          .then((res) => {
            if (res?.data) {
              // console.log('delete', res.data);
              contratsProposition();
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (e) {
        throw e;
      }
    }
  };

  return (
    <>
      <ListItem
        role={'listitem'}
        dense
        button
        onClick={() => handleOpenProposition(contrat)}
      >
        <ListItemText
          id={labelId}
          primary={contrat.ctr_ref}
          secondary={!isClientSelected ? contrat.clt_raisonsociale : undefined}
        />
        <ListItemSecondaryAction>
          {isPlan && (
            <IconButton
              edge="end"
              aria-label="plan"
              size="medium"
              onClick={handlePlan}
            >
              <MapIcon />
            </IconButton>
          )}
          {isProducts && (
            <IconButton
              edge="end"
              aria-label="products"
              size="medium"
              onClick={handleProducts}
            >
              <ViewListIcon />
            </IconButton>
          )}
          <IconButton
            edge="end"
            aria-label="delete"
            size="medium"
            style={{
              color: 'red',
            }}
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {!isEnd ? <Divider /> : <></>}
    </>
  );
};

export default ListItemProposition;
