import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ReplyIcon from "@material-ui/icons/Reply";
import { useSelector } from "react-redux";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    btnAction: {
        marginLeft: "20px",
        transform: "translate(-10px, 14px)",
    },
    icon: {
        color: "#757575",
        verticalAlign: "middle",
    },
}));

export default function MessageMenu({
    title,
    date,
    text,
    handleReply,
    data,
    type,
    com_id,
    user,
    role,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const accessToken = useSelector((state) => state.accessToken);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        if (e.target.textContent === " Répondre") {
            handleReply({
                acc_firstname: title.split(" ")[0],
                acc_lastname: title.split(" ")[1],
                com_user: user,
                com_data: text,
                com_id: com_id,
                date: date,
                type: type,
            });
        } else if (e.target.textContent === " Copier") {
            navigator.clipboard.writeText(text);
        } else if (e.target.textContent === " Supprimer" && role === 1) {
            if (
                window.confirm(
                    "Etes vous sur de vouloir supprimmer ce message ?"
                )
            ) {
                axios
                    .post(
                        process.env.REACT_APP_BACK_API_URL +
                            "/ticket/messages/delete",
                        {
                            com_id: com_id,
                        },
                        {
                            headers: { token: accessToken },
                        }
                    )
                    .then((res) => {
                        document.getElementById(com_id).style.display = "none";
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                size="small"
                className={classes.btnAction}
                aria-label="settings"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <ReplyIcon className={classes.icon} /> Répondre
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <FileCopyIcon className={classes.icon} /> Copier
                </MenuItem>
                {role === 1 && (
                    <MenuItem onClick={handleClose}>
                        <DeleteOutlineIcon className={classes.icon} /> Supprimer
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}
