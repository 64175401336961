import React from "react";
import Agility from "./Alarme/Agility";
import Ajax from "./Alarme/Ajax";
import AAutres from "./Alarme/Autres";
import Lightsys from "./Alarme/Lightsys";
import Prosys from "./Alarme/Prosys";
import Cat1 from "./Autres/Cat1";
import Cat2 from "./Autres/Cat2";
import Cat3 from "./Autres/Cat3";
import CDahua from "./Controle/Dahua";
import Vauban from "./Controle/Vauban";
import PDahua from "./Particulier/Dahua";
import Hik from "./Particulier/Hik";
import VAutres from "./Videosurveillance/Autres";
import CamHD from "./Videosurveillance/CamHD";
import CamIP from "./Videosurveillance/CamIP";
import Dvr from "./Videosurveillance/Dvr";
import Moniteur from "./Videosurveillance/Moniteur";
import Nvr from "./Videosurveillance/Nvr";

const Fam = ({ steps, handleStep }) => {
  return (
    <>
      {/* Vidéo */}
      <Dvr steps={steps} handleStep={handleStep} />
      <Nvr steps={steps} handleStep={handleStep} />
      <CamHD steps={steps} handleStep={handleStep} />
      <CamIP steps={steps} handleStep={handleStep} />
      <Moniteur steps={steps} handleStep={handleStep} />
      <VAutres steps={steps} handleStep={handleStep} />

      {/* Alarme */}
      <Agility steps={steps} handleStep={handleStep} />
      <Ajax steps={steps} handleStep={handleStep} />
      <AAutres steps={steps} handleStep={handleStep} />
      <Lightsys steps={steps} handleStep={handleStep} />
      <Prosys steps={steps} handleStep={handleStep} />

      {/* Controle */}
      <CDahua steps={steps} handleStep={handleStep} />
      <Vauban steps={steps} handleStep={handleStep} />

      {/* Autres */}
      <Cat1 steps={steps} handleStep={handleStep} />
      <Cat2 steps={steps} handleStep={handleStep} />
      <Cat3 steps={steps} handleStep={handleStep} />

      {/* Particulier */}
      <Hik steps={steps} handleStep={handleStep} />
      <PDahua steps={steps} handleStep={handleStep} />
    </>
  );
};

export default Fam;
