import {
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TimePicker } from '@material-ui/pickers';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router';

const Synthese = ({ accessToken }) => {
  const [ouverture, setOuverture] = useState([]);
  const [fermeture, setFermeture] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const [contrats, setContrats] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      async function notif() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + '/notif-centrale', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res.data && res.data.length && mounted) {
                // console.log(res.data);
                setContrats(
                  res.data.filter(
                    (thing, index, self) =>
                      index === self.findIndex((t) => t.ctr_id === thing.ctr_id)
                  )
                );
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      notif();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken]);

  useEffect(() => {
    contrats.map((ctr, i) => {
      setOuverture((ouverture) => ({
        ...ouverture,
        [i]: ctr.ctr_ouverture ?? null,
      }));
      setFermeture((fermeture) => ({
        ...fermeture,
        [i]: ctr.ctr_fermeture ?? null,
      }));
      setTolerance((tolerance) => ({
        ...tolerance,
        [i]: ctr.ctr_tolerance ?? '',
      }));
      return ctr;
    });
  }, [contrats]);

  const handleOuverture = async (e, i, ctr_id) => {
    setOuverture((ouverture) => ({
      ...ouverture,
      [i]: e,
    }));
    await axios
      .post(
        `${process.env.REACT_APP_BACK_API_URL}/notif-centrale/ouverture`,
        { ouverture: e.format('YYYY-MM-DD HH:mm'), ctr_id },
        {
          headers: { token: accessToken },
        }
      )
      .catch((err) => {
        console.error(err);
      });
  };

  const handleFermeture = async (e, i, ctr_id) => {
    setFermeture((fermeture) => ({
      ...fermeture,
      [i]: e,
    }));
    await axios
      .post(
        `${process.env.REACT_APP_BACK_API_URL}/notif-centrale/fermeture`,
        { fermeture: e.format('YYYY-MM-DD HH:mm'), ctr_id },
        {
          headers: { token: accessToken },
        }
      )
      .catch((err) => {
        console.error(err);
      });
  };

  const handleTolerance = (e, i) => {
    if ((e.target.value > 0 && e.target.value <= 60) || e.target.value === '')
      setTolerance((tolerance) => ({
        ...tolerance,
        [i]: e.target.value,
      }));
  };

  const handleBlur = async (data) => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACK_API_URL}/notif-centrale/tolerance`,
          data,
          {
            headers: { token: accessToken },
          }
        )
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      {contrats.map((ctr, i) => {
        return (
          <div key={i}>
            <div style={{ width: '100%', padding: '30px' }}>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push({
                    pathname: '/contrat/informations',
                    state: {
                      ...location.state,
                      ctr_id: ctr.ctr_id,
                      clt_id: ctr.clt_id,
                    },
                  });
                }}
              >
                {ctr.ctr_ref}
              </Typography>
              <TimePicker
                ampm={false}
                label="Ouverture"
                value={ouverture[i] ?? null}
                onChange={(e) => handleOuverture(e, i, ctr.ctr_id)}
                style={{ padding: '0px', width: '28%' }}
              />
              <TimePicker
                ampm={false}
                label="Fermeture"
                value={fermeture[i] ?? null}
                onChange={(e) => handleFermeture(e, i, ctr.ctr_id)}
                style={{ padding: '0px', width: '28%' }}
              />
              <TextField
                label={'Tolérance'}
                type="number"
                min="0"
                max="60"
                value={tolerance[i] ?? ''}
                onChange={(e) => handleTolerance(e, i)}
                onBlur={() => {
                  handleBlur({
                    tolerance: tolerance[i],
                    ctr_id: ctr.ctr_id,
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Mn</InputAdornment>
                  ),
                }}
                style={{ padding: '0px', width: '28%' }}
              />
            </div>
            <Divider />
          </div>
        );
      })}
    </>
  );
};

export default Synthese;
