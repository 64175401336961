'Use strict';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import EyeTable from '../../utils/EyeTable/EyeTable';
import { Button, Card } from '@material-ui/core';
import axios from 'axios';
import Grow from '@material-ui/core/Grow';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EyeTableSAV from '../../utils/EyeTable/EyeTableSAV';
import ViewStyle from '../ViewStyle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: 0,
    margin: 0,
  },
  card: {
    backgroundColor: theme.palette.background.default,
  },
  gridClientInfoChip: {
    marginTop: 15,
    position: 'sticky',
    top: 0,
    display: 'flex',
    // justifyContent: "center",
    zIndex: 99,
  },
}));

export default function Contrat() {
  const location = useLocation();
  const history = useHistory();
  const accessToken = useSelector((state) => state.accessToken);

  const [role, setRole] = useState(null);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const classes = useStyles();
  const breadLink = {
    view: 'Contrat',
    bread: [
      {
        name: 'Clients',
        href: '/clients',
      },
      {
        name: 'Contrats',
        href: '/contrats',
      },
    ],
  };

  const [client, setClient] = useState({});
  const [contrats, setContrats] = useState([]);
  const [contratsF, setContratsF] = useState([]);
  const CtrColumns = [
    {
      id: 'alert',
      numeric: false,
      disablePadding: false,
      label: 'Dest. alerte',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Référence',
    },
    {
      id: 'create',
      numeric: false,
      disablePadding: false,
      label: 'Création',
    },
    {
      id: 'adress',
      numeric: false,
      disablePadding: false,
      label: 'Adresse',
    },
  ];

  const [sav, setSAV] = useState([]);
  const [savF, setSAVF] = useState([]);
  const SAVColumns = [
    {
      id: 'active',
      numeric: false,
      disablePadding: false,
      label: 'Actif',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Raison Sociale',
    },
    {
      id: 'contrat',
      numeric: false,
      disablePadding: false,
      label: 'Contrat',
    },
    {
      id: 'date',
      numeric: false,
      label: 'Date',
    },
  ];

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      let request =
        location.state && location.state.clt_id
          ? '/contrat/' + location.state.clt_id
          : '/contrat';
      async function client() {
        try {
          if (location && location.state && location.state.clt_id)
            await axios
              .get(
                process.env.REACT_APP_BACK_API_URL +
                  '/client/' +
                  location.state.clt_id,
                {
                  headers: { token: accessToken },
                }
              )
              .then((res) => {
                if (res && res.data && res.data[0]) {
                  setClient(res.data[0]);
                } else {
                  history.push('/');
                }
              })
              .catch((err) => {
                console.error(err);
              });
          else {
            await axios
              .get(process.env.REACT_APP_BACK_API_URL + '/client/', {
                headers: { token: accessToken },
              })
              .then((res) => {
                if (res && res.data && res.data[0]) {
                  setClient(res.data[0]);
                } else {
                  history.push('/');
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        } catch (e) {
          throw e;
        }
      }
      async function contrat() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + request, {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (Array.isArray(res.data) && mounted) {
                setContrats(res.data);
                setContratsF(res.data);
              } else {
                history.push({
                  pathname: res.data,
                  state: location.state,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      async function sav() {
        try {
          await axios
            .get(process.env.REACT_APP_BACK_API_URL + '/ticket' + request, {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (Array.isArray(res.data) && mounted) {
                setSAV(res.data);
                setSAVF(res.data);
              } else {
                history.push({
                  pathname: res.data,
                  state: location.state,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (e) {
          throw e;
        }
      }
      async function role() {
        try {
          axios
            .get(process.env.REACT_APP_BACK_API_URL + '/user/role', {
              headers: { token: accessToken },
            })
            .then((res) => {
              if (res && res.data && res.data.role && mounted) {
                setRole(res.data.role);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (error) {
          throw error;
        }
      }
      role();
      contrat();
      sav();
      client();
    }
    return () => {
      mounted = false;
    };
  }, [accessToken, location, history]);

  const handleSearch = (event) => {
    let newCtr = contrats.filter(
      (el) =>
        (el.ctr_adr_install &&
          el.ctr_adr_install
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1) ||
        (el.ctr_ref &&
          el.ctr_ref.toUpperCase().indexOf(event.target.value.toUpperCase()) !==
            -1)
    );
    let newSAV = sav.filter(
      (el) =>
        (el.ctr_adr_install &&
          el.ctr_adr_install
            .toUpperCase()
            .indexOf(event.target.value.toUpperCase()) !== -1) ||
        (el.ctr_ref &&
          el.ctr_ref.toUpperCase().indexOf(event.target.value.toUpperCase()) !==
            -1)
    );
    setSAVF(newSAV);
    setContratsF(newCtr);
  };

  return (
    <>
      <ViewStyle
        dial={'ctr'}
        role={role}
        search
        handleSearch={handleSearch}
        clt
        noCard
        width="lg"
        breadLink={breadLink}
      >
        <Grid container spacing={1}>
          {client && client.clt_raisonsociale && (
            <Grow in={true} timeout={600}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.gridClientInfoChip}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<AssignmentIndIcon />}
                  onClick={() => {
                    history.push({
                      pathname: '/client',
                      state: location.state,
                    });
                  }}
                >
                  {client.clt_raisonsociale}
                </Button>
              </Grid>
            </Grow>
          )}

          <Grow in={true} timeout={600}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.grid}>
              <Card
                style={{
                  marginTop: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingRight: 0,
                  paddingleft: 0,
                  backgroundColor: 'white',
                }}
              >
                <EyeTable
                  title="Contrats"
                  columns={CtrColumns}
                  rows={contratsF}
                  defaultSort="create"
                  cltId={client.clt_id}
                />
              </Card>
            </Grid>
          </Grow>
          <Grow in={true} timeout={900}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.grid}>
              <Card
                style={{
                  marginTop: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingRight: 0,
                  paddingleft: 0,
                  backgroundColor: 'white',
                }}
              >
                <EyeTableSAV
                  title="SAV"
                  columns={SAVColumns}
                  rows={savF}
                  defaultSort="create"
                />
              </Card>
            </Grid>
          </Grow>
        </Grid>
      </ViewStyle>
    </>
  );
}
