import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { Skeleton } from '@material-ui/lab';
import PhoneIcon from '@material-ui/icons/Phone';
import PhonePausedIcon from '@material-ui/icons/PhonePaused';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const beautifyTel = (tel) => {
    return tel.replaceAll(' ', '');
};

export default function ClientInfo({ client }) {
    const classes = useStyles();
    const [cli, setCli] = useState(client);

    const refTel = useRef(null);
    const refCell = useRef(null);
    const refEmail = useRef(null);
    const refMap = useRef(null);

    useEffect(() => {
        setCli((cli) => client);
    }, [client]);

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader
                    style={{ fontSize: 18 }}
                    component="div"
                    id="nested-list-subheader"
                >
                    {cli && cli.clt_raisonsociale ? (
                        cli.clt_raisonsociale
                    ) : (
                        <Skeleton />
                    )}
                </ListSubheader>
            }
            className={classes.root}
            style={{ padding: 0, margin: 0 }}
        >
            <ListItem>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <>
                            {client && client.clt_title
                                ? client.clt_title + ' '
                                : null}
                            {client && client.clt_lastname
                                ? client.clt_lastname + ' '
                                : null}
                            {client && client.clt_firstname
                                ? client.clt_firstname
                                : null}
                        </>
                    }
                />
            </ListItem>
            <Divider />

            <ListItem>
                <ListItemIcon>
                    <StorefrontIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        client && client.act_nom ? client.act_nom : <Skeleton />
                    }
                />
            </ListItem>
            <Divider />

            <ListItem
                button={client && client.clt_email ? true : false}
                onClick={() => {
                    if (refEmail && refEmail.current) refEmail.current.click();
                }}
            >
                <ListItemIcon>
                    <AlternateEmailIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        client && client.clt_email ? (
                            <a
                                ref={refEmail}
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                }}
                                href={'mailto:' + client.clt_email}
                            >
                                {client.clt_email}
                            </a>
                        ) : (
                            <Skeleton />
                        )
                    }
                />
            </ListItem>
            <Divider />

            <ListItem
                button={client && client.clt_tel ? true : false}
                onClick={() => {
                    if (refTel && refTel.current) refTel.current.click();
                }}
            >
                <ListItemIcon>
                    <PhoneIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        client && client.clt_tel ? (
                            <a
                                ref={refTel}
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                }}
                                href={
                                    'tel:' + client.clt_tel.replaceAll(' ', '')
                                }
                            >
                                {beautifyTel(client.clt_tel)}
                            </a>
                        ) : (
                            <Skeleton />
                        )
                    }
                />
            </ListItem>
            <Divider />

            <ListItem
                button={client && client.clt_cell ? true : false}
                onClick={() => {
                    if (refCell && refCell.current) refCell.current.click();
                }}
            >
                <ListItemIcon>
                    <PhonePausedIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        client && client.clt_cell ? (
                            <a
                                ref={refCell}
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                }}
                                href={
                                    'tel://' +
                                    client.clt_cell.replaceAll(' ', '')
                                }
                            >
                                {beautifyTel(client.clt_cell)}
                            </a>
                        ) : (
                            <Skeleton />
                        )
                    }
                />
            </ListItem>
            <Divider />

            <ListItem
                button={client && client.clt_adr_rue ? true : false}
                onClick={() => {
                    if (refMap && refMap.current) refMap.current.click();
                }}
            >
                <ListItemIcon>
                    <RoomIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                        client && client.clt_adr_rue ? (
                            <a
                                ref={refMap}
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                }}
                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                                    client.clt_adr_rue
                                )}`}
                            >
                                {client.clt_adr_rue}
                            </a>
                        ) : (
                            <Skeleton />
                        )
                    }
                />
            </ListItem>
        </List>
    );
}
