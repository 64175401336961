"Use strict";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import EyeButton from "../../utils/EyeButton/EyeButton";
import ViewStyle from "../ViewStyle";
import Grow from "@material-ui/core/Grow";

function Support() {
    const breadLink = {
        view: "Support",
        bread: [
            {
                name: "Accueil",
                href: "/",
            },
            {
                name: "Support",
                href: "/support",
            },
        ],
    };

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <ViewStyle breadLink={breadLink} width="lg">
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
            >
                <Grow in={true} timeout={600}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <EyeButton type="video" />
                    </Grid>
                </Grow>
                <Grow in={true} timeout={900}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <EyeButton type="alarme" />
                    </Grid>
                </Grow>
                <Grow in={true} timeout={1200}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <EyeButton type="controle" />
                    </Grid>
                </Grow>
            </Grid>
        </ViewStyle>
    );
}

export default Support;
